import { StyleSheet, Text, View, TouchableOpacity } from 'react-native'
import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { Ionicons } from '@expo/vector-icons'
import { AntDesign } from '@expo/vector-icons'

// styles
import globalStyles from '../../styles/global'

// data
import { cartItemsFeed } from '../../data'

// components
import CartItem from './CartItem'
import FlatButton from '../Button'

const CartItemList = ({ items }) => {
  const navigation = useNavigation()

  const countinueShoppingHandler = () => {
    navigation.navigate('Home', {})
  }

  return (
    <View>
      {items.length > 0 && (
        <View style={[styles.card, globalStyles.pt4]}>
          {items.map((item, index) => (
            <CartItem
              item={item}
              separator={items.length === index + 1 ? true : true}
              key={item.product}
            />
          ))}
          <View style={[globalStyles.mt1, globalStyles.mb2]}>
            <TouchableOpacity
              onPress={countinueShoppingHandler}
              style={styles.more}
            >
              <Text
                style={[
                  globalStyles.text,
                  globalStyles.fwMedium,
                  globalStyles.fs15,
                  globalStyles.primaryTextColor
                ]}
              >
                Continue Shopping
              </Text>

              <View style={{ position: 'relative', top: 1.5, marginLeft: 3 }}>
                <Ionicons
                  name="chevron-forward-sharp"
                  size={20}
                  color="#df143c"
                />
                {/* <AntDesign name="rightcircleo" size={18} color="#df143c" /> */}
              </View>
            </TouchableOpacity>
          </View>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  card: {
    position: 'relative',
    justifyContent: 'flex-end',
    borderRadius: 10,
    backgroundColor: '#fff',
    borderColor: '#e9ecef',
    borderWidth: 1,
    padding: 16
  },
  more: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

export default CartItemList
