import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Dimensions
} from 'react-native'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { AntDesign } from '@expo/vector-icons'
import { useToast } from 'react-native-toast-notifications'

// styles
import globalStyles from '../styles/global'

// utils
import { currencyFormatter } from '../utils/index'

// components
import FlatButton from './Button'
import Rating from './Rating'
import Like from './Like'
import Ribbon from './Ribbon'

// actions
import { addToCart } from '../redux/actions/cartActions'
import { likeProduct } from '../redux/actions/productActions'

// config
import { productImagePlaceholder } from '../config'

// constants
const DEVICE_WIDTH = Dimensions.get('window').width

const CardItem = ({ item, setUnlikedProduct }) => {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // const [isLiked, setIsLiked] = useState(
  //   item.likes?.includes(userInfo?._id) || false
  // )

  // const [likeCount, setLikeCount] = useState(item.numLikes || 0)

  const handleOpenSingleProduct = (productId) => {
    navigation.navigate('ProductSingle', { productId })
  }

  const dispatch = useDispatch()
  const navigation = useNavigation()
  const toast = useToast()

  const addToCartHandler = () => {
    dispatch(addToCart(item._id, 1, toast))
    navigation.navigate('Home', {})
  }

  return (
    <View style={[styles.itemContainer]}>
      <Like item={item} setUnlikedProduct={setUnlikedProduct} />
      <TouchableOpacity
        style={[globalStyles.hCenterItems, globalStyles.vCenterItems]}
        onPress={() => handleOpenSingleProduct(item._id)}
      >
        <Image
          source={{ uri: item.images[0]?.imageurl || productImagePlaceholder }}
          style={[styles.itemImage]}
        />
      </TouchableOpacity>
      <Text style={styles.itemCategory}>{item.category}</Text>
      <TouchableOpacity onPress={() => handleOpenSingleProduct(item._id)}>
        <Text style={styles.itemName}>{item.name}</Text>
      </TouchableOpacity>
      <Text style={styles.itemPrice}>
        {currencyFormatter(parseFloat(item.price))}
      </Text>
      <View style={styles.itemRating}>
        <Rating rating={parseFloat(item.rating)} size={16} />
      </View>
      <View style={styles.itemAction}>
        <FlatButton
          text="Add to cart"
          type="primaryOutline"
          onPress={addToCartHandler}
          isDisabled={item.countInStock === 0 ? true : false}
        />
      </View>
      {item.countInStock === 0 && (
        <View style={{ position: 'absolute', top: -5, right: -5 }}>
          <Ribbon
            text={'Out of stock'}
            bgColor={'rgba(223, 20, 60, .75)'}
            textColor={'rgba(255, 255, 255, 1)'}
          />
        </View>
      )}
      {item.isNewArrival && item.countInStock !== 0 && (
        <View style={{ position: 'absolute', top: -5, right: -5 }}>
          <Ribbon
            text={'New arrival'}
            bgColor={'rgba(7, 188, 12, .75)'}
            textColor={'rgba(255, 255, 255, 1)'}
          />
        </View>
      )}

      {/* <View style={[styles.overlay]}>
        <Text
          style={[
            globalStyles.vAlignItems,
            globalStyles.hCenterItems,
            globalStyles.vCenterItems
          ]}
        >
          Sold Out
        </Text>
      </View> */}
    </View>
  )
}

const styles = StyleSheet.create({
  itemContainer: {
    position: 'relative',
    justifyContent: 'flex-end',
    borderRadius: 10,
    backgroundColor: '#fff',
    borderColor: '#e9ecef',
    borderWidth: 1,
    margin: 4,
    padding: 16
  },
  overlay: {
    flex: 1,
    position: 'absolute',
    left: 0,
    top: 0,
    padding: 16,
    width: '100%',
    height: '100%',
    borderRadius: 10,
    backgroundColor: 'rgba(0,0,0,.5)',
    borderColor: '#e9ecef',
    zIndex: 9999999999
  },
  itemImage: {
    width: 120,
    height: 120
  },
  itemName: {
    fontFamily: 'notosanstc-light',
    fontSize: 14,
    lineHeight: 19,
    color: 'rgba(0, 0, 0, .7)',
    marginBottom: 15,
    textAlign: 'center'
  },
  itemCategory: {
    fontFamily: 'notosanstc-bold',
    fontSize: 12,
    lineHeight: 17,
    color: 'rgba(0, 0, 0, 1)',
    marginBottom: 10,
    textAlign: 'center'
  },
  itemPrice: {
    fontFamily: 'notosanstc-bold',
    fontSize: 16,
    lineHeight: 21,
    color: 'rgba(0, 0, 0, 1)',
    marginBottom: 10,
    textAlign: 'center'
  },
  itemRating: {
    alignItems: 'center',
    marginBottom: 5
  },
  itemAction: {
    marginTop: 20
  }
})

export default CardItem
