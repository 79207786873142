import { StyleSheet, Text, View, Image, TouchableOpacity } from 'react-native'
import React, { useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { AntDesign } from '@expo/vector-icons'
import { useToast } from 'react-native-toast-notifications'

// styles
import globalStyles from '../../styles/global'

// utils
import { currencyFormatter } from '../../utils/index'
import { removeFromCartHandler } from '../../utils/cart'

// components
import Rating from '../Rating'
import Counter from '../Counter'

// actions
import { addToCart, removeFromCart } from '../../redux/actions/cartActions'

const CartItem = ({ item, separator }) => {
  const [count, setCount] = useState(item.quantity)

  const dispatch = useDispatch()
  const navigation = useNavigation()
  const toast = useToast()

  const handleOpenSingleProduct = (productId) => {
    navigation.navigate('ProductSingle', { productId })
  }

  return (
    <View>
      <View style={[styles.itemContainer]}>
        <TouchableOpacity
          style={{ paddingRight: 10 }}
          onPress={() => handleOpenSingleProduct(item.product)}
        >
          <Image
            source={{
              uri: item.image
            }}
            style={[styles.itemImage]}
          />
        </TouchableOpacity>
        <View style={{ flex: 1 }}>
          <View style={[globalStyles.mb1]}>
            <TouchableOpacity
              onPress={() => handleOpenSingleProduct(item.product)}
            >
              <Text
                style={[
                  globalStyles.text,
                  globalStyles.fwBold,
                  globalStyles.fs15
                ]}
              >
                {item.name}
              </Text>
            </TouchableOpacity>
          </View>
          <View style={[globalStyles.mb3]}>
            <Rating rating={parseFloat(item.rating)} size={16} />
          </View>
          {item.countInStock < 10 && (
            <View style={[globalStyles.mb3]}>
              <Text
                style={[
                  globalStyles.text,
                  globalStyles.fwLight,
                  globalStyles.blackTextColor
                ]}
              >
                {item.countInStock} {item.countInStock === 1 ? 'item' : 'items'}{' '}
                left
              </Text>
            </View>
          )}
          <View style={[globalStyles.mb3]}>
            <Text
              style={[
                globalStyles.text,
                globalStyles.fwMedium,
                globalStyles.fs16
              ]}
            >
              {currencyFormatter(parseFloat(item.price))}
            </Text>
          </View>
          <Counter
            productId={item.product}
            count={count}
            setCount={setCount}
            countInStock={item.countInStock}
            isRemovable={true}
            updateCart={true}
          />
          {true && (
            <TouchableOpacity
              style={globalStyles.mt3}
              onPress={() => {
                removeFromCartHandler(
                  item.product,
                  dispatch,
                  removeFromCart,
                  toast
                )
              }}
            >
              <View style={[globalStyles.hAlignItems]}>
                <AntDesign name="close" size={16} color="#df143c" />
                <Text
                  style={[
                    globalStyles.text,
                    globalStyles.fs12,
                    globalStyles.fwMedium,
                    globalStyles.primaryTextColor,
                    globalStyles.ml1
                  ]}
                >
                  Remove
                </Text>
              </View>
            </TouchableOpacity>
          )}
        </View>
      </View>
      {separator && <View style={globalStyles.separator}></View>}
    </View>
  )
}

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  itemImage: {
    width: 80,
    height: 80
  }
})
export default CartItem
