import { StyleSheet, Text, View, TouchableOpacity } from 'react-native'
import React, { useState, useEffect } from 'react'
import { AntDesign } from '@expo/vector-icons'

// styles
import globalStyles from '../../styles/global'

// components
import ModalPopup from './ModalPopup'
import FlatButton from '../Button'

const ModalEdit = ({
  title = '',
  message = '',
  confirmAction,
  visible,
  setVisible
}) => {
  return (
    <ModalPopup visible={visible}>
      <View style={{ alignItems: 'center' }}>
        <View style={styles.header}>
          <Text
            style={[globalStyles.text, globalStyles.fs18, globalStyles.fwBold]}
          >
            {title}
          </Text>
          <TouchableOpacity onPress={() => setVisible(!visible)}>
            <AntDesign name="close" size={22} color="#333" />
          </TouchableOpacity>
        </View>
        <View style={styles.body}>
          <Text
            style={[globalStyles.text, globalStyles.fs16, globalStyles.fwLight]}
          >
            {message}
          </Text>
        </View>
        <View style={styles.footer}>
          <View style={[globalStyles.mr1]}>
            <FlatButton
              text="Cancel"
              type="secondary"
              onPress={() => setVisible(!visible)}
            />
          </View>
          <View>
            <FlatButton text="Confirm" type="primary" onPress={confirmAction} />
          </View>
        </View>
      </View>
    </ModalPopup>
  )
}

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: 20,
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderColor: '#f2f2f2'
  },
  body: {
    width: '100%',
    paddingTop: 20,
    paddingBottom: 30,
    paddingHorizontal: 20
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: 20,
    paddingVertical: 12,
    borderTopWidth: 1,
    borderColor: '#f2f2f2'
  }
})

export default ModalEdit
