import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Image,
  TextInput,
  TouchableOpacity
} from 'react-native'
import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { useNavigation, useIsFocused } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { useToast } from 'react-native-toast-notifications'
import { Ionicons } from '@expo/vector-icons'
import * as ImagePicker from 'expo-image-picker'

// styles
import globalStyles from '../styles/global'

// utils
import { getMimeType } from '../utils'

// components
import FlatButton from '../components/Button'
import ModalUpload from '../components/modals/ModalUpload'

// skeletons
import ProfileSkeleton from '../components/skeletons/ProfileSkeleton'

// actions
import { getUserDetails, updateUserProfile } from '../redux/actions/userActions'

// constants
import { defaultMaleProfile } from '../config'

const ProfileScreen = () => {
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [picture, setPicture] = useState(defaultMaleProfile)
  const [selectedPicture, setSelectedPicture] = useState(null)

  const [editToggle, setEditToggle] = useState('Unlock')
  const [isEditable, setIsEditable] = useState(false)
  const [isCancelled, setIsCancelled] = useState(false)

  // TODO: change password modal
  const [visible, setVisible] = React.useState(false)

  const navigation = useNavigation()
  const dispatch = useDispatch()
  const toast = useToast()
  const isFocused = useIsFocused()

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const {
    loading: loadingUpdatedUser,
    error: errorUpdatedUser,
    success: successUpdate,
    userInfo: updatedUserInfo
  } = userUpdateProfile

  const _editProfile = useCallback(async () => {
    setUsername(user?.username || '')
    setEmail(user?.email || '')
    setFirstname(user?.firstname || '')
    setLastname(user?.lastname || '')
    setPicture(user?.picture || defaultMaleProfile)
  }, [user])

  const saveChangesHandler = () => {
    let userFormData = new FormData()
    userFormData.append('username', username)
    userFormData.append('email', email)
    userFormData.append('firstname', firstname)
    userFormData.append('lastname', lastname)
    userFormData.append('picture', selectedPicture)

    // CONSOLE.LOG
    // console.log('picture:', selectedPicture)

    dispatch(updateUserProfile(userFormData, toast))
  }

  const changePasswordHandler = () => {
    navigation.navigate('ChangePassword', {})
  }

  const editHandler = () => {
    setIsEditable(!isEditable)
    isEditable ? setEditToggle('Unlock') : setEditToggle('Lock')
  }

  const fullname = useMemo(() => {
    return `${user.firstname} ${user.lastname}`.trim()
  }, [user])

  const handleChoosePhoto = async () => {
    // hide the modal
    setVisible(false)

    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 4],
      quality: 1
    })

    if (!result.cancelled) {
      setPicture(result.uri)
      setSelectedPicture({
        name: user?._id || (Math.random() * 10).toString().replace('.', ''),
        uri: result.uri,
        type: 'image/jpeg'
      })
    }
  }

  const handleTakePhoto = async () => {
    // hide the modal
    setVisible(false)

    let result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 4],
      quality: 0.5
    })

    if (!result.cancelled) {
      setPicture(result.uri)
      setSelectedPicture({
        name: user?._id || (Math.random() * 10).toString().replace('.', ''),
        uri: result.uri,
        type: 'image/jpeg'
      })
    }
  }

  useEffect(() => {
    _editProfile()

    // console.log('_editProfile')
  }, [_editProfile])

  useEffect(() => {
    dispatch(getUserDetails(toast))
    if (isFocused || isCancelled || successUpdate) {
      setIsEditable(false)
      setIsCancelled(false)
    }

    // console.log('getUserDetails')
  }, [dispatch, isFocused, isCancelled, successUpdate])

  return (
    <>
      <ScrollView style={styles.container}>
        <View
          style={[globalStyles.secondaryBgColor, globalStyles.containerBand]}
        ></View>
        <View style={[globalStyles.containerOverlap]}>
          <View style={[globalStyles.containerOverlapInner]}>
            {loading && <ProfileSkeleton />}
            {!loading && user && (
              <View>
                <TouchableOpacity style={styles.edit} onPress={editHandler}>
                  <Ionicons
                    name={
                      isEditable ? 'lock-open-outline' : 'lock-closed-outline'
                    }
                    size={18}
                    color="#999"
                  />
                  <Text
                    style={[
                      globalStyles.text,
                      globalStyles.fwRegular,
                      globalStyles.fs12,
                      globalStyles.darkTextColor,
                      globalStyles.opacity50,
                      { marginLeft: 5, position: 'relative', top: 1 }
                    ]}
                  >
                    {editToggle}
                  </Text>
                </TouchableOpacity>
                <View style={styles.innerContainer} behavior="padding">
                  <View style={{ alignItems: 'center' }}>
                    <View style={{ marginVertical: 0, position: 'relative' }}>
                      {isEditable && (
                        <TouchableOpacity
                          style={styles.photoOverlay}
                          onPress={() => setVisible(true)}
                        >
                          <Text
                            style={[
                              globalStyles.text,
                              globalStyles.fwLight,
                              globalStyles.fs14,
                              globalStyles.whiteTextColor
                            ]}
                          >
                            Change
                          </Text>
                        </TouchableOpacity>
                      )}
                      <Image
                        source={{ uri: picture }}
                        style={{ width: 100, height: 100, borderRadius: 50 }}
                      />
                    </View>
                    <View style={{ marginTop: 15 }}>
                      <Text
                        style={[
                          globalStyles.text,
                          globalStyles.fwRegular,
                          globalStyles.fs18
                        ]}
                      >
                        {fullname || user.username}
                      </Text>
                    </View>
                  </View>
                  <View style={{ marginVertical: 20 }}>
                    <View style={styles.inputContainer} behavior="padding">
                      <View
                        style={[styles.inputLabelWrapper, { marginTop: -8 }]}
                      >
                        <Text style={[styles.inputLabel]}>Username</Text>
                        <Text style={styles.inputLabelNote}>(required)</Text>
                      </View>
                      <TextInput
                        placeholder=""
                        value={username}
                        onChangeText={(text) => setUsername(text)}
                        style={[
                          styles.input,
                          !isEditable && styles.inputNonEditable
                        ]}
                        editable={isEditable}
                        selectTextOnFocus={isEditable}
                        required
                        autoCapitalize="none"
                      />
                      <Text style={styles.note}>
                        A valid username should start with an alphabet, then
                        other characters can be alphabets, numbers or
                        underscores. When its length consists of 6 to 28
                        characters and it's case insensitive (e.g. hyusuf,
                        yusuf22, Yusuf_22, h_yusuf_22).
                      </Text>
                      <View style={styles.inputLabelWrapper}>
                        <Text style={styles.inputLabel}>Email</Text>
                        <Text style={styles.inputLabelNote}>(required)</Text>
                      </View>
                      <TextInput
                        placeholder=""
                        value={email}
                        onChangeText={(text) => setEmail(text)}
                        style={[
                          styles.input,
                          !isEditable && styles.inputNonEditable
                        ]}
                        editable={isEditable}
                        selectTextOnFocus={isEditable}
                        required
                        autoCapitalize="none"
                      />
                      <View style={styles.inputLabelWrapper}>
                        <Text style={styles.inputLabel}>Firstname</Text>
                      </View>
                      <TextInput
                        placeholder=""
                        value={firstname}
                        onChangeText={(text) => setFirstname(text)}
                        style={[
                          styles.input,
                          !isEditable && styles.inputNonEditable
                        ]}
                        editable={isEditable}
                        selectTextOnFocus={isEditable}
                        required
                      />
                      <View style={styles.inputLabelWrapper}>
                        <Text style={styles.inputLabel}>Lastname</Text>
                      </View>
                      <TextInput
                        placeholder=""
                        value={lastname}
                        onChangeText={(text) => setLastname(text)}
                        style={[
                          styles.input,
                          !isEditable && styles.inputNonEditable
                        ]}
                        editable={isEditable}
                        selectTextOnFocus={isEditable}
                        required
                      />
                    </View>
                    <View style={styles.buttonContainer}>
                      <View style={{ marginRight: 5 }}>
                        <FlatButton
                          text="Cancel"
                          transform="uppercase"
                          type="secondary"
                          isDisabled={!isEditable}
                          onPress={() => setIsCancelled(true)}
                        />
                      </View>
                      <FlatButton
                        text="Save"
                        transform="uppercase"
                        type="primary"
                        isDisabled={!isEditable}
                        onPress={saveChangesHandler}
                      />
                    </View>
                    <View style={[globalStyles.separator]} />
                    <View>
                      <TouchableOpacity onPress={changePasswordHandler}>
                        <Text
                          style={[
                            globalStyles.text,
                            globalStyles.primaryTextColor
                          ]}
                        >
                          Change password
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </View>
            )}
          </View>
        </View>
      </ScrollView>
      <ModalUpload
        title="Choose an action"
        message={`Picking image from camera or gallery?`}
        firstAction={handleTakePhoto}
        secondAction={handleChoosePhoto}
        visible={visible}
        setVisible={setVisible}
      />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  innerContainer: {
    marginVertical: 0,
    padding: 16
  },
  inputContainer: {
    marginVertical: 0,
    padding: 0
  },
  input: {
    borderWidth: 1,
    backgroundColor: '#fff',
    color: '#333',
    borderColor: '#e9ecef',
    paddingHorizontal: 16,
    height: 46,
    fontSize: 16,
    lineHeight: 21,
    borderRadius: 6,
    marginVertical: 4,
    fontFamily: 'notosanstc-medium'
  },
  inputNonEditable: {
    backgroundColor: '#fefefe',
    color: '#ccc'
  },
  inputLabelWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10
  },
  inputLabel: {
    color: '#333',
    fontFamily: 'notosanstc-medium',
    fontSize: 14,
    lineHeight: 19,
    marginBottom: 0,
    marginTop: 16,
    marginHorizontal: 1
  },
  inputLabelNote: {
    color: 'rgba(0,0,0, .7)',
    fontFamily: 'notosanstc-light',
    fontSize: 11,
    lineHeight: 16,
    marginBottom: 0,
    marginTop: 16,
    marginHorizontal: 3
  },
  note: {
    fontFamily: 'notosanstc-light',
    fontSize: 11,
    lineHeight: 16,
    color: 'rgba(0,0,0, .7)'
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginVertical: 16
  },
  edit: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginHorizontal: 16,
    marginVertical: 12
  },
  photoOverlay: {
    position: 'absolute',
    width: 100,
    height: 100,
    borderRadius: 50,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 2,
    justifyContent: 'center',
    alignItems: 'center'
  }
})

export default ProfileScreen
