import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity
} from 'react-native'
import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { useToast } from 'react-native-toast-notifications'
import { Ionicons } from '@expo/vector-icons'
import moment from 'moment'

// styles
import globalStyles from '../styles/global'

// config
import { myReviewsPerPage } from '../config'

// components
import NotFound from '../components/NotFound'
import Rating from './Rating'
import ModalEdit from './modals/ModalEdit'
import EditReviewScreen from '../screens/EditReviewScreen'

// skeletons
import MyReviewsSkeleton from './skeletons/MyReviewsSkeleton'

// actions
import {
  myReviewedProducts,
  removeReview
} from '../redux/actions/productActions'

const MyReviews = ({ isFocused }) => {
  const [reviewedItems, setReviewedItems] = useState([])
  const [selectedReviewedProduct, setSelectedReviewedProduct] = useState(null)
  const [selectedReviewTitle, setSelectedReviewTitle] = useState('')
  const [visible, setVisible] = React.useState(false)
  const [removedItem, setRemovedItem] = useState('')
  const [editReviewModalOpen, setEditReviewModalOpen] = useState(false)
  const [closeModal, setCloseModal] = useState(false)

  const navigation = useNavigation()
  const dispatch = useDispatch()
  const toast = useToast()

  // TODO: make this dynamic later (it's hardcoded so far)
  const page = 1

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productMyReviews = useSelector((state) => state.productMyReviews)
  const { items, loading, error } = productMyReviews

  const handleOpenSingleProduct = (productId) => {
    navigation.navigate('ProductSingle', { productId })
  }

  const removeReviewHandler = () => {
    dispatch(removeReview(selectedReviewedProduct._id, toast))
    setVisible(false)
    setRemovedItem(selectedReviewedProduct._id)
  }

  const toggleDrawerHandler = (index) => {
    const updatedReviewedItems = reviewedItems.map((obj, i) => {
      if (i === index) {
        return { ...obj, openDrawer: !obj.openDrawer }
      } else {
        return { ...obj, openDrawer: false }
      }
    })

    setReviewedItems(updatedReviewedItems)
  }

  const _formatItems = useCallback(async () => {
    if (items?.data) {
      const formattedItems = items.data.map((review, index) => {
        return { openDrawer: index === 0 ? true : false, data: review }
      })
      setReviewedItems(formattedItems)
    }
  }, [items])

  useEffect(() => {
    _formatItems()
  }, [_formatItems])

  useEffect(() => {
    dispatch(myReviewedProducts(myReviewsPerPage, page))
  }, [dispatch, page, isFocused, removedItem, closeModal])

  // console.log('items:', items)
  // console.log('reviewedItems:', reviewedItems)

  if (loading) return <MyReviewsSkeleton />

  if (!loading && (error || reviewedItems.length === 0))
    return (
      <NotFound
        message="No reviews found"
        actionLabel="Back Home"
        action={() => {
          navigation.navigate('Home', {})
        }}
      />
    )

  return (
    <>
      <View style={[globalStyles.card, { padding: 0 }]}>
        {!loading &&
          reviewedItems &&
          reviewedItems.map((reviewedItem, index) => {
            // console.log('reviewedItem:', reviewedItem)

            // get the review
            const review = reviewedItem.data.reviews.find(
              (review) => review.user === userInfo._id
            )

            // console.log('review:', review)

            return (
              <View
                style={[
                  globalStyles.drawer,
                  reviewedItems.length === index + 1 && globalStyles.lastDrawer
                ]}
                key={index}
              >
                {reviewedItems.length === 1 ? (
                  <View style={[globalStyles.drawerHeader]}>
                    <Text
                      style={[
                        globalStyles.text,
                        globalStyles.fwLight,
                        globalStyles.fs18
                      ]}
                    >
                      Product #
                      {reviewedItem.data._id.substring(0, 9).toUpperCase()}
                    </Text>
                  </View>
                ) : (
                  <TouchableOpacity
                    style={[globalStyles.drawerHeader]}
                    onPress={() => toggleDrawerHandler(index)}
                  >
                    <Text
                      style={[
                        globalStyles.text,
                        globalStyles.fwLight,
                        globalStyles.fs18
                      ]}
                    >
                      Product #
                      {reviewedItem.data._id.substring(0, 9).toUpperCase()}
                    </Text>
                    <Ionicons
                      name={`md-chevron-${
                        reviewedItem.openDrawer ? 'up' : 'down'
                      }-sharp`}
                      size={22}
                      color="#333"
                    />
                  </TouchableOpacity>
                )}
                {(reviewedItem.openDrawer || reviewedItems.length === 1) && (
                  <View style={[globalStyles.drawerBody]}>
                    <TouchableOpacity
                      style={[
                        globalStyles.vCenterItems,
                        globalStyles.mt2,
                        globalStyles.mb3
                      ]}
                      onPress={() =>
                        handleOpenSingleProduct(reviewedItem.data._id)
                      }
                    >
                      <Image
                        source={{ uri: reviewedItem.data.images[0].imageurl }}
                        style={{ width: 140, height: 140, borderRadius: 140 }}
                      />

                      <View>
                        <Text
                          style={[
                            globalStyles.text,
                            globalStyles.fs18,
                            globalStyles.fwLight,
                            { paddingHorizontal: 60, textAlign: 'center' }
                          ]}
                        >
                          {reviewedItem.data.name}
                        </Text>
                      </View>
                      <View
                        style={[
                          globalStyles.mt2,
                          globalStyles.hAlignItems,
                          globalStyles.hCenterItems
                        ]}
                      >
                        <Rating rating={reviewedItem.data.rating} />
                        <Text
                          style={[
                            globalStyles.text,
                            globalStyles.fwLight,
                            globalStyles.ml1
                          ]}
                        >
                          {reviewedItem.data.numReviews}{' '}
                          {reviewedItem.data.numReviews === 1
                            ? 'review'
                            : 'reviews'}
                        </Text>
                      </View>
                    </TouchableOpacity>

                    <View
                      style={[
                        globalStyles.separator,
                        globalStyles.separatorBottomNarrow
                      ]}
                    />

                    <View style={[globalStyles.mt2, globalStyles.mb4]}>
                      <View
                        style={[
                          globalStyles.hAlignItems,
                          globalStyles.hCenterItems,
                          globalStyles.hBetweenItems,
                          globalStyles.mb4
                        ]}
                      >
                        <View
                          style={[
                            globalStyles.vAlignItems,
                            globalStyles.vStartItems
                          ]}
                        >
                          <Rating rating={review.rating} color={'#000'} />
                        </View>
                        <View
                          style={[
                            globalStyles.hAlignItems,
                            globalStyles.vCenterItems,
                            globalStyles.hEndItems,
                            { marginRight: 1 }
                          ]}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              setSelectedReviewedProduct(reviewedItem.data)
                              setEditReviewModalOpen(true)
                            }}
                          >
                            <Text
                              style={[
                                globalStyles.text,
                                globalStyles.fs11,
                                globalStyles.primaryTextColor,
                                globalStyles.fwBold
                              ]}
                            >
                              Edit
                            </Text>
                          </TouchableOpacity>
                          {/* edit review */}
                          <Text
                            style={[
                              globalStyles.primaryTextColor,
                              globalStyles.ml1,
                              globalStyles.mr1
                            ]}
                          >
                            |
                          </Text>
                          <TouchableOpacity
                            onPress={() => {
                              setSelectedReviewedProduct(reviewedItem.data)
                              setSelectedReviewTitle(review.title)
                              setVisible(true)
                            }}
                          >
                            <Text
                              style={[
                                globalStyles.text,
                                globalStyles.fs11,
                                globalStyles.primaryTextColor,
                                globalStyles.fwBold
                              ]}
                            >
                              Remove
                            </Text>
                          </TouchableOpacity>
                          {/* remove review */}
                        </View>
                      </View>
                      {/* rating */}

                      <View
                        style={[
                          globalStyles.vAlignItems,
                          globalStyles.vStartItems,
                          globalStyles.mb3
                        ]}
                      >
                        <Text
                          style={[
                            globalStyles.text,
                            globalStyles.fwBold,
                            globalStyles.mr1,
                            globalStyles.mb1
                          ]}
                        >
                          Title
                        </Text>
                        <Text
                          style={[
                            globalStyles.text,
                            globalStyles.fwLight,
                            globalStyles.fs13
                          ]}
                        >
                          {review.title}
                        </Text>
                      </View>
                      {/* title */}

                      <View
                        style={[
                          globalStyles.vAlignItems,
                          globalStyles.vStartItems,
                          globalStyles.mb3
                        ]}
                      >
                        <Text
                          style={[
                            globalStyles.text,
                            globalStyles.fwBold,
                            globalStyles.mr1,
                            globalStyles.mb1
                          ]}
                        >
                          Comment
                        </Text>
                        <Text
                          style={[
                            globalStyles.text,
                            globalStyles.fwLight,
                            globalStyles.fs13
                          ]}
                        >
                          {review.comment}
                        </Text>
                      </View>
                      {/* comment */}

                      <View
                        style={[
                          globalStyles.vAlignItems,
                          globalStyles.vStartItems
                        ]}
                      >
                        <Text
                          style={[
                            globalStyles.text,
                            globalStyles.fwBold,
                            globalStyles.fs13,
                            globalStyles.mr1,
                            globalStyles.mb1
                          ]}
                        >
                          Date
                        </Text>
                        <Text style={[globalStyles.text, globalStyles.fwLight]}>
                          {moment(review.createdAt).format('L')}
                        </Text>
                      </View>
                      {/* date */}
                    </View>
                  </View>
                )}
              </View>
            )
          })}
      </View>
      <ModalEdit
        title="Remove Review"
        message={`Are you sure you wish to remove“${selectedReviewTitle}”review?`}
        confirmAction={removeReviewHandler}
        visible={visible}
        setVisible={setVisible}
      />
      <EditReviewScreen
        productId={selectedReviewedProduct?._id}
        editReviewModalOpen={editReviewModalOpen}
        setEditReviewModalOpen={setEditReviewModalOpen}
        productImage={selectedReviewedProduct?.images[0].imageurl}
        productName={selectedReviewedProduct?.name}
        productRating={selectedReviewedProduct?.rating || 0}
        productNumReviews={selectedReviewedProduct?.numReviews}
        closeModal={closeModal}
        setCloseModal={setCloseModal}
      />
    </>
  )
}

const styles = StyleSheet.create({})

export default MyReviews
