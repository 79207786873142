import { StyleSheet, Text, View, ScrollView } from 'react-native'
import React, { useState } from 'react'
import { useNavigation, useIsFocused } from '@react-navigation/native'

// styles
import globalStyles from '../styles/global'

// components
import OrderList from '../components/OrderList'

const OrderListScreen = ({ route }) => {
  const { load } = route.params

  const isFocused = useIsFocused()

  return (
    <ScrollView style={styles.container}>
      <View style={[globalStyles.secondaryBgColor, globalStyles.containerBand]}>
        {false && (
          <View style={[globalStyles.textOverlap]}>
            <View>
              <Text
                style={[
                  globalStyles.text,
                  globalStyles.fwMedium,
                  globalStyles.fs20,
                  globalStyles.blackTextColor,
                  globalStyles.mb1
                ]}
              >
                My Orders
              </Text>
            </View>
          </View>
        )}
      </View>
      <View style={[globalStyles.containerOverlap]}>
        <OrderList load={load} isFocused={isFocused} />
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  innerContainer: {
    marginVertical: 0,
    padding: 16,
    alignItems: 'center'
  }
})

export default OrderListScreen
