import { StyleSheet, Text, View, TouchableOpacity } from 'react-native'
import React, { useState, useEffect } from 'react'
import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { useToast } from 'react-native-toast-notifications'
import { MaterialCommunityIcons } from '@expo/vector-icons'

// styles
import globalStyles from '../../styles/global'

// components
import FlatButton from '../Button'
import Steps from './Steps'

// actions
import { savePaymentMethod } from '../../redux/actions/cartActions'

const Payment = () => {
  const [paymentMethod, setPaymentMethod] = useState('')

  const dispatch = useDispatch()
  const navigation = useNavigation()
  const toast = useToast()

  const cart = useSelector((state) => state.cart)
  const { paymentMethod: cartPaymentMethod } = cart

  const countinueHandler = () => {
    dispatch(savePaymentMethod(paymentMethod, navigation, toast))
    navigation.navigate('Order', {})
  }

  const selectPaymentHandler = () => {
    if (paymentMethod === '') {
      setPaymentMethod('PayPal')
    } else {
      setPaymentMethod('')
    }
  }

  useEffect(() => {
    setPaymentMethod(cartPaymentMethod)
  }, [cartPaymentMethod])

  return (
    <View style={[globalStyles.card]}>
      <Steps step={3} />
      <View>
        <Text
          style={[
            globalStyles.text,
            globalStyles.fwBold,
            globalStyles.fs16,
            globalStyles.mb3
          ]}
        >
          Select Method
        </Text>
        <TouchableOpacity
          style={[
            globalStyles.hAlignItems,
            globalStyles.vCenterItems,
            { marginLeft: -3 }
          ]}
          onPress={selectPaymentHandler}
        >
          {paymentMethod ? (
            <MaterialCommunityIcons
              name="radiobox-marked"
              size={24}
              color="#df143c"
            />
          ) : (
            <MaterialCommunityIcons
              name="radiobox-blank"
              size={24}
              color="#000"
            />
          )}
          <Text
            style={[globalStyles.text, globalStyles.fwLight, globalStyles.ml1]}
          >
            PayPal or Credit Card
          </Text>
        </TouchableOpacity>
        <View style={[globalStyles.mt1]}>
          <Text
            style={[globalStyles.text, globalStyles.fs10, globalStyles.fwLight]}
          >
            This is for demonstration purpose only, thus the payment method is
            not implemented.
          </Text>
        </View>
        <View style={{ marginTop: 20, marginBottom: 10 }}>
          <FlatButton
            text="Continue"
            transform="uppercase"
            type="primary"
            onPress={countinueHandler}
            isDisabled={!paymentMethod}
          />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({})

export default Payment
