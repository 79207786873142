import { StyleSheet, Text, View, Image, Dimensions } from 'react-native'
import React from 'react'

// constants
const DEVICE_WIDTH = Dimensions.get('window').width

const CardItemSkeleton = () => {
  return (
    <View style={[styles.grid]}>
      <View style={[styles.item, styles.itemLeft]}>
        <View style={[styles.itemContainer]}>
          <View style={styles.itemCenter}>
            <View style={[styles.itemImg]}></View>
          </View>
          <View style={[styles.itemText, styles.itemCategory]}></View>
          <View style={[styles.itemText, styles.itemName]}></View>
          <View style={[styles.itemText, styles.itemPrice]}></View>
          <View style={[styles.itemText, styles.itemRating]}></View>
          <View style={[styles.itemAction]}></View>
        </View>
      </View>
      <View style={[styles.item, styles.itemRight]}>
        <View style={[styles.itemContainer]}>
          <View style={styles.itemCenter}>
            <View style={[styles.itemImg]}></View>
          </View>
          <View style={[styles.itemText, styles.itemCategory]}></View>
          <View style={[styles.itemText, styles.itemName]}></View>
          <View style={[styles.itemText, styles.itemPrice]}></View>
          <View style={[styles.itemText, styles.itemRating]}></View>
          <View style={[styles.itemAction]}></View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  grid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start' // if you want to fill rows left to right
  },
  item: {
    width: DEVICE_WIDTH / 2 - 20, // is 50% of container width
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 8
  },
  itemLeft: {
    marginLeft: 16,
    marginRight: 4
  },
  itemRight: {
    marginLeft: 4,
    marginRight: 16
  },
  itemContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    backgroundColor: '#fff',
    borderColor: '#e9ecef',
    borderWidth: 1,
    margin: 8,
    padding: 16,
    width: '100%'
  },
  itemImg: {
    width: 120,
    height: 120,
    borderRadius: 120,
    backgroundColor: 'rgba(15, 23, 42, .1)',
    marginBottom: 15
  },
  itemText: {
    backgroundColor: 'rgba(15, 23, 42, .1)',
    width: '40%',
    padding: 6,
    borderRadius: 10,
    marginBottom: 15
  },
  itemCategory: { backgroundColor: 'rgba(15, 23, 42, .1)' },
  itemName: { width: '70%' },
  itemPrice: { width: '20%' },
  itemRating: { backgroundColor: 'rgba(15, 23, 42, .07)' },
  itemAction: {
    backgroundColor: 'rgba(15, 23, 42, .1)',
    width: '80%',
    padding: 20,
    borderRadius: 5
  }
})

export default CardItemSkeleton
