import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import React from 'react'
import PropTypes from 'prop-types'
import { Ionicons } from '@expo/vector-icons'
import globalStyles from '../styles/global'

const FlatButton = ({
  text,
  transform,
  type,
  isDisabled,
  onPress,
  icon,
  leftIcon,
  rightIcon,
  colorIcon,
  weight,
  size
}) => {
  let btnColor = ''
  let textColor = ''

  switch (type) {
    case 'primary':
      btnColor = styles.primaryBtn
      textColor = styles.primaryText
      break
    case 'primaryOutline':
      btnColor = styles.primaryOutlineBtn
      textColor = styles.primaryOutlineText
      break
    case 'secondary':
      btnColor = styles.secondaryBtn
      textColor = styles.secondaryText
      break
    case 'secondaryOutline':
      btnColor = styles.secondaryOutlineBtn
      textColor = styles.secondaryOutlineText
      break
    case 'dark':
      btnColor = styles.darkBtn
      textColor = styles.darkText
      break
    case 'darkOutline':
      btnColor = styles.darkOutlineBtn
      textColor = styles.darkOutlineText
      break
    case 'light':
      btnColor = styles.lightBtn
      textColor = styles.lightText
      break
    case 'lightOutline':
      btnColor = styles.lightOutlineBtn
      textColor = styles.lightOutlineText
      break
    case 'default':
      btnColor = styles.defaultBtn
      textColor = styles.defaultText
      break
  }

  return (
    <TouchableOpacity onPress={onPress} disabled={isDisabled}>
      <View
        style={[
          styles.button,
          size === 'tag'
            ? styles.buttonTag
            : size === 'small'
            ? styles.buttonSmall
            : '',
          btnColor,
          { opacity: isDisabled ? 0.6 : 1 }
        ]}
      >
        {leftIcon && (
          <View style={{ marginRight: 2 }}>
            <Ionicons
              name={icon}
              size={18}
              color={colorIcon === 'dark' ? '#333' : '#fff'}
            />
          </View>
        )}
        <Text
          style={[
            styles.buttonText,
            weight === 'bold'
              ? styles.buttonTextBold
              : weight === 'light'
              ? styles.buttonTextLight
              : '',
            size === 'tag'
              ? styles.buttonTextTag
              : size === 'small'
              ? styles.buttonTextSmall
              : '',
            textColor,
            { textTransform: transform }
          ]}
        >
          {text}
        </Text>
        {rightIcon && (
          <View style={{ marginLeft: 2 }}>
            <Ionicons
              name={icon}
              size={18}
              color={colorIcon === 'dark' ? '#333' : '#fff'}
            />
          </View>
        )}
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    paddingHorizontal: 16,
    paddingVertical: 10,
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#fff'
  },
  buttonSmall: {},
  buttonTag: {
    paddingLeft: 14,
    paddingRight: 9,
    paddingTop: 2.5,
    paddingBottom: 2.5,
    borderRadius: 50
  },
  buttonText: {
    fontFamily: 'notosanstc-regular',
    color: '#333',
    // textTransform: 'uppercase',
    fontSize: 14,
    lineHeight: 19,
    textAlign: 'center'
  },
  buttonTextSmall: {
    fontSize: 13,
    lineHeight: 18
  },
  buttonTextTag: {
    fontSize: 12,
    lineHeight: 17
  },
  buttonTextLight: {
    fontFamily: 'notosanstc-light'
  },
  buttonTextBold: {
    fontFamily: 'notosanstc-bold'
  },
  defaultBtn: {
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#e9ecef'
  },
  defaultText: {
    color: '#000'
  },

  primaryBtn: {
    backgroundColor: '#df143c',
    borderColor: '#df143c'
  },
  primaryText: {
    color: '#fff'
  },
  primaryOutlineBtn: {
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#df143c'
  },
  primaryOutlineText: {
    color: '#df143c'
  },

  secondaryBtn: {
    backgroundColor: '#f5efe0',
    borderColor: '#f5efe0'
  },
  secondaryText: {
    color: '#333'
  },
  secondaryOutlineBtn: {
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#f5efe0'
  },
  secondaryOutlineText: {
    color: '#f5efe0'
  },

  darkBtn: {
    backgroundColor: '#333',
    borderColor: '#333'
  },
  darkText: {
    color: '#fff'
  },
  darkOutlineBtn: {
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#333'
  },
  darkOutlineText: {
    color: '#333'
  },

  lightBtn: {
    backgroundColor: '#f1f1f1',
    borderColor: '#f1f1f1'
  },
  lightText: {
    color: '#333'
  },
  lightOutlineBtn: {
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#f1f1f1'
  },
  lightOutlineText: {
    color: '#f1f1f1'
  }
})

FlatButton.defaultProps = {
  type: '',
  transform: 'capitalize',
  isDisabled: false,
  leftIcon: false,
  rightIcon: false,
  colorIcon: 'dark',
  weight: 'regular',
  size: ''
}

FlatButton.propTypes = {
  type: PropTypes.string,
  transform: PropTypes.string,
  isDisabled: PropTypes.bool,
  leftIcon: PropTypes.bool,
  rightIcon: PropTypes.bool,
  weight: PropTypes.string,
  size: PropTypes.string
}

export default FlatButton
