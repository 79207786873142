import { StyleSheet, Text, View, TouchableOpacity } from 'react-native'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import PropTypes from 'prop-types'
import { AntDesign } from '@expo/vector-icons'
import { useToast } from 'react-native-toast-notifications'

// styles
import globalStyles from '../styles/global'

// actions
import { likeProduct } from '../redux/actions/productActions'

const Like = ({ size, item, type = '', setUnlikedProduct }) => {
  const [isLikeSubmit, setIsLikeSubmit] = useState(false)

  const dispatch = useDispatch()
  const navigation = useNavigation()
  const toast = useToast()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productLike = useSelector((state) => state.productLike)
  const { product: likedProduct } = productLike

  const [isLiked, setIsLiked] = useState(
    item.likes?.includes(userInfo?._id) || false
  )
  const [likeCount, setLikeCount] = useState(item.numLikes || 0)

  const likeProductHandler = (productId) => {
    if (!userInfo) {
      return navigation.navigate('Login', {})
    }

    dispatch(likeProduct(productId, toast))
    setIsLikeSubmit(true)
    setIsLiked(!isLiked)
    if (setUnlikedProduct) setUnlikedProduct(item._id)
  }

  useEffect(() => {
    if (likedProduct && isLikeSubmit) {
      setLikeCount(likedProduct.numLikes)
      setIsLikeSubmit(false)
    }
  }, [likedProduct, isLikeSubmit])

  return (
    <TouchableOpacity
      style={
        type === 'slide'
          ? styles.likeSlide
          : type === 'details'
          ? styles.likeDetails
          : [
              styles.like,
              (item.countInStock === 0 || item.isNewArrival) &&
                styles.likeMirror
            ]
      }
      onPress={() => likeProductHandler(item._id)}
    >
      <AntDesign
        name={isLiked ? 'heart' : 'hearto'}
        size={size}
        color="#df143c"
      />
      <Text
        style={[
          globalStyles.text,
          type === 'details' ? globalStyles.fs16 : globalStyles.fs12,
          globalStyles.fwLight,
          { marginLeft: 6, color: '#333' }
        ]}
      >
        {likeCount}
      </Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  like: {
    position: 'absolute',
    top: 10,
    right: 12,
    zIndex: 2,
    flexDirection: 'row',
    alignItems: 'center'
  },
  likeMirror: {
    right: undefined,
    left: 12
  },
  likeDetails: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 2,
    flexDirection: 'row',
    alignItems: 'center'
  },
  likeSlide: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    paddingBottom: 16,
    paddingHorizontal: 16,
    zIndex: 2,
    flexDirection: 'row',
    alignItems: 'center'
  }
})

Like.defaultProps = {
  size: 16
}

Like.propTypes = {
  size: PropTypes.number,
  type: PropTypes.string
}

export default Like
