import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_RESET,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_PREVIOUS_PAGE,
  PRODUCT_NEXT_PAGE,
  PRODUCT_REMOVE_REQUEST,
  PRODUCT_REMOVE_SUCCESS,
  PRODUCT_REMOVE_FAIL,
  PRODUCT_ADD_REQUEST,
  PRODUCT_ADD_SUCCESS,
  PRODUCT_ADD_FAIL,
  PRODUCT_EDIT_REQUEST,
  PRODUCT_EDIT_SUCCESS,
  PRODUCT_EDIT_FAIL,
  PRODUCT_EDIT_RESET,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_RESET,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_CREATE_REVIEW_FAIL,
  PRODUCT_CREATE_REVIEW_RESET,
  FEATURED_PRODUCT_LIST_REQUEST,
  FEATURED_PRODUCT_LIST_SUCCESS,
  FEATURED_PRODUCT_LIST_FAIL,
  FEATURED_PRODUCT_LIST_RESET,
  FEATURED_PRODUCT_UPDATE_REQUEST,
  FEATURED_PRODUCT_UPDATE_SUCCESS,
  FEATURED_PRODUCT_UPDATE_FAIL,
  FEATURED_PRODUCT_UPDATE_RESET,
  PRODUCT_LIKE_REQUEST,
  PRODUCT_LIKE_SUCCESS,
  PRODUCT_LIKE_FAIL,
  PRODUCT_LIKE_RESET,
  PRODUCT_WISHLIST_REQUEST,
  PRODUCT_WISHLIST_SUCCESS,
  PRODUCT_WISHLIST_FAIL,
  PRODUCT_WISHLIST_RESET,
  REVIEW_REMOVE_REQUEST,
  REVIEW_REMOVE_SUCCESS,
  REVIEW_REMOVE_FAIL,
  REVIEW_REMOVE_RESET,
  REVIEW_EDIT_REQUEST,
  REVIEW_EDIT_SUCCESS,
  REVIEW_EDIT_FAIL,
  REVIEW_EDIT_RESET,
  REVIEW_UPDATE_REQUEST,
  REVIEW_UPDATE_SUCCESS,
  REVIEW_UPDATE_FAIL,
  REVIEW_UPDATE_RESET,
  PRODUCT_MY_REVIEWS_REQUEST,
  PRODUCT_MY_REVIEWS_SUCCESS,
  PRODUCT_MY_REVIEWS_FAIL
} from '../constants/productConstants'

export const productListReducer = (
  state = {
    products: [],
    meta: {},
    term: '',
    sortBy: '*',
    filter: {}
  },
  action
) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true, ...state }
    case PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload.data,
        meta: action.payload.meta,
        term: action.payload.term,
        sort: action.payload.sort,
        filter: action.payload.filter
      }
    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload }
    case PRODUCT_LIST_RESET:
      return {
        loading: true,
        products: [],
        meta: {},
        term: '',
        filter: {}
      }
    default:
      return state
  }
}

export const productDetailsReducer = (
  state = { product: { reviews: [], rating: 0 } },
  action
) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST:
      return { loading: true }
    case PRODUCT_DETAILS_SUCCESS:
      return { loading: false, product: action.payload }
    case PRODUCT_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productPaginationReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_PREVIOUS_PAGE:
      return { ...state }
    case PRODUCT_NEXT_PAGE:
      return { ...state }
    default:
      return state
  }
}

export const productRemoveReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_REMOVE_REQUEST:
      return { loading: true }
    case PRODUCT_REMOVE_SUCCESS:
      return { loading: false, success: true }
    case PRODUCT_REMOVE_FAIL:
      return { loading: false }
    default:
      return state
  }
}

export const productAddReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_ADD_REQUEST:
      return { loading: true }
    case PRODUCT_ADD_SUCCESS:
      return { loading: false, success: true, order: action.payload }
    case PRODUCT_ADD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productEditReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case PRODUCT_EDIT_REQUEST:
      return { ...state, loading: true }
    case PRODUCT_EDIT_SUCCESS:
      return { loading: false, product: action.payload }
    case PRODUCT_EDIT_FAIL:
      return { loading: false, error: action.payload }
    case PRODUCT_EDIT_RESET:
      return { product: {} }
    default:
      return state
  }
}

export const productUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_UPDATE_REQUEST:
      return { ...state, loading: true }
    case PRODUCT_UPDATE_SUCCESS:
      return { loading: false, success: true, product: action.payload }
    case PRODUCT_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case PRODUCT_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const productCreateReviewReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREATE_REVIEW_REQUEST:
      return { loading: true }
    case PRODUCT_CREATE_REVIEW_SUCCESS:
      return { loading: false, success: true }
    case PRODUCT_CREATE_REVIEW_FAIL:
      return { loading: false, error: action.payload }
    case PRODUCT_CREATE_REVIEW_RESET:
      return {}
    default:
      return state
  }
}

export const featuredProductListReducer = (
  state = {
    products: []
  },
  action
) => {
  switch (action.type) {
    case FEATURED_PRODUCT_LIST_REQUEST:
      return { loading: true, ...state }
    case FEATURED_PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload
      }
    case FEATURED_PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload }
    case FEATURED_PRODUCT_LIST_RESET:
      return {
        loading: true,
        products: []
      }
    default:
      return state
  }
}

export const featuredProductUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case FEATURED_PRODUCT_UPDATE_REQUEST:
      return { loading: true }
    case FEATURED_PRODUCT_UPDATE_SUCCESS:
      return { loading: false, success: true }
    case FEATURED_PRODUCT_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case FEATURED_PRODUCT_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const productLikeReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case PRODUCT_LIKE_REQUEST:
      return { loading: true }
    case PRODUCT_LIKE_SUCCESS:
      return { loading: false, success: true, product: action.payload }
    case PRODUCT_LIKE_FAIL:
      return { loading: false, error: action.payload }
    case PRODUCT_LIKE_RESET:
      return {}
    default:
      return state
  }
}

export const productWishlistReducer = (
  state = { products: [], meta: {} },
  action
) => {
  switch (action.type) {
    case PRODUCT_WISHLIST_REQUEST:
      return { loading: true }
    case PRODUCT_WISHLIST_SUCCESS:
      return {
        loading: false,
        products: action.payload.data,
        meta: action.payload.meta
      }
    case PRODUCT_WISHLIST_FAIL:
      return { loading: false, error: action.payload }
    case PRODUCT_WISHLIST_RESET:
      return { products: [], meta: {} }
    default:
      return state
  }
}

export const reviewRemoveReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case REVIEW_REMOVE_REQUEST:
      return { loading: true }
    case REVIEW_REMOVE_SUCCESS:
      return { loading: false, success: true, product: action.payload }
    case REVIEW_REMOVE_FAIL:
      return { loading: false, error: action.payload }
    case REVIEW_REMOVE_RESET:
      return {}
    default:
      return state
  }
}

export const reviewEditReducer = (state = { review: {} }, action) => {
  switch (action.type) {
    case REVIEW_EDIT_REQUEST:
      return { loading: true }
    case REVIEW_EDIT_SUCCESS:
      return { loading: false, success: true, review: action.payload }
    case REVIEW_EDIT_FAIL:
      return { loading: false, error: action.payload }
    case REVIEW_EDIT_RESET:
      return {}
    default:
      return state
  }
}

export const reviewUpdateReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case REVIEW_UPDATE_REQUEST:
      return { loading: true }
    case REVIEW_UPDATE_SUCCESS:
      return { loading: false, success: true, product: action.payload }
    case REVIEW_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case REVIEW_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const productMyReviewsReducer = (state = { items: [] }, action) => {
  switch (action.type) {
    case PRODUCT_MY_REVIEWS_REQUEST:
      return { loading: true }
    case PRODUCT_MY_REVIEWS_SUCCESS:
      return {
        loading: false,
        items: action.payload
      }
    case PRODUCT_MY_REVIEWS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
