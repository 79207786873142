import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity
} from 'react-native'
import React, { useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { useToast } from 'react-native-toast-notifications'

// styles
import globalStyles from '../styles/global'

// utils
import { currencyFormatter } from '../utils/index'
import {
  removeAllItemsHandler,
  checkoutHandler,
  cartCalculations
} from '../utils/cart'

// components
import CartItemList from '../components/cart/CartItemList'
import CartOrderSummary from '../components/cart/CartOrderSummary'
import NotFound from '../components/NotFound'
import ProductDetailsScreen from './ProductDetailsScreen'

// actions
import { addToCart, removeAllItemsFromCart } from '../redux/actions/cartActions'

const CartScreen = () => {
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const toast = useToast()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  const processCheckoutHandler = () => {
    navigation.navigate(userInfo ? 'Shipping' : 'SignIn', {})
  }

  // calculations
  cartCalculations(cart, cartItems)

  return (
    <>
      <ScrollView style={styles.container}>
        <View
          style={[globalStyles.secondaryBgColor, globalStyles.containerBand]}
        >
          {cartItems && cartItems.length > 0 && (
            <View style={[globalStyles.textOverlap]}>
              <View>
                <Text
                  style={[
                    globalStyles.text,
                    globalStyles.fwMedium,
                    globalStyles.fs20,
                    globalStyles.blackTextColor,
                    globalStyles.mb1
                  ]}
                >
                  Shopping Cart
                </Text>
                <TouchableOpacity
                  onPress={() => {
                    removeAllItemsHandler(
                      dispatch,
                      removeAllItemsFromCart,
                      navigation,
                      toast
                    )
                  }}
                >
                  <Text
                    style={[
                      globalStyles.text,
                      globalStyles.fwMedium,
                      globalStyles.fs13,
                      globalStyles.primaryTextColor
                    ]}
                  >
                    Clear the cart
                  </Text>
                </TouchableOpacity>
              </View>
              <View>
                <Text
                  style={[
                    globalStyles.text,
                    globalStyles.fwMedium,
                    globalStyles.blackTextColor
                  ]}
                >
                  {cart.itemSubtotal}{' '}
                  {cart.itemSubtotal === 1 ? 'item' : 'items'}
                </Text>
              </View>
            </View>
          )}
        </View>
        <View style={[globalStyles.containerOverlap]}>
          {cartItems && cartItems.length === 0 && (
            <NotFound
              message="Your cart is empty"
              actionLabel="Start Shopping"
              action={() => {
                navigation.navigate('Home', {})
              }}
            />
          )}
          {cartItems && cartItems.length > 0 && (
            <CartItemList items={cartItems} />
          )}
        </View>
        {cartItems && cartItems.length > 0 && (
          <View style={{ paddingHorizontal: 16, paddingBottom: 40 }}>
            <CartOrderSummary
              itemSubtotal={cart.itemSubtotal}
              itemTotalPrice={currencyFormatter(cart.itemTotalPrice)}
              deliveryFee={
                cart.shippingPrice === 0
                  ? 'Free'
                  : currencyFormatter(cart.shippingPrice)
              }
              taxLabel={'Estimated tax'}
              tax={currencyFormatter(cart.taxPrice)}
              orderTotalLabel={'Order Total'}
              orderTotalPrice={currencyFormatter(cart.orderTotalPrice)}
              cartItems={cartItems}
              actionLabel={'Process To Checkout'}
              action={processCheckoutHandler}
              isItemsShown={false}
            />
          </View>
        )}
      </ScrollView>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  innerContainer: {
    marginVertical: 0,
    padding: 16,
    alignItems: 'center'
  }
})

export default CartScreen
