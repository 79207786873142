// currency formatter
const currencyFormatter = (value, options) => {
  const defaultOptions = {
    significantDigits: 2,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbol: '$'
  }

  if (typeof value !== 'number') value = 0.0
  options = { ...defaultOptions, ...options }
  value = value.toFixed(options.significantDigits)

  const [currency, decimal] = value.split('.')
  return `${options.symbol}${currency.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    options.thousandsSeparator
  )}${options.decimalSeparator}${decimal}`
}

// capitalize first letter
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

// check empty object
const isEmptyObj = (obj) => {
  return Object.keys(obj).length === 0
}

// get mime type
const getMimeType = (base64) => {
  const mimeType = base64.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]
  return mimeType
}

export { currencyFormatter, capitalizeFirstLetter, isEmptyObj, getMimeType }
