import { StyleSheet, Text, View } from 'react-native'
import React, { useState } from 'react'

// styles
import globalStyles from '../../styles/global'

const MyReviewsSkeleton = () => {
  const [reviews] = useState([1, 2, 3, 4, 5])

  return (
    <View style={[globalStyles.card, { padding: 0 }]}>
      {reviews.map((review, index) => (
        <View
          style={[
            globalStyles.drawer,
            styles.drawerSize,
            reviews.length === index + 1 && globalStyles.lastDrawer
          ]}
          key={index}
        >
          <View
            style={[globalStyles.drawerHeader, styles.drawerHeaderTitle]}
          ></View>
        </View>
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  drawerSize: {
    paddingHorizontal: 16,
    paddingVertical: 22
  },
  drawerHeaderTitle: {
    backgroundColor: 'rgba(15, 23, 42, .1)',
    width: '100%',
    padding: 6,
    borderRadius: 10
  }
})

export default MyReviewsSkeleton
