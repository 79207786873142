import { StyleSheet, Text, View, Image } from 'react-native'
import React from 'react'

// styles
import globalStyles from '../styles/global'

// components
import FlatButton from './Button'

const NotFoud = ({ message, actionLabel, action }) => {
  return (
    <View style={[globalStyles.containerOverlapInner]}>
      <View style={styles.innerContainer} behavior="padding">
        <View style={{ marginVertical: 10 }}>
          <Image
            source={require('../assets/logos/shot-logo-dark-82x142.png')}
            style={{ width: 41, height: 71 }}
          />
        </View>
        <View style={{ marginVertical: 20 }}>
          <Text
            style={[globalStyles.text, globalStyles.fwLight, globalStyles.fs18]}
          >
            {message}
          </Text>
        </View>
        <FlatButton
          text={actionLabel}
          transform="uppercase"
          type="primary"
          onPress={action}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  innerContainer: {
    marginVertical: 0,
    padding: 16,
    alignItems: 'center'
  }
})

export default NotFoud
