import { StyleSheet, Text, View, ScrollView, Image } from 'react-native'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation, useIsFocused } from '@react-navigation/native'
import { useToast } from 'react-native-toast-notifications'

// styles
import globalStyles from '../styles/global'

// data
import { wishlistTtemsFeed } from '../data'

// components
import NotFound from '../components/NotFound'
import WishlistFilter from '../components/WishlistFilter'
import WishList from '../components/WishList'

// actions
import { wishlistProducts } from '../redux/actions/productActions'

const WishlistScreen = () => {
  const [filter, setFilter] = useState({})

  const dispatch = useDispatch()
  const navigation = useNavigation()
  const isFocused = useIsFocused()

  const productWishlist = useSelector((state) => state.productWishlist)
  const { products, meta, loading, error } = productWishlist

  // console.log('meta:', meta)

  return (
    <View style={[styles.container]}>
      <View style={[globalStyles.secondaryBgColor, globalStyles.containerBand]}>
        {meta && meta.total_products !== 0 && (
          <View style={[globalStyles.mt3, globalStyles.mb2]}>
            <WishlistFilter
              theme="dark"
              filter={filter}
              setFilter={setFilter}
            />
          </View>
        )}
      </View>
      {!loading && products && products.length === 0 && (
        <View style={[globalStyles.containerOverlap, { flex: 1 }]}>
          <NotFound
            message="No items saved"
            actionLabel="back home"
            action={() => {
              navigation.navigate('Home', {})
            }}
          />
        </View>
      )}
      <View
        style={[
          globalStyles.containerOverlap,
          globalStyles.containerOverlapNoGap,
          { flex: 1 }
        ]}
      >
        <WishList
          sort={filter.value ? filter.value : '*'}
          isFocused={isFocused}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  innerContainer: {
    marginVertical: 0,
    padding: 16,
    alignItems: 'center'
  }
})

export default WishlistScreen
