import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TextInput,
  TouchableOpacity
} from 'react-native'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { useToast } from 'react-native-toast-notifications'

// styles
import globalStyles from '../styles/global'

// components
import FlatButton from '../components/Button'

// actions
import { forgotUserPassword } from '../redux/actions/userActions'

const ForgotPasswordScreen = () => {
  const [identifier, setIdentifier] = useState('')

  const dispatch = useDispatch()
  const navigation = useNavigation()
  const toast = useToast()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userInfoLogin } = userLogin

  const userForgotPassword = useSelector((state) => state.userForgotPassword)
  const { loading, error, userInfo } = userForgotPassword

  const forgotPasswordHandler = () => {
    dispatch(forgotUserPassword(identifier, navigation, toast))
  }

  useEffect(() => {
    if (userInfoLogin) navigation.navigate('Home', {})
  }, [navigation, userInfoLogin])

  return (
    <>
      {!userInfoLogin && (
        <ScrollView style={styles.container}>
          <View
            style={[globalStyles.secondaryBgColor, globalStyles.containerBand]}
          >
            {false && (
              <View style={[globalStyles.textOverlap]}>
                <View>
                  <Text
                    style={[
                      globalStyles.text,
                      globalStyles.fwMedium,
                      globalStyles.fs20,
                      globalStyles.blackTextColor,
                      globalStyles.mb1
                    ]}
                  >
                    Forgot Password
                  </Text>
                </View>
              </View>
            )}
          </View>
          <View style={[globalStyles.containerOverlap]}>
            <View style={[globalStyles.containerOverlapInner]}>
              <View style={styles.inputContainer} behavior="padding">
                <View style={[styles.inputLabelWrapper, { marginTop: -8 }]}>
                  <Text style={[styles.inputLabel]}>Email / Username</Text>
                </View>
                <TextInput
                  placeholder=""
                  value={identifier}
                  onChangeText={(text) => setIdentifier(text)}
                  style={styles.input}
                  required
                  autoCapitalize="none"
                />
                <View style={styles.buttonContainer}>
                  <FlatButton
                    text="Reset Password"
                    transform="uppercase"
                    type="primary"
                    onPress={forgotPasswordHandler}
                  />
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  inputContainer: {
    marginVertical: 0,
    padding: 16
  },
  input: {
    borderWidth: 1,
    backgroundColor: '#fff',
    borderColor: '#e9ecef',
    paddingHorizontal: 16,
    height: 46,
    fontSize: 16,
    lineHeight: 21,
    borderRadius: 6,
    marginVertical: 4,
    fontFamily: 'notosanstc-medium'
  },
  inputLabelWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10
  },
  inputLabel: {
    color: '#333',
    fontFamily: 'notosanstc-medium',
    fontSize: 14,
    lineHeight: 19,
    marginBottom: 0,
    marginTop: 16,
    marginHorizontal: 1
  },
  inputLabelNote: {
    color: 'rgba(0,0,0, .7)',
    fontFamily: 'notosanstc-light',
    fontSize: 11,
    lineHeight: 16,
    marginBottom: 0,
    marginTop: 16,
    marginHorizontal: 3
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginVertical: 16
  },
  copyContainer: {
    flexDirection: 'row'
  },
  copyText: {
    color: '#333',
    fontFamily: 'notosanstc-light',
    marginHorizontal: 1
  },
  copyLink: {
    color: '#df143c',
    marginLeft: 5,
    borderBottomColor: '#df143c',
    fontFamily: 'notosanstc-bold'
  },
  separator: {
    marginVertical: 20,
    borderBottomWidth: 1,
    borderColor: '#E9ECEF'
  },
  separatorBottomNarrow: {
    marginBottom: 10
  }
})

export default ForgotPasswordScreen
