import 'react-native-gesture-handler'

import { StatusBar } from 'expo-status-bar'
import AppLoading from 'expo-app-loading'
import { useFonts } from 'expo-font'
import { StyleSheet, Text, View, Dimensions } from 'react-native'
import React, { useState, useEffect } from 'react'
import { NavigationContainer } from '@react-navigation/native'
import { ToastProvider } from 'react-native-toast-notifications'
import { Provider } from 'react-redux'

// styles
import globalStyles from './styles/global'

// navigations
import StackNavigator from './navigations/StackNavigator'
import AlertIcon from './components/AlertIcon'

// local storage
import { getLocalStorage } from './storage'

// redux store
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './redux/reducers'

// constants
const DEVICE_WIDTH = Dimensions.get('window').width

const App = () => {
  const [recentCarItems, setRecentCarItems] = useState([])
  const [recentShippingAddress, setRecentShippingAddress] = useState({})
  const [recentPaymentMethod, setRecentPaymentMethod] = useState('')
  const [recentUserInfo, setRecentUserInfo] = useState(null)

  const initialState = {
    cart: {
      cartItems: recentCarItems,
      shippingAddress: recentShippingAddress,
      paymentMethod: recentPaymentMethod
    },
    userLogin: { userInfo: recentUserInfo }
  }
  const middleware = [thunk]
  const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  )

  useEffect(() => {
    const fetchLocalStorage = async () => {
      const cartItemsVal = await getLocalStorage('cartItems')
      if (cartItemsVal !== undefined && cartItemsVal !== null) {
        setRecentCarItems(cartItemsVal)
      }
      const shippingAddressVal = await getLocalStorage('shippingAddress')
      if (shippingAddressVal !== undefined && shippingAddressVal !== null) {
        setRecentShippingAddress(shippingAddressVal)
      }
      const paymentMethodVal = await getLocalStorage('paymentMethod')
      if (paymentMethodVal !== undefined && paymentMethodVal !== null) {
        setRecentPaymentMethod(paymentMethodVal)
      }
      const userInfoVal = await getLocalStorage('userInfo')
      if (userInfoVal !== undefined && userInfoVal !== null) {
        setRecentUserInfo(userInfoVal)
      }
    }
    fetchLocalStorage()
  }, [])

  let [fontsLoaded] = useFonts({
    'notosanstc-black': require('./assets/fonts/NotoSansTC-Black.otf'),
    'notosanstc-bold': require('./assets/fonts/NotoSansTC-Bold.otf'),
    'notosanstc-light': require('./assets/fonts/NotoSansTC-Light.otf'),
    'notosanstc-medium': require('./assets/fonts/NotoSansTC-Medium.otf'),
    'notosanstc-regular': require('./assets/fonts/NotoSansTC-Regular.otf'),
    'notosanstc-thin': require('./assets/fonts/NotoSansTC-Thin.otf')
  })

  if (!fontsLoaded) return <AppLoading />

  return (
    <Provider store={store}>
      <ToastProvider
        placement="top"
        duration={1000}
        animationType="zoom-in"
        animationDuration={250}
        offset={50}
        offsetTop={100}
        offsetBottom={40}
        swipeEnabled={true}
        successColor="#07bc0c"
        dangerColor="#e74c3c"
        warningColor="#f1c40f"
        successIcon={<AlertIcon type="success" />}
        dangerIcon={<AlertIcon type="danger" />}
        warningIcon={<AlertIcon type="warning" />}
        renderType={{
          info: (toast) => (
            <View
              style={[
                globalStyles.toastContainer,
                {
                  maxWidth: (DEVICE_WIDTH / 10) * 9,
                  width: '100%',
                  alignItems: 'center',
                  backgroundColor: '#3498db'
                }
              ]}
            >
              <AlertIcon type="info" />
              <Text style={{ color: '#fff', flex: 1 }}>{toast.message}</Text>
            </View>
          ),
          danger: (toast) => (
            <View
              style={[
                globalStyles.toastContainer,
                {
                  maxWidth: (DEVICE_WIDTH / 10) * 9,
                  width: '100%',
                  alignItems: 'center',
                  backgroundColor: '#e74c3c'
                }
              ]}
            >
              <AlertIcon type="info" />
              <Text style={{ color: '#fff', flex: 1 }}>{toast.message}</Text>
            </View>
          )
        }}
        textStyle={{}}
      >
        <NavigationContainer>
          <StackNavigator />
          <StatusBar style="light" />
        </NavigationContainer>
      </ToastProvider>
    </Provider>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center'
  }
})

export default App
