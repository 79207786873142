import {
  StyleSheet,
  Text,
  View,
  TextInput,
  TouchableOpacity
} from 'react-native'
import React, { useState, useEffect } from 'react'
import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { useToast } from 'react-native-toast-notifications'
import { Ionicons } from '@expo/vector-icons'

// styles
import globalStyles from '../../styles/global'

// components
import FlatButton from '../Button'
import Steps from './Steps'

//actions
import { login } from '../../redux/actions/userActions'

const SignIn = () => {
  const [identifier, setIdentifier] = useState('')
  const [password, setPassword] = useState('')
  const [isPasswordHidden, setIsPasswordHidden] = useState(true)

  const dispatch = useDispatch()
  const navigation = useNavigation()
  const toast = useToast()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const loginHandler = () => {
    dispatch(login(identifier, password, toast))
  }

  useEffect(() => {
    if (userInfo) navigation.navigate('Shipping', {})
  }, [userInfo])

  return (
    <View style={[globalStyles.card]}>
      <Steps step={1} />
      <View>
        <View style={globalStyles.inputContainer} behavior="padding">
          <View style={[globalStyles.inputLabelWrapper, { marginTop: -8 }]}>
            <Text style={[globalStyles.inputLabel]}>Email / Username</Text>
          </View>
          <TextInput
            placeholder=""
            value={identifier}
            onChangeText={(text) => setIdentifier(text)}
            style={globalStyles.input}
            required
            autoCapitalize="none"
          />
          <View style={[globalStyles.inputLabelWrapper]}>
            <Text style={[globalStyles.inputLabel]}>Password</Text>
          </View>
          <View style={styles.passwordField}>
            <TextInput
              placeholder=""
              value={password}
              onChangeText={(text) => setPassword(text)}
              style={globalStyles.input}
              secureTextEntry={isPasswordHidden}
              required
              autoCapitalize="none"
            />
            <TouchableOpacity
              style={styles.eyeIcon}
              onPress={() => {
                setIsPasswordHidden(!isPasswordHidden)
              }}
            >
              <Ionicons
                name={`${
                  isPasswordHidden ? 'ios-eye-outline' : 'ios-eye-off-outline'
                }`}
                size={24}
                color="rgba(0,0,0,.25)"
              />
            </TouchableOpacity>
          </View>
          <View style={styles.buttonContainer}>
            <FlatButton
              text="Login"
              transform="uppercase"
              type="primary"
              onPress={loginHandler}
            />
            <FlatButton
              text="Forgot Password?"
              transform="capitalize"
              type=""
              onPress={() => navigation.navigate('ForgotPassword')}
            />
          </View>
          <View style={[globalStyles.separator]}></View>
          <View style={[styles.copyContainer]}>
            <Text style={styles.copyText}>Need an account?</Text>
            <TouchableOpacity onPress={() => navigation.navigate('Register')}>
              <Text style={styles.copyLink}>Register</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 16,
    marginBottom: 12
  },
  passwordField: {
    position: 'relative',
    marginHorizontal: 0
  },
  eyeIcon: {
    position: 'absolute',
    right: 12,
    top: 15.5
  },
  copyContainer: {
    flexDirection: 'row',
    marginBottom: 6
  },
  copyText: {
    color: '#333',
    fontFamily: 'notosanstc-light',
    marginHorizontal: 1,
    fontSize: 14,
    lineHeight: 19
  },
  copyLink: {
    color: '#df143c',
    marginLeft: 5,
    borderBottomColor: '#df143c',
    fontFamily: 'notosanstc-bold',
    fontSize: 14,
    lineHeight: 19
  }
})

export default SignIn
