import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Alert
} from 'react-native'
import React, { useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { useToast } from 'react-native-toast-notifications'
import moment from 'moment'

// components
import globalStyles from '../styles/global'
import Rating from './Rating'
import ModalEdit from './modals/ModalEdit'

// actions
import { removeReview } from '../redux/actions/productActions'

const Review = ({
  productId,
  review,
  separator = true,
  shown,
  editReviewModalOpen,
  setEditReviewModalOpen,
  closeModal,
  setCloseModal
}) => {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // TODO: change password modal
  const [visible, setVisible] = React.useState(false)

  const navigation = useNavigation()
  const dispatch = useDispatch()
  const toast = useToast()

  // const removeReviewHandler = (id, title) => {
  //   Alert.alert(
  //     'Remove Review',
  //     `Are you sure you wish to remove "${title}" review?`,
  //     [
  //       {
  //         text: 'Cancel',
  //         onPress: () => console.log('cancel'),
  //         style: 'cancel'
  //       },
  //       { text: 'Ok', onPress: () => dispatch(removeReview(productId)) }
  //     ]
  //   )
  // }

  const editReviewHandler = (id, title) => {
    setEditReviewModalOpen(!editReviewModalOpen)
  }

  const removeReviewHandler = () => {
    dispatch(removeReview(productId, toast))
    setVisible(false)

    setCloseModal(!closeModal)
    // setCloseModal(Math.random())
  }

  return (
    <>
      {shown && (
        <View style={[styles.reviewWrapper]}>
          <View style={[styles.reviewHeader, globalStyles.mb3]}>
            <View>
              <Rating rating={parseFloat(review.rating)} />
            </View>
            <Text
              style={[
                globalStyles.text,
                globalStyles.fwLight,
                globalStyles.fs13
              ]}
            >
              {moment(review.createdAt).format('LL')}
            </Text>
          </View>
          <View style={[styles.reviewTitle, globalStyles.mb2]}>
            <Text
              style={[
                globalStyles.text,
                globalStyles.fwBold,
                globalStyles.fs17
              ]}
            >
              {review.title}
            </Text>
          </View>
          <View style={[styles.reviewBody, globalStyles.mb3]}>
            <Text
              style={[
                globalStyles.text,
                globalStyles.fwLight,
                globalStyles.fs13
              ]}
            >
              {review.comment}
            </Text>
          </View>
          <View style={[styles.reviewFooter]}>
            <View style={[globalStyles.hAlignItems, globalStyles.vCenterItems]}>
              <Image
                source={{
                  uri: review.picture
                }}
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: 30,
                  marginRight: 6
                }}
              />
              <Text
                style={[
                  globalStyles.text,
                  globalStyles.fwBold,
                  { marginLeft: 4, marginTop: 0 }
                ]}
              >
                {review.name}
              </Text>
              {/* <Text
            style={[
              globalStyles.text,
              globalStyles.fwLight,
              globalStyles.fs12,
              { marginLeft: 4, marginTop: 2 }
            ]}
          >
            - Verified Purchase
          </Text> */}
            </View>
            {userInfo && userInfo._id === review.user && (
              <View
                style={[
                  globalStyles.hAlignItems,
                  globalStyles.vCenterItems,
                  { marginTop: 2 }
                ]}
              >
                <TouchableOpacity
                  onPress={() => editReviewHandler(review._id, review.title)}
                >
                  <Text
                    style={[
                      globalStyles.text,
                      globalStyles.fs11,
                      globalStyles.primaryTextColor,
                      globalStyles.fwBold
                    ]}
                  >
                    Edit
                  </Text>
                </TouchableOpacity>
                <Text
                  style={[
                    globalStyles.primaryTextColor,
                    globalStyles.ml1,
                    globalStyles.mr1
                  ]}
                >
                  |
                </Text>
                <TouchableOpacity onPress={() => setVisible(true)}>
                  <Text
                    style={[
                      globalStyles.text,
                      globalStyles.fs11,
                      globalStyles.primaryTextColor,
                      globalStyles.fwBold
                    ]}
                  >
                    Remove
                  </Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
          {separator && <View style={globalStyles.separator}></View>}
        </View>
      )}
      <ModalEdit
        title="Remove Review"
        message={`Are you sure you wish to remove“${review.title}”review?`}
        confirmAction={removeReviewHandler}
        visible={visible}
        setVisible={setVisible}
      />
    </>
  )
}

const styles = StyleSheet.create({
  reviewWrapper: {},
  reviewHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  reviewTitle: {},
  reviewBody: {},
  reviewFooter: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

export default Review
