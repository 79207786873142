import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

// styles
import globalStyles from '../../styles/global'

const OrderDetailsSkeleton = () => {
  return (
    <>
      <View style={[globalStyles.card, globalStyles.mb3]}>
        <View style={[styles.title, , globalStyles.mt1, globalStyles.mb4]} />
        <View style={[styles.text]} />
        <View style={[styles.text]} />
        <View style={[styles.text]} />
        <View style={[styles.status]} />
        <View style={[globalStyles.separator]} />
        <View style={[styles.title, globalStyles.mb4]} />
        <View style={[styles.text]} />
        <View style={[styles.status]} />
      </View>
      <View style={[globalStyles.card]}>
        <View style={[styles.title, globalStyles.mt1]} />
        <View style={[globalStyles.separator]} />
        <View style={[globalStyles.hAlignItems, globalStyles.vCenterItems]}>
          <View style={[styles.orderAvatar, globalStyles.mr3]} />
          <View style={[globalStyles.flexOne]}>
            <View style={[styles.orderItemTitle]} />
            <View style={[styles.orderItemPrice]} />
          </View>
        </View>
        <View style={[globalStyles.separator]} />
        <View
          style={[
            globalStyles.hAlignItems,
            globalStyles.hBetweenItems,
            globalStyles.vCenterItems,
            globalStyles.mb2
          ]}
        >
          <View style={[styles.orderItemTitle, styles.orderItemLeft]} />
          <View style={[styles.orderItemPrice, styles.orderItemRight]} />
        </View>
        <View
          style={[
            globalStyles.hAlignItems,
            globalStyles.hBetweenItems,
            globalStyles.vCenterItems
          ]}
        >
          <View style={[styles.orderItemTitle, styles.orderItemLeft]} />
          <View style={[styles.orderItemPrice, styles.orderItemRight]} />
        </View>
        <View style={[globalStyles.separator]} />
        <View
          style={[
            globalStyles.hAlignItems,
            globalStyles.hBetweenItems,
            globalStyles.vCenterItems
          ]}
        >
          <View style={[styles.orderItemTitle, styles.orderItemLeft]} />
          <View style={[styles.orderItemPrice, styles.orderItemRight]} />
        </View>
        <View style={[styles.orderAction]} />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  title: {
    backgroundColor: 'rgba(15, 23, 42, .1)',
    width: 220,
    padding: 8,
    borderRadius: 10
  },
  text: {
    backgroundColor: 'rgba(15, 23, 42, .05)',
    width: '100%',
    padding: 6,
    borderRadius: 10,
    marginVertical: 4
  },
  status: {
    backgroundColor: 'rgba(15, 23, 42, .1)',
    width: 140,
    padding: 6,
    borderRadius: 10,
    marginTop: 20
  },
  orderHeading: {},
  orderAvatar: {
    backgroundColor: 'rgba(15, 23, 42, .1)',
    width: 60,
    height: 60,
    borderRadius: 60
  },
  orderAvatarBadge: {},
  orderItemTitle: {
    backgroundColor: 'rgba(15, 23, 42, .1)',
    width: '70%',
    padding: 6,
    borderRadius: 10,
    marginVertical: 6
  },
  orderItemPrice: {
    backgroundColor: 'rgba(15, 23, 42, .05)',
    width: '40%',
    padding: 6,
    borderRadius: 10,
    marginVertical: 6
  },
  orderItemLeft: {
    width: 160
  },
  orderItemRight: {
    width: 30
  },
  orderAction: {
    backgroundColor: 'rgba(15, 23, 42, .05)',
    width: '100%',
    height: 46,
    borderRadius: 5,
    marginTop: 20
  }
})

export default OrderDetailsSkeleton
