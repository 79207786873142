import { StyleSheet, View, Text } from 'react-native'
import React from 'react'
import Svg, {
  SvgProps,
  Path,
  Polygon,
  G,
  Rect,
  Line,
  Text as SvgText
} from 'react-native-svg'

const Ribbon = ({ text, bgColor, textColor }) => {
  return (
    <Svg height="70" width="70">
      <Polygon points="0 0, 0 5, 5 5" fill={bgColor} strokeWidth="0" />
      <Polygon
        points="0 0, 70 70, 70 40, 30 0"
        fill={bgColor}
        strokeWidth="0"
      />
      <Polygon points="65 65, 65 70, 70 70" fill={bgColor} strokeWidth="0" />
      <G rotation="45" origin="130, -20">
        <SvgText
          x="101"
          y="80"
          fill={textColor}
          textAnchor="middle"
          //   fontFamily="notosanstc-bold"
          fontWeight={600}
          fontSize={10}
        >
          {text}
        </SvgText>
      </G>
    </Svg>
  )
}

const styles = StyleSheet.create({})

export default Ribbon
