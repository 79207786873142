import { StyleSheet, Text, View, TouchableOpacity } from 'react-native'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AntDesign } from '@expo/vector-icons'
import { useToast } from 'react-native-toast-notifications'

// styles
import globalStyles from '../styles/global'

// utils
import { removeFromCartHandler } from '../utils/cart'

// actions
import { addToCart, removeFromCart } from '../redux/actions/cartActions'

const Counter = ({
  productId,
  count,
  setCount,
  countInStock,
  isRemovable = false,
  updateCart = false
}) => {
  // const [count, setCount] = useState(quantity)
  const [isMin, setIsMin] = useState(false)
  const [isMax, setIsMax] = useState(false)

  const dispatch = useDispatch()
  const toast = useToast()

  const incrementQuantityHandler = () => {
    setCount(count + 1)
    if (updateCart) {
      dispatch(addToCart(productId, count + 1, toast))
    }
  }

  const decrementQuantityHandler = () => {
    if (count > 1) {
      setCount(count - 1)
      if (updateCart) {
        dispatch(
          addToCart(
            productId,
            count - 1,
            toast,
            'Product removed from the cart'
          )
        )
      }
    } else if (count === 1) {
      // TODO: remove item from cart
      console.log('TODO: remove item from cart')
      if (updateCart) {
        removeFromCartHandler(productId, dispatch, removeFromCart, toast)
      }
    }
  }

  useEffect(() => {
    if (count === 1 && !isRemovable) {
      setIsMin(true)
    } else {
      setIsMin(false)
    }

    if (count === countInStock) {
      setIsMax(true)
    } else {
      setIsMax(false)
    }
  }, [count])

  return (
    <View style={[globalStyles.hAlignItems, globalStyles.vCenterItems]}>
      <TouchableOpacity onPress={decrementQuantityHandler} disabled={isMin}>
        <View style={[{ opacity: isMin ? 0.2 : 1 }]}>
          <AntDesign
            name="minuscircleo"
            size={24}
            color={isRemovable && count === 1 ? '#df143c' : '#000'}
          />
          {/* {isRemovable && count === 1 ? (
            <AntDesign name="closecircleo" size={24} color="#df143c" />
          ) : (
            <AntDesign name="minuscircleo" size={24} color="#000" />
          )} */}
        </View>
      </TouchableOpacity>
      <View style={[styles.quatity]}>
        <Text
          style={[
            globalStyles.text,
            globalStyles.fs16,
            globalStyles.blackTextColor
          ]}
        >
          {count}
        </Text>
      </View>
      <TouchableOpacity onPress={incrementQuantityHandler} disabled={isMax}>
        <View style={[{ opacity: isMax ? 0.2 : 1 }]}>
          <AntDesign name="pluscircleo" size={24} color="#000" />
        </View>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  quatity: { height: 30, paddingHorizontal: 15, justifyContent: 'center' }
})

export default Counter
