import axios from 'axios'
// import { toast } from 'react-toastify'

import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_RESET,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_REMOVE_REQUEST,
  PRODUCT_REMOVE_SUCCESS,
  PRODUCT_REMOVE_FAIL,
  PRODUCT_ADD_REQUEST,
  PRODUCT_ADD_SUCCESS,
  PRODUCT_ADD_FAIL,
  PRODUCT_EDIT_RESET,
  PRODUCT_EDIT_REQUEST,
  PRODUCT_EDIT_SUCCESS,
  PRODUCT_EDIT_FAIL,
  PRODUCT_UPDATE_RESET,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  FEATURED_PRODUCT_LIST_RESET,
  FEATURED_PRODUCT_LIST_REQUEST,
  FEATURED_PRODUCT_LIST_SUCCESS,
  FEATURED_PRODUCT_LIST_FAIL,
  FEATURED_PRODUCT_UPDATE_REQUEST,
  FEATURED_PRODUCT_UPDATE_SUCCESS,
  FEATURED_PRODUCT_UPDATE_RESET,
  PRODUCT_LIKE_REQUEST,
  PRODUCT_LIKE_SUCCESS,
  PRODUCT_LIKE_FAIL,
  PRODUCT_LIKE_RESET,
  PRODUCT_WISHLIST_RESET,
  PRODUCT_WISHLIST_REQUEST,
  PRODUCT_WISHLIST_SUCCESS,
  PRODUCT_WISHLIST_FAIL,
  REVIEW_EDIT_REQUEST,
  REVIEW_REMOVE_REQUEST,
  REVIEW_REMOVE_SUCCESS,
  REVIEW_REMOVE_FAIL,
  REVIEW_REMOVE_RESET,
  REVIEW_EDIT_SUCCESS,
  REVIEW_EDIT_FAIL,
  REVIEW_EDIT_RESET,
  PRODUCT_CREATE_REVIEW_RESET,
  REVIEW_UPDATE_RESET,
  REVIEW_UPDATE_REQUEST,
  REVIEW_UPDATE_SUCCESS,
  REVIEW_UPDATE_FAIL,
  PRODUCT_MY_REVIEWS_REQUEST,
  PRODUCT_MY_REVIEWS_SUCCESS,
  PRODUCT_MY_REVIEWS_FAIL
} from '../constants/productConstants'

// config
import { apiBaseUrl } from '../../config'

export const listProducts =
  (perPage, page, sort, term, filter = {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: PRODUCT_LIST_RESET
      })

      dispatch({
        type: PRODUCT_LIST_REQUEST
      })

      const { data } = await axios.get(
        `${apiBaseUrl}/api/products/${perPage}/${page}/${sort}/${term}`
      )

      dispatch({
        type: PRODUCT_LIST_SUCCESS,
        payload: {
          data: data.data,
          meta: data.meta,
          term: term.trim(),
          sort,
          filter
        }
      })
    } catch (error) {
      dispatch({
        type: PRODUCT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }

export const listProductDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCT_DETAILS_REQUEST
    })

    const {
      data: { data }
    } = await axios.get(`${apiBaseUrl}/api/products/${id}`)

    dispatch({
      type: PRODUCT_DETAILS_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}

export const removeProduct = (id) => async (dispatch, getState) => {
  // const toast = useToast()

  try {
    dispatch({
      type: PRODUCT_REMOVE_REQUEST
    })

    const {
      userLogin: { userInfo }
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.delete(
      `${apiBaseUrl}/api/products/${id}`,
      config
    )

    dispatch({
      type: PRODUCT_REMOVE_SUCCESS
    })

    // toast.show(data.message, { type: 'success' })
  } catch (error) {
    const errorMsg =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message

    dispatch({
      type: PRODUCT_REMOVE_FAIL,
      payload: errorMsg
    })

    // toast.error(errorMsg, { type: 'danger' })
  }
}

export const addProduct = (product) => async (dispatch, getState) => {
  // const toast = useToast()

  try {
    dispatch({
      type: PRODUCT_ADD_REQUEST
    })

    const {
      userLogin: { userInfo }
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.post(
      `${apiBaseUrl}/api/products/add`,
      product,
      config
    )

    dispatch({
      type: PRODUCT_ADD_SUCCESS,
      payload: data.data
    })

    // toast.show(data.message, { type: 'success' })
  } catch (error) {
    const errorMsg =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message

    dispatch({
      type: PRODUCT_ADD_FAIL,
      payload: errorMsg
    })

    // toast.error(errorMsg, { type: 'danger' })
  }
}

export const editProduct = (id) => async (dispatch, getState) => {
  // const toast = useToast()

  dispatch({ type: PRODUCT_EDIT_RESET })

  try {
    dispatch({
      type: PRODUCT_EDIT_REQUEST
    })

    const {
      userLogin: { userInfo }
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.get(`${apiBaseUrl}/api/products/${id}`, config)

    dispatch({
      type: PRODUCT_EDIT_SUCCESS,
      payload: data.data
    })

    // commented out anyway
    // toast.show(data.message, {type: 'success'})
  } catch (error) {
    const errorMsg =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message

    dispatch({
      type: PRODUCT_EDIT_FAIL,
      payload: errorMsg
    })

    // commented out anyway
    // toast.error(errorMsg, {type: 'danger'})
  }
}

export const updateProduct =
  (id, productFormData) => async (dispatch, getState) => {
    // const toast = useToast()

    try {
      dispatch({
        type: PRODUCT_UPDATE_RESET
      })

      dispatch({
        type: PRODUCT_UPDATE_REQUEST
      })

      const {
        userLogin: { userInfo }
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.token}`
        }
      }

      const { data } = await axios.put(
        `${apiBaseUrl}/api/products/${id}`,
        productFormData,
        config
      )

      dispatch({
        type: PRODUCT_UPDATE_SUCCESS,
        payload: data.data
      })

      // toast.show(data.message, { type: 'success' })
    } catch (error) {
      const errorMsg =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message

      dispatch({
        type: PRODUCT_UPDATE_FAIL,
        payload: errorMsg
      })

      // toast.error(errorMsg, { type: 'danger' })
    }
  }

export const createProductReview =
  (productId, review, toast) => async (dispatch, getState) => {
    // const toast = useToast()

    try {
      dispatch({
        type: PRODUCT_CREATE_REVIEW_RESET
      })

      dispatch({
        type: PRODUCT_CREATE_REVIEW_REQUEST
      })

      const {
        userLogin: { userInfo }
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`
        }
      }

      await axios.post(
        `${apiBaseUrl}/api/products/${productId}/reviews`,
        review,
        config
      )

      dispatch({
        type: PRODUCT_CREATE_REVIEW_SUCCESS
      })

      toast.show('Your review has been successfully added.', {
        type: 'success'
      })
    } catch (error) {
      const errorMsg =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message

      dispatch({
        type: PRODUCT_ADD_FAIL,
        payload: errorMsg
      })

      // toast.error(errorMsg, { type: 'danger' })
    }
  }

export const listFeaturedProducts = () => async (dispatch) => {
  try {
    dispatch({
      type: FEATURED_PRODUCT_LIST_RESET
    })

    dispatch({
      type: FEATURED_PRODUCT_LIST_REQUEST
    })

    const { data } = await axios.get(`${apiBaseUrl}/api/products/featured`)

    dispatch({
      type: FEATURED_PRODUCT_LIST_SUCCESS,
      payload: data.data
    })
  } catch (error) {
    dispatch({
      type: FEATURED_PRODUCT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}

export const clearListFeaturedProducts = () => async (dispatch) => {
  dispatch({
    type: FEATURED_PRODUCT_LIST_RESET
  })
}

export const updateFeaturedProducts =
  (products) => async (dispatch, getState) => {
    // const toast = useToast()

    try {
      dispatch({
        type: FEATURED_PRODUCT_UPDATE_RESET
      })

      dispatch({
        type: FEATURED_PRODUCT_UPDATE_REQUEST
      })

      const {
        userLogin: { userInfo }
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`
        }
      }

      await axios.post(
        `${apiBaseUrl}/api/products/featured`,
        { products },
        config
      )

      dispatch({
        type: FEATURED_PRODUCT_UPDATE_SUCCESS
      })

      // toast.show('The featured products have been successfully updated.', {
      //   type: 'success'
      // })
    } catch (error) {
      const errorMsg =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message

      dispatch({
        type: FEATURED_PRODUCT_LIST_FAIL,
        payload: errorMsg
      })

      // toast.error(errorMsg, { type: 'danger' })
    }
  }

export const likeProduct = (productId, toast) => async (dispatch, getState) => {
  // const toast = useToast()

  try {
    dispatch({
      type: PRODUCT_LIKE_RESET
    })

    dispatch({
      type: PRODUCT_LIKE_REQUEST
    })

    const {
      userLogin: { userInfo }
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.put(
      `${apiBaseUrl}/api/products/${productId}/like`,
      {},
      config
    )

    dispatch({
      type: PRODUCT_LIKE_SUCCESS,
      payload: data.data
    })

    // toast.show(data.message, { type: 'success' })
    toast.hideAll()
    toast.show(data.message, { type: 'success' })
  } catch (error) {
    const errorMsg =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message

    dispatch({
      type: PRODUCT_LIKE_FAIL,
      payload: errorMsg
    })

    // commented out anyway
    // toast.error(errorMsg, {type: 'danger'})
  }
}

export const wishlistProducts =
  (perPage, page, sort) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PRODUCT_WISHLIST_RESET
      })

      dispatch({
        type: PRODUCT_WISHLIST_REQUEST
      })

      const {
        userLogin: { userInfo }
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`
        }
      }

      const { data } = await axios.get(
        `${apiBaseUrl}/api/products/wishlist/${perPage}/${page}/${sort}`,
        config
      )

      dispatch({
        type: PRODUCT_WISHLIST_SUCCESS,
        payload: {
          data: data.data,
          meta: data.meta
        }
      })
    } catch (error) {
      const errorMsg =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message

      dispatch({
        type: PRODUCT_WISHLIST_FAIL,
        payload: errorMsg
      })
    }
  }

export const removeReview = (id, toast) => async (dispatch, getState) => {
  // const toast = useToast()

  try {
    dispatch({
      type: REVIEW_REMOVE_RESET
    })

    dispatch({
      type: REVIEW_REMOVE_REQUEST
    })

    const {
      userLogin: { userInfo }
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.delete(
      `${apiBaseUrl}/api/products/${id}/reviews`,
      config
    )

    dispatch({
      type: REVIEW_REMOVE_SUCCESS
    })

    toast.show(data.message, { type: 'success' })
  } catch (error) {
    const errorMsg =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message

    dispatch({
      type: REVIEW_REMOVE_FAIL,
      payload: errorMsg
    })

    // toast.error(errorMsg, { type: 'danger' })
  }
}

export const editReview = (id, toast) => async (dispatch, getState) => {
  // const toast = useToast()

  dispatch({ type: REVIEW_EDIT_RESET })

  try {
    dispatch({
      type: REVIEW_EDIT_REQUEST
    })

    const {
      userLogin: { userInfo }
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.get(
      `${apiBaseUrl}/api/products/${id}/reviews`,
      config
    )

    dispatch({
      type: REVIEW_EDIT_SUCCESS,
      payload: data.data
    })

    // commented out anyway
    // toast.show(data.message, {type: 'success'})
  } catch (error) {
    const errorMsg =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message

    dispatch({
      type: REVIEW_EDIT_FAIL,
      payload: errorMsg
    })

    // commented out anyway
    // toast.error(errorMsg, {type: 'danger'})
  }
}

export const updateReview =
  (id, reviewFormData, toast) => async (dispatch, getState) => {
    // const toast = useToast()

    try {
      dispatch({
        type: REVIEW_UPDATE_RESET
      })

      dispatch({
        type: REVIEW_UPDATE_REQUEST
      })

      const {
        userLogin: { userInfo }
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`
        }
      }

      const { data } = await axios.put(
        `${apiBaseUrl}/api/products/${id}/reviews`,
        reviewFormData,
        config
      )

      dispatch({
        type: REVIEW_UPDATE_SUCCESS,
        payload: data.data
      })

      toast.show(data.message, { type: 'success' })
    } catch (error) {
      const errorMsg =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message

      dispatch({
        type: REVIEW_UPDATE_FAIL,
        payload: errorMsg
      })

      // toast.error(errorMsg, { type: 'danger' })
    }
  }

export const myReviewedProducts =
  (perPage, page) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PRODUCT_MY_REVIEWS_REQUEST
      })

      const {
        userLogin: { userInfo }
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`
        }
      }

      const { data } = await axios.get(
        `${apiBaseUrl}/api/products/reviewed/${perPage}/${page}`,
        config
      )

      dispatch({
        type: PRODUCT_MY_REVIEWS_SUCCESS,
        payload: data
      })
    } catch (error) {
      const errorMsg =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message

      dispatch({
        type: PRODUCT_MY_REVIEWS_FAIL,
        payload: errorMsg
      })
    }
  }
