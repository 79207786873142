import { StyleSheet, Text, View, ScrollView } from 'react-native'
import React from 'react'

// styles
import globalStyles from '../styles/global'

// components
import OrderDetails from '../components/checkout/OrderDetails'

const OrderDetailsScreen = ({ route }) => {
  const { orderId } = route.params

  return (
    <ScrollView style={styles.container}>
      <View style={[globalStyles.secondaryBgColor, globalStyles.containerBand]}>
        {true && (
          <View style={[globalStyles.textOverlap]}>
            <View>
              <Text
                style={[
                  globalStyles.text,
                  globalStyles.fwMedium,
                  globalStyles.fs20,
                  globalStyles.blackTextColor,
                  globalStyles.mb1
                ]}
              >
                Order #{orderId.substring(0, 9).toUpperCase()}
              </Text>
            </View>
          </View>
        )}
      </View>
      <View style={[globalStyles.containerOverlap]}>
        <OrderDetails orderId={orderId} />
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  }
})

export default OrderDetailsScreen
