import { StyleSheet, Text, View } from 'react-native'
import React, { useState } from 'react'

// styles
import globalStyles from '../styles/global'

// config
import { shownReviews } from '../config'

// components
import Review from './Review'

const ReviewList = ({
  productId,
  reviews,
  isShown,
  editReviewModalOpen,
  setEditReviewModalOpen,
  closeModal,
  setCloseModal
}) => {
  if (reviews.length === 0)
    return (
      <>
        <View
          style={[
            globalStyles.hAlignItems,
            globalStyles.hCenterItems,
            globalStyles.mt3,
            globalStyles.mb5
          ]}
        >
          <Text
            style={[globalStyles.text, globalStyles.fwLight, globalStyles.fs18]}
          >
            No reviews
          </Text>
        </View>
        {/* <View style={globalStyles.separator}></View> */}
      </>
    )
  return (
    <>
      <View>
        {reviews.length !== 0 &&
          reviews
            .slice(0)
            .reverse()
            .map((review, index) => (
              <Review
                key={review._id}
                productId={productId}
                review={review}
                separator={reviews.length === index + 1 ? true : true}
                shown={shownReviews > index ? true : isShown}
                editReviewModalOpen={editReviewModalOpen}
                setEditReviewModalOpen={setEditReviewModalOpen}
                closeModal={closeModal}
                setCloseModal={setCloseModal}
              />
            ))}
      </View>
    </>
  )
}

const styles = StyleSheet.create({})

export default ReviewList
