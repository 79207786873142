import { StyleSheet, Text, View, ScrollView, Image } from 'react-native'
import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { AntDesign } from '@expo/vector-icons'

// components
import FlatButton from '../components/Button'

// styles
import globalStyles from '../styles/global'

const OrderCompleteScreen = () => {
  const navigation = useNavigation()

  return (
    <ScrollView style={styles.container}>
      <View
        style={[globalStyles.secondaryBgColor, globalStyles.containerBand]}
      ></View>
      <View style={[globalStyles.containerOverlap]}>
        <View style={[globalStyles.containerOverlapInner]}>
          <View style={styles.innerContainer} behavior="padding">
            <View style={{ marginVertical: 10 }}>
              <AntDesign name="checkcircle" size={44} color="#df143c" />
            </View>
            <View style={{ marginVertical: 20 }}>
              <Text
                style={[
                  globalStyles.text,
                  globalStyles.fwBold,
                  globalStyles.fs20,
                  globalStyles.textCenter,
                  globalStyles.mb3
                ]}
              >
                Your order is completed!
              </Text>
              <Text
                style={[
                  globalStyles.text,
                  globalStyles.fwLight,
                  globalStyles.fs14,
                  globalStyles.textCenter,
                  globalStyles.mb1
                ]}
              >
                Thank you for your order! Your order is being processed and will
                be completed within 3-6 hours. You will receive an email
                confirmation when your order is completed.
              </Text>
            </View>
            <FlatButton
              text="Continue Shopping"
              transform="uppercase"
              type="primary"
              onPress={() => {
                navigation.navigate('Home', {})
              }}
            />
          </View>
        </View>
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  innerContainer: {
    marginVertical: 0,
    padding: 16,
    alignItems: 'center'
  }
})

export default OrderCompleteScreen
