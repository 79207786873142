import { combineReducers } from 'redux'

import {
  productListReducer,
  productDetailsReducer,
  productRemoveReducer,
  productAddReducer,
  productEditReducer,
  productUpdateReducer,
  productCreateReviewReducer,
  featuredProductListReducer,
  productLikeReducer,
  productWishlistReducer,
  reviewRemoveReducer,
  reviewEditReducer,
  reviewUpdateReducer,
  productMyReviewsReducer
} from './reducers/productReducers'
import { cartReducer } from './reducers/cartReducers'
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userUpdatePasswordReducer,
  userForgotPasswordReducer,
  userResetPasswordReducer,
  userConfirmRegistrationReducer,
  userResetPasswordValidReducer,
  userConfirmRegistrationValidReducer,
  userListReducer,
  userRemoveReducer,
  userAddReducer,
  userEditReducer,
  userUpdateReducer
} from './reducers/userReducers'
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  orderDeliverReducer,
  orderListMyOrdersReducer,
  orderListReducer
} from './reducers/orderReducers'

const reducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetailsReducer,
  productRemove: productRemoveReducer,
  productAdd: productAddReducer,
  productEdit: productEditReducer,
  productUpdate: productUpdateReducer,
  productCreateReview: productCreateReviewReducer,
  featuredProductList: featuredProductListReducer,
  productLike: productLikeReducer,
  productWishlist: productWishlistReducer,
  productMyReviews: productMyReviewsReducer,
  reviewRemove: reviewRemoveReducer,
  reviewEdit: reviewEditReducer,
  reviewUpdate: reviewUpdateReducer,
  cart: cartReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userUpdatePassword: userUpdatePasswordReducer,
  userForgotPassword: userForgotPasswordReducer,
  userResetPassword: userResetPasswordReducer,
  userConfirmRegistration: userConfirmRegistrationReducer,
  userResetPasswordValid: userResetPasswordValidReducer,
  userConfirmRegistrationValid: userConfirmRegistrationValidReducer,
  userList: userListReducer,
  userRemove: userRemoveReducer,
  userAdd: userAddReducer,
  userEdit: userEditReducer,
  userUpdate: userUpdateReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderDeliver: orderDeliverReducer,
  orderListMyOrders: orderListMyOrdersReducer,
  orderList: orderListReducer
})

export default reducer
