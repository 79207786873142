import { StyleSheet, Text, View, TouchableOpacity, Image } from 'react-native'
import React, { useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import { Ionicons } from '@expo/vector-icons'

// components
import FlatButton from '../Button'

// styles
import globalStyles from '../../styles/global'

// utils
import { currencyFormatter } from '../../utils/index'

// data
import { cartItemsFeed } from '../../data'

const CartOrderSummary = ({
  itemSubtotal,
  itemTotalPrice,
  deliveryFee,
  taxLabel,
  tax,
  orderTotalLabel,
  orderTotalPrice,
  cartItems,
  actionLabel,
  action,
  isDisabled = false,
  isItemsShown = true,
  isAction = true,
  isDeliveryNote = true
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true)

  const navigation = useNavigation()

  const toggleItemsDrawerHandler = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  const handleOpenSingleProduct = (productId) => {
    navigation.navigate('ProductSingle', { productId })
  }

  return (
    <View style={[styles.card]}>
      <View
        style={[
          globalStyles.hAlignItems,
          globalStyles.hBetweenItems,
          globalStyles.vEndItems
        ]}
      >
        <Text
          style={[
            globalStyles.text,
            globalStyles.fwBold,
            globalStyles.fs18,
            globalStyles.mt1
          ]}
        >
          Order summary
        </Text>
        {isItemsShown && (
          <TouchableOpacity onPress={toggleItemsDrawerHandler}>
            <Ionicons
              name={`md-chevron-${isDrawerOpen ? 'up' : 'down'}-sharp`}
              size={22}
              color="#333"
            />
          </TouchableOpacity>
        )}
      </View>
      <View style={globalStyles.separator}></View>
      {isItemsShown && (
        <View>
          {isDrawerOpen ? (
            <View style={[styles.orderedItems]}>
              {cartItems.map((item) => (
                <View key={item.product}>
                  <View
                    style={[
                      globalStyles.hAlignItems,
                      globalStyles.vCenterItems
                    ]}
                  >
                    <TouchableOpacity
                      style={[globalStyles.mr3, globalStyles.relativePosition]}
                      onPress={() => handleOpenSingleProduct(item.product)}
                    >
                      <Image
                        source={{ uri: item.image }}
                        style={[styles.itemImage]}
                      />
                      <View style={[styles.badge]}>
                        <Text
                          style={[
                            styles.badgeText,
                            globalStyles.text,
                            globalStyles.fwRegular,
                            globalStyles.fs10
                          ]}
                        >
                          {item.quantity}
                        </Text>
                      </View>
                    </TouchableOpacity>
                    <View style={[globalStyles.flexOne]}>
                      <TouchableOpacity
                        onPress={() => handleOpenSingleProduct(item.product)}
                      >
                        <Text
                          style={[
                            globalStyles.text,
                            globalStyles.fwBold,
                            globalStyles.fs16,
                            globalStyles.mb1
                          ]}
                        >
                          {item.name}
                        </Text>
                      </TouchableOpacity>
                      <Text style={[globalStyles.text, globalStyles.fs14]}>
                        {item.quantity} x {currencyFormatter(item.price)} ={' '}
                        {currencyFormatter(item.price * item.quantity)}
                      </Text>
                    </View>
                  </View>
                  <View style={globalStyles.separator}></View>
                </View>
              ))}
            </View>
          ) : (
            <View>
              <View
                style={[globalStyles.hAlignItems, globalStyles.vCenterItems]}
              >
                {cartItems.map((item, index) => {
                  return (
                    index < 3 && (
                      <View key={item.product}>
                        <TouchableOpacity
                          style={[
                            globalStyles.mr2,
                            globalStyles.relativePosition
                          ]}
                          onPress={() => handleOpenSingleProduct(item.product)}
                        >
                          <Image
                            source={{ uri: item.image }}
                            style={[styles.itemImage]}
                          />
                          <View style={[styles.badge]}>
                            <Text
                              style={[
                                styles.badgeText,
                                globalStyles.text,
                                globalStyles.fwRegular,
                                globalStyles.fs10
                              ]}
                            >
                              {item.quantity}
                            </Text>
                          </View>
                        </TouchableOpacity>
                      </View>
                    )
                  )
                })}
                {cartItems.length > 3 && (
                  <View>
                    <TouchableOpacity
                      style={[globalStyles.ml3]}
                      onPress={toggleItemsDrawerHandler}
                    >
                      <Text
                        style={[
                          globalStyles.text,
                          globalStyles.fwBold,
                          globalStyles.fs17,
                          globalStyles.primaryTextColor
                        ]}
                      >
                        +{cartItems.length - 3}
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}
              </View>
              <View style={globalStyles.separator}></View>
            </View>
          )}
        </View>
      )}
      <View
        style={[
          globalStyles.hAlignItems,
          globalStyles.hBetweenItems,
          globalStyles.mb2
        ]}
      >
        <Text
          style={[
            globalStyles.text,
            globalStyles.fwMedium,
            globalStyles.blackTextColor
          ]}
        >
          Item subtotal ({itemSubtotal})
        </Text>
        <Text
          style={[
            globalStyles.text,
            globalStyles.fwBold,
            globalStyles.blackTextColor
          ]}
        >
          {itemTotalPrice}
        </Text>
      </View>
      <View style={[globalStyles.hAlignItems, globalStyles.hBetweenItems]}>
        <Text
          style={[
            globalStyles.text,
            globalStyles.fwMedium,
            globalStyles.blackTextColor
          ]}
        >
          Delivery{isDeliveryNote && ' *'}
        </Text>
        <Text
          style={[
            globalStyles.text,
            globalStyles.fwBold,
            globalStyles.blackTextColor
          ]}
        >
          {deliveryFee}
        </Text>
      </View>
      <View style={globalStyles.separator}></View>
      <View
        style={[
          globalStyles.hAlignItems,
          globalStyles.hBetweenItems,
          globalStyles.mb2
        ]}
      >
        <Text
          style={[
            globalStyles.text,
            globalStyles.fwMedium,
            globalStyles.blackTextColor
          ]}
        >
          {taxLabel}
        </Text>
        <Text
          style={[
            globalStyles.text,
            globalStyles.fwBold,
            globalStyles.blackTextColor
          ]}
        >
          {tax}
        </Text>
      </View>
      <View
        style={[
          globalStyles.hAlignItems,
          globalStyles.hBetweenItems,
          globalStyles.mb2
        ]}
      >
        <Text
          style={[
            globalStyles.text,
            globalStyles.fwMedium,
            globalStyles.fs18,
            globalStyles.primaryTextColor
          ]}
        >
          {orderTotalLabel}
        </Text>
        <Text
          style={[
            globalStyles.text,
            globalStyles.fwBold,
            globalStyles.fs18,
            globalStyles.primaryTextColor
          ]}
        >
          {orderTotalPrice}
        </Text>
      </View>
      {isAction && (
        <View style={[globalStyles.mt3, globalStyles.mb2]}>
          <FlatButton
            text={actionLabel}
            transform="uppercase"
            type="primary"
            onPress={action}
            isDisabled={isDisabled}
          />
        </View>
      )}
      {isDeliveryNote && (
        <View>
          <View
            style={[globalStyles.separator, globalStyles.separatorBottomNarrow]}
          ></View>
          <Text
            style={[
              globalStyles.text,
              globalStyles.fwLight,
              globalStyles.fs12,
              globalStyles.mb1
            ]}
          >
            * Free delivery when you spend over $100
          </Text>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  card: {
    position: 'relative',
    justifyContent: 'flex-end',
    borderRadius: 10,
    backgroundColor: '#fff',
    borderColor: '#e9ecef',
    borderWidth: 1,
    padding: 16
  },
  orderedItems: {},
  itemImage: {
    width: 70,
    height: 70
  },
  badge: {
    position: 'absolute',
    right: -2,
    top: 0,
    backgroundColor: '#df143c',
    width: 16,
    height: 16,
    borderRadius: 50
  },
  badgeText: {
    textAlign: 'center',
    color: '#fff'
  }
})

export default CartOrderSummary
