import {
  StyleSheet,
  Text,
  View,
  TextInput,
  TouchableOpacity,
  Dimensions,
  Pressable,
  Platform
} from 'react-native'
import React, { useState, useEffect, useRef } from 'react'
import { AntDesign } from '@expo/vector-icons'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { useNavigation } from '@react-navigation/native'

// styles
import globalStyles from '../styles/global'

// navigations
import DrawerNavigator from './DrawerNavigator'

// components
import CartScreen from '../screens/CartScreen'
import SearchScreen from '../screens/SearchScreen'
import LoginScreen from '../screens/LoginScreen'
import RegisterScreen from '../screens/RegisterScreen'
import ForgotPasswordScreen from '../screens/ForgotPasswordScreen'
import SignInScreen from '../screens/SignInScreen'
import ShippingScreen from '../screens/ShippingScreen'
import PaymentScreen from '../screens/PaymentScreen'
import OrderScreen from '../screens/OrderScreen'
import OrderDetailsScreen from '../screens/OrderDetailsScreen'
import OrderCompleteScreen from '../screens/OrderCompleteScreen'
import ChangePasswordScreen from '../screens/ChangePasswordScreen'
import ProductSingleScreen from '../screens/ProductSingleScreen'

// constants
const DEVICE_WIDTH = Dimensions.get('window').width
const MARGIN_LEFT_RIGHT = Platform.OS === 'web' ? 16 : 0

// stack
const Stack = createNativeStackNavigator()

const StackNavigator = () => {
  const [term, setTerm] = useState('')

  const navigation = useNavigation()
  const searchInput = useRef()

  const searchHandler = (text) => {
    setTerm(text)
  }

  const clearHandler = () => {
    setTerm('')
    searchInput.current.focus()
  }

  const openCartHandler = () => {
    navigation.navigate('Cart', {})
  }

  useEffect(() => {
    // searchInput.current.focus()
    // console.log(startSearch)
  }, [])

  return (
    <Stack.Navigator>
      <Stack.Screen
        options={{
          headerShown: false
        }}
        name="HomeScreen"
        component={DrawerNavigator}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: 'Cart',
          headerStyle: {
            backgroundColor: '#df143c',
            borderBottomWidth: 0,
            borderBottomColor: '#df143c',
            elevation: 0,
            shadowOpacity: 0
            // height: 90
          },
          headerShadowVisible: false,
          headerTitleStyle: {
            fontFamily: 'notosanstc-medium',
            color: '#fff'
          },
          headerTintColor: '#fff',
          headerLeft: () => (
            <View style={[{ marginLeft: MARGIN_LEFT_RIGHT }]}>
              <Pressable
                style={{ marginRight: 12 }}
                onPress={() => {
                  navigation.goBack()
                }}
              >
                <AntDesign name="arrowleft" size={24} color="white" />
              </Pressable>
            </View>
          )
          /*headerRight: () => (
            <TouchableOpacity
              style={[globalStyles.hAlignItems, globalStyles.vCenterItems]}
              onPress={openCartHandler}
            >
              <AntDesign name="shoppingcart" size={24} color="white" />
              <Text
                style={[
                  globalStyles.text,
                  globalStyles.whiteTextColor,
                  globalStyles.ml1
                ]}
              >
                3
              </Text>
            </TouchableOpacity>
          )*/
        }}
        name="Cart"
        component={CartScreen}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: '',
          headerStyle: {
            backgroundColor: '#df143c',
            borderBottomWidth: 0,
            borderBottomColor: '#df143c',
            elevation: 0,
            shadowOpacity: 0
            // height: 90
          },
          headerShadowVisible: false,
          headerTitleStyle: {
            fontFamily: 'notosanstc-medium',
            color: '#fff'
          },
          headerTintColor: '#fff',
          headerRight: () => (
            <View
              style={[styles.searchBar, { marginRight: MARGIN_LEFT_RIGHT }]}
            >
              <TextInput
                placeholder="Search Lynashop"
                value={term}
                onChangeText={(text) => searchHandler(text)}
                style={[styles.input, { paddingRight: 40 }]}
                onSubmitEditing={() => {
                  if (term !== '') navigation.setParams({ keyword: term })
                }}
                ref={searchInput}
              />
              <View style={styles.inputIcon}>
                <AntDesign
                  name={'search1'}
                  size={22}
                  color="rgba(0, 0, 0, .25)"
                />
              </View>
              {term.length !== 0 && (
                <TouchableOpacity
                  style={styles.inputClear}
                  onPress={clearHandler}
                >
                  <AntDesign
                    name={'close'}
                    size={24}
                    color="rgba(0, 0, 0, .25)"
                  />
                </TouchableOpacity>
              )}
            </View>
          ),
          headerLeft: () => (
            <View style={[{ marginLeft: MARGIN_LEFT_RIGHT }]}>
              <Pressable
                style={{ marginRight: 12 }}
                onPress={() => {
                  navigation.goBack()
                }}
              >
                <AntDesign name="arrowleft" size={24} color="white" />
              </Pressable>
            </View>
          )
        }}
        name="Search"
        component={SearchScreen}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: 'Login',
          headerStyle: {
            backgroundColor: '#df143c',
            borderBottomWidth: 0,
            borderBottomColor: '#df143c',
            elevation: 0,
            shadowOpacity: 0
            // height: 90
          },
          headerShadowVisible: false,
          headerTitleStyle: {
            fontFamily: 'notosanstc-medium',
            color: '#fff'
          },
          headerTintColor: '#fff',
          headerLeft: () => (
            <View style={[{ marginLeft: MARGIN_LEFT_RIGHT }]}>
              <Pressable
                style={{ marginRight: 12 }}
                onPress={() => {
                  navigation.navigate('Home', {})
                }}
              >
                <AntDesign name="arrowleft" size={24} color="white" />
              </Pressable>
            </View>
          )
        }}
        name="Login"
        component={LoginScreen}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: 'Register',
          headerStyle: {
            backgroundColor: '#df143c',
            borderBottomWidth: 0,
            borderBottomColor: '#df143c',
            elevation: 0,
            shadowOpacity: 0
            // height: 90
          },
          headerShadowVisible: false,
          headerTitleStyle: {
            fontFamily: 'notosanstc-medium',
            color: '#fff'
          },
          headerTintColor: '#fff',
          headerLeft: () => (
            <View style={[{ marginLeft: MARGIN_LEFT_RIGHT }]}>
              <Pressable
                style={{ marginRight: 12 }}
                onPress={() => {
                  navigation.goBack()
                }}
              >
                <AntDesign name="arrowleft" size={24} color="white" />
              </Pressable>
            </View>
          )
        }}
        name="Register"
        component={RegisterScreen}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: 'Forgot Password',
          headerStyle: {
            backgroundColor: '#df143c',
            borderBottomWidth: 0,
            borderBottomColor: '#df143c',
            elevation: 0,
            shadowOpacity: 0
            // height: 90
          },
          headerShadowVisible: false,
          headerTitleStyle: {
            fontFamily: 'notosanstc-medium',
            color: '#fff'
          },
          headerTintColor: '#fff',
          headerLeft: () => (
            <View style={[{ marginLeft: MARGIN_LEFT_RIGHT }]}>
              <Pressable
                style={{ marginRight: 12 }}
                onPress={() => {
                  navigation.goBack()
                }}
              >
                <AntDesign name="arrowleft" size={24} color="white" />
              </Pressable>
            </View>
          )
        }}
        name="ForgotPassword"
        component={ForgotPasswordScreen}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: 'Sign In',
          headerStyle: {
            backgroundColor: '#df143c',
            borderBottomWidth: 0,
            borderBottomColor: '#df143c',
            elevation: 0,
            shadowOpacity: 0
            // height: 90
          },
          headerShadowVisible: false,
          headerTitleStyle: {
            fontFamily: 'notosanstc-medium',
            color: '#fff'
          },
          headerTintColor: '#fff',
          headerLeft: () => (
            <View style={[{ marginLeft: MARGIN_LEFT_RIGHT }]}>
              <Pressable
                style={{ marginRight: 12 }}
                onPress={() => {
                  navigation.goBack()
                }}
              >
                <AntDesign name="arrowleft" size={24} color="white" />
              </Pressable>
            </View>
          )
        }}
        name="SignIn"
        component={SignInScreen}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: 'Shipping',
          headerStyle: {
            backgroundColor: '#df143c',
            borderBottomWidth: 0,
            borderBottomColor: '#df143c',
            elevation: 0,
            shadowOpacity: 0
            // height: 90
          },
          headerShadowVisible: false,
          headerTitleStyle: {
            fontFamily: 'notosanstc-medium',
            color: '#fff'
          },
          headerTintColor: '#fff',
          headerLeft: () => (
            <View style={[{ marginLeft: MARGIN_LEFT_RIGHT }]}>
              <Pressable
                style={{ marginRight: 12 }}
                onPress={() => {
                  navigation.goBack()
                }}
              >
                <AntDesign name="arrowleft" size={24} color="white" />
              </Pressable>
            </View>
          )
        }}
        name="Shipping"
        component={ShippingScreen}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: 'Payment',
          headerStyle: {
            backgroundColor: '#df143c',
            borderBottomWidth: 0,
            borderBottomColor: '#df143c',
            elevation: 0,
            shadowOpacity: 0
            // height: 90
          },
          headerShadowVisible: false,
          headerTitleStyle: {
            fontFamily: 'notosanstc-medium',
            color: '#fff'
          },
          headerTintColor: '#fff',
          headerLeft: () => (
            <View style={[{ marginLeft: MARGIN_LEFT_RIGHT }]}>
              <Pressable
                style={{ marginRight: 12 }}
                onPress={() => {
                  navigation.goBack()
                }}
              >
                <AntDesign name="arrowleft" size={24} color="white" />
              </Pressable>
            </View>
          )
        }}
        name="Payment"
        component={PaymentScreen}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: 'Order',
          headerStyle: {
            backgroundColor: '#df143c',
            borderBottomWidth: 0,
            borderBottomColor: '#df143c',
            elevation: 0,
            shadowOpacity: 0
            // height: 90
          },
          headerShadowVisible: false,
          headerTitleStyle: {
            fontFamily: 'notosanstc-medium',
            color: '#fff'
          },
          headerTintColor: '#fff',
          headerLeft: () => (
            <View style={[{ marginLeft: MARGIN_LEFT_RIGHT }]}>
              <Pressable
                style={{ marginRight: 12 }}
                onPress={() => {
                  navigation.goBack()
                }}
              >
                <AntDesign name="arrowleft" size={24} color="white" />
              </Pressable>
            </View>
          )
        }}
        name="Order"
        component={OrderScreen}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: 'My Order',
          headerStyle: {
            backgroundColor: '#df143c',
            borderBottomWidth: 0,
            borderBottomColor: '#df143c',
            elevation: 0,
            shadowOpacity: 0
            // height: 90
          },
          headerShadowVisible: false,
          headerTitleStyle: {
            fontFamily: 'notosanstc-medium',
            color: '#fff'
          },
          headerTintColor: '#fff',
          headerLeft: () => (
            <View style={[{ marginLeft: MARGIN_LEFT_RIGHT }]}>
              <Pressable
                style={{ marginRight: 12 }}
                onPress={() => {
                  navigation.goBack()
                }}
              >
                <AntDesign name="arrowleft" size={24} color="white" />
              </Pressable>
            </View>
          )
        }}
        name="OrderDetails"
        component={OrderDetailsScreen}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: 'Thank You',
          headerStyle: {
            backgroundColor: '#df143c',
            borderBottomWidth: 0,
            borderBottomColor: '#df143c',
            elevation: 0,
            shadowOpacity: 0
            // height: 90
          },
          headerShadowVisible: false,
          headerTitleStyle: {
            fontFamily: 'notosanstc-medium',
            color: '#fff'
          },
          headerTintColor: '#fff',
          headerLeft: () => (
            <View style={[{ marginLeft: MARGIN_LEFT_RIGHT }]}>
              <Pressable
                style={{ marginRight: 12 }}
                onPress={() => {
                  navigation.navigate('Home', {})
                }}
              >
                <AntDesign name="arrowleft" size={24} color="white" />
              </Pressable>
            </View>
          )
        }}
        name="OrderComplete"
        component={OrderCompleteScreen}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: 'Change Password',
          headerStyle: {
            backgroundColor: '#df143c',
            borderBottomWidth: 0,
            borderBottomColor: '#df143c',
            elevation: 0,
            shadowOpacity: 0
            // height: 90
          },
          headerShadowVisible: false,
          headerTitleStyle: {
            fontFamily: 'notosanstc-medium',
            color: '#fff'
          },
          headerTintColor: '#fff',
          headerLeft: () => (
            <View style={[{ marginLeft: MARGIN_LEFT_RIGHT }]}>
              <Pressable
                style={{ marginRight: 12 }}
                onPress={() => {
                  navigation.goBack()
                }}
              >
                <AntDesign name="arrowleft" size={24} color="white" />
              </Pressable>
            </View>
          )
        }}
        name="ChangePassword"
        component={ChangePasswordScreen}
      />
      <Stack.Screen
        options={{
          headerShown: true,
          title: 'Product',
          headerStyle: {
            backgroundColor: '#df143c',
            borderBottomWidth: 0,
            borderBottomColor: '#df143c',
            elevation: 0,
            shadowOpacity: 0
            // height: 90
          },
          headerShadowVisible: false,
          headerTitleStyle: {
            fontFamily: 'notosanstc-medium',
            color: '#fff'
          },
          headerTintColor: '#fff',
          headerLeft: () => (
            <View style={[{ marginLeft: MARGIN_LEFT_RIGHT }]}>
              <Pressable
                style={{ marginRight: 12 }}
                onPress={() => {
                  navigation.goBack()
                }}
              >
                <AntDesign name="arrowleft" size={24} color="white" />
              </Pressable>
            </View>
          )
          // headerLeft: () => <></>,
          // headerRight: () => (
          //   <View>
          //     <Pressable
          //       onPress={() => {
          //         navigation.goBack()
          //       }}
          //     >
          //       <AntDesign name="close" size={28} color="white" />
          //     </Pressable>
          //   </View>
          // )
        }}
        name="ProductSingle"
        component={ProductSingleScreen}
      />
    </Stack.Navigator>
  )
}

const styles = StyleSheet.create({
  input: {
    borderWidth: 1,
    backgroundColor: '#fff',
    borderColor: 'transparent',
    color: 'rgba(15, 23, 42, 1)',
    paddingLeft: 45,
    paddingRight: 16,
    height: 45,
    fontFamily: 'notosanstc-regular',
    fontSize: 16,
    lineHeight: 21,
    borderRadius: 5,
    width: DEVICE_WIDTH - 70
  },
  inputIcon: {
    position: 'absolute',
    left: 12,
    top: 11
  },
  inputClear: {
    position: 'absolute',
    right: 12,
    top: 10
  },
  searchBar: {
    position: 'relative',
    marginHorizontal: 0
  },
  searchResult: {
    flex: 1,
    marginVertical: 26
  }
})

export default StackNavigator
