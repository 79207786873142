import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

// styles
import globalStyles from '../../styles/global'

const ProductSingleSkeleton = () => {
  return (
    <View style={[styles.container]}>
      <View style={[styles.productCategory]} />
      <View style={[styles.productTitle]} />
      <View style={[styles.productRating]} />
      <View style={[globalStyles.vCenterItems]}>
        <View style={[styles.productImg]} />
      </View>
      <View style={[globalStyles.mb2]}>
        <View style={[styles.productText]} />
        <View style={[styles.productText]} />
        <View style={[styles.productText]} />
        <View style={[styles.productText]} />
      </View>
      <View style={[globalStyles.separator]} />
      <View style={[globalStyles.mt2, globalStyles.mb3]}>
        <View style={[globalStyles.hAlignItems, globalStyles.mb4]}>
          <View style={[styles.productDescTitle]} />
          <View style={[styles.productDescDetails]} />
        </View>
        <View style={[globalStyles.hAlignItems, globalStyles.mb2]}>
          <View style={[styles.productDescTitle]} />
          <View style={[styles.productDescDetails]} />
        </View>
        <View style={[globalStyles.hAlignItems, globalStyles.vCenterItems]}>
          <View style={[styles.productDescTitle]} />
          <View style={[styles.productDescCircle, globalStyles.mr3]} />
          <View style={[styles.productDescCircle]} />
        </View>
      </View>
      <View style={[styles.productAction]} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: '#fff'
  },
  productCategory: {
    backgroundColor: 'rgba(15, 23, 42, .07)',
    width: '40%',
    padding: 6,
    borderRadius: 10,
    marginBottom: 12
  },
  productTitle: {
    backgroundColor: 'rgba(15, 23, 42, .07)',
    width: '80%',
    padding: 9,
    borderRadius: 10,
    marginBottom: 12
  },
  productRating: {
    backgroundColor: 'rgba(15, 23, 42, .07)',
    width: '30%',
    padding: 6,
    borderRadius: 10,
    marginBottom: 12
  },
  productText: {
    backgroundColor: 'rgba(15, 23, 42, .05)',
    width: '100%',
    padding: 6,
    borderRadius: 10,
    marginVertical: 6
  },
  productDescTitle: {
    backgroundColor: 'rgba(15, 23, 42, .07)',
    width: 80,
    padding: 6,
    borderRadius: 10,
    marginRight: 12
  },
  productDescDetails: {
    backgroundColor: 'rgba(15, 23, 42, .07)',
    width: 140,
    padding: 6,
    borderRadius: 10
  },
  productDescCircle: {
    width: 30,
    height: 30,
    borderRadius: 30,
    backgroundColor: 'rgba(15, 23, 42, .07)'
  },
  productImg: {
    width: 200,
    height: 200,
    borderRadius: 200,
    backgroundColor: 'rgba(15, 23, 42, .1)',
    marginVertical: 20
  },
  productAction: {
    backgroundColor: 'rgba(15, 23, 42, .07)',
    width: '100%',
    height: 46,
    borderRadius: 5,
    marginTop: 20
  }
})

export default ProductSingleSkeleton
