import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

const ProductFilterSkeleton = () => {
  return (
    <View style={[styles.filterPlaceholder]}>
      <View style={[styles.filterPlaceholderText]}></View>
    </View>
  )
}

const styles = StyleSheet.create({
  filterPlaceholder: {
    paddingHorizontal: 16,
    paddingVertical: 11
  },
  filterPlaceholderText: {
    backgroundColor: 'rgba(15, 23, 42, .1)',
    width: '25%',
    padding: 6,
    borderRadius: 10
  }
})

export default ProductFilterSkeleton
