import { StyleSheet, Text, View, TouchableOpacity } from 'react-native'
import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { useToast } from 'react-native-toast-notifications'

// styles
import globalStyles from '../../styles/global'

// components
import FlatButton from '../Button'
import Steps from './Steps'

const Order = ({ action }) => {
  const navigation = useNavigation()

  const cart = useSelector((state) => state.cart)
  const { shippingAddress } = cart

  const editShippinAddressHandler = () => {
    navigation.navigate('Shipping', {})
  }

  const editPaymentMethodHandler = () => {
    navigation.navigate('Payment', {})
  }

  return (
    <View style={[globalStyles.card]}>
      <Steps step={4} />
      <View>
        {shippingAddress && (
          <>
            <View
              style={[
                globalStyles.hAlignItems,
                globalStyles.hBetweenItems,
                globalStyles.mb3
              ]}
            >
              <Text
                style={[
                  globalStyles.text,
                  globalStyles.fwBold,
                  globalStyles.fs16
                ]}
              >
                Shipping Address
              </Text>
              <TouchableOpacity onPress={editShippinAddressHandler}>
                <Text
                  style={[
                    globalStyles.text,
                    globalStyles.fwBold,
                    globalStyles.primaryTextColor
                  ]}
                >
                  Edit
                </Text>
              </TouchableOpacity>
            </View>
            <Text style={[globalStyles.text, globalStyles.fwLight]}>
              {shippingAddress.address}
              {shippingAddress.apt && `, #${shippingAddress.apt}`}
            </Text>
            <Text style={[globalStyles.text, globalStyles.fwLight]}>
              {shippingAddress.city}, {shippingAddress.state}{' '}
              {shippingAddress.zipCode}
            </Text>
            <Text style={[globalStyles.text, globalStyles.fwLight]}>
              {shippingAddress.country}
            </Text>
            <View style={[globalStyles.separator]} />
          </>
        )}
        <>
          <View
            style={[
              globalStyles.hAlignItems,
              globalStyles.hBetweenItems,
              globalStyles.mb3
            ]}
          >
            <Text
              style={[
                globalStyles.text,
                globalStyles.fwBold,
                globalStyles.fs16
              ]}
            >
              Payment Method
            </Text>
            <TouchableOpacity onPress={editPaymentMethodHandler}>
              <Text
                style={[
                  globalStyles.text,
                  globalStyles.fwBold,
                  globalStyles.primaryTextColor
                ]}
              >
                Edit
              </Text>
            </TouchableOpacity>
          </View>
          <Text style={[globalStyles.text, globalStyles.fwLight]}>PayPal</Text>
        </>
        <View style={{ marginTop: 20, marginBottom: 10 }}>
          <FlatButton
            text="Place Order"
            transform="uppercase"
            type="primary"
            onPress={action}
          />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({})

export default Order
