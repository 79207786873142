import {
  StyleSheet,
  Text,
  View,
  Image,
  Dimensions,
  TouchableOpacity,
  SafeAreaView
} from 'react-native'
import React, { useState, useEffect, useRef } from 'react'
import { useNavigation } from '@react-navigation/native'
import { SwiperFlatList } from 'react-native-swiper-flatlist'
import { AntDesign } from '@expo/vector-icons'
import { useDispatch, useSelector } from 'react-redux'

// styles
import globalStyles from '../styles/global'

// utils
import { currencyFormatter } from '../utils/index'

// components
import Like from './Like'

// skeletons
import FeaturedProductsSkeleton from './skeletons/FeaturedProductsSkeleton'

// actions
import {
  clearListFeaturedProducts,
  listFeaturedProducts
} from '../redux/actions/productActions'

// redux constants
import { FEATURED_PRODUCT_LIST_RESET } from '../redux/constants/productConstants'

// constants
const DEVICE_WIDTH =
  Dimensions.get('window').width >= 768
    ? Dimensions.get('window').width - 320
    : Dimensions.get('window').width

const FeaturedProducts = ({ isFocused }) => {
  const [firstLoad, setFirstLoad] = useState(true)

  const dispatch = useDispatch()
  const navigation = useNavigation()

  const featuredProductList = useSelector((state) => state.featuredProductList)
  const { loading, error, products } = featuredProductList

  const handleOpenSingleProduct = (productId) => {
    navigation.navigate('ProductSingle', { productId })
  }

  useEffect(() => {
    if (firstLoad || isFocused) {
      dispatch(listFeaturedProducts())
      setFirstLoad(false)
    }

    return () => {
      clearListFeaturedProducts()
    }
  }, [dispatch, isFocused, firstLoad])

  if (loading) return <FeaturedProductsSkeleton />

  return (
    <>
      {!loading && products.length > 0 && (
        <View style={styles.slidesWrapper}>
          <SwiperFlatList
            disableVirtualization={true}
            index={0}
            autoplayDelay={1}
            showPagination
            paginationStyle={styles.slidesControl}
            paginationStyleItem={[
              styles.dot,
              {
                width: products.length > 1 ? DEVICE_WIDTH / products.length : 0
              }
            ]}
            paginationStyleItemInactive={[styles.inactive]}
            paginationStyleItemActive={[styles.active]}
            data={products}
            renderItem={({ item }) => (
              <TouchableOpacity
                style={styles.slide}
                onPress={() => handleOpenSingleProduct(item._id)}
              >
                <Image
                  source={{
                    uri: item.images[0].imageurl
                  }}
                  style={styles.slideImg}
                />
                <View style={styles.caption}>
                  <Text
                    style={[styles.captionText, styles.text]}
                    numberOfLines={2}
                  >
                    {item.shortName || item.name}
                  </Text>
                </View>
                <View style={styles.price}>
                  <Text style={[styles.priceText, styles.text]}>
                    {currencyFormatter(item.price)}
                  </Text>
                </View>
                <View style={styles.category}>
                  <Text style={[styles.categoryText, styles.text]}>
                    {item.category}
                  </Text>
                </View>
                <Like item={item} type="slide" />
              </TouchableOpacity>
            )}
          />
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  slidesWrapper: {
    flexDirection: 'row',
    backgroundColor: '#f5efe0',
    width: DEVICE_WIDTH,
    minHeight: 240,
    marginBottom: 20
  },
  slide: {
    position: 'relative',
    width: DEVICE_WIDTH,
    justifyContent: 'center',
    alignItems: 'center'
  },
  slideImg: {
    width: 220,
    height: 220,
    marginTop: 20
  },
  caption: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    left: 0,
    bottom: 0,
    width: '100%',
    // backgroundColor: 'rgba(0, 0, 0, 0.75)',
    paddingBottom: 16,
    paddingHorizontal: 16
  },
  captionText: {
    color: 'rgba(0, 0, 0, 1)',
    fontFamily: 'notosanstc-light',
    fontSize: 16,
    lineHeight: 21,
    paddingRight: 50,
    flex: 1
  },
  category: {
    position: 'absolute',
    top: 0,
    left: 0,
    // backgroundColor: 'rgba(0, 0, 0, 0.75)',
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderBottomLeftRadius: 0
  },
  categoryText: {
    color: 'rgba(0, 0, 0, 1)',
    fontFamily: 'notosanstc-light',
    fontSize: 14,
    lineHeight: 19
  },
  price: {
    position: 'absolute',
    top: 0,
    right: 0,
    // backgroundColor: 'rgba(0, 0, 0, 0.75)',
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderBottomLeftRadius: 0
  },
  priceText: {
    color: 'rgba(0, 0, 0, 1)',
    fontFamily: 'notosanstc-bold',
    fontSize: 16,
    lineHeight: 21
  },
  slidesControl: {
    justifyContent: 'center',
    alignItems: 'flex-end',
    margin: 0,
    height: 0,
    bottom: -15
  },
  dot: {
    backgroundColor: 'rgba(220, 20, 60, 1)',
    borderRadius: 0,
    marginHorizontal: 0,
    flex: 1,
    height: 6,
    borderRightColor: '#fff',
    borderRightWidth: 0
  },
  inactive: {
    backgroundColor: 'rgba(0, 0, 0, .15)'
    // backgroundColor: 'rgba(245, 239, 224, .6)',
  },
  active: {
    backgroundColor: '#333'
    // backgroundColor: 'rgba(220, 20, 60, 1)'
  },
  like: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    paddingBottom: 16,
    paddingHorizontal: 16,
    zIndex: 2,
    flexDirection: 'row',
    alignItems: 'center'
  }
})

export default FeaturedProducts
