import { StyleSheet, View, Dimensions } from 'react-native'
import React from 'react'

// styles
import globalStyles from '../../styles/global'

// constants
const DEVICE_WIDTH = Dimensions.get('window').width

const FeaturedProductsSkeleton = () => {
  return (
    <View style={styles.slidesWrapper}>
      <View style={styles.slide}>
        <View style={styles.slideImg}></View>
        <View style={styles.category}></View>
        <View style={styles.price}></View>
        <View style={styles.title}></View>
        <View style={styles.like}></View>
      </View>
      {false && (
        <View style={[styles.slidesControl, globalStyles.absolutePosition]}>
          <View style={[styles.dot, styles.active]}></View>
          <View style={[styles.dot, styles.inactive]}></View>
          <View style={[styles.dot, styles.inactive]}></View>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  slidesWrapper: {
    flexDirection: 'row',
    backgroundColor: 'rgba(15, 23, 42, .05)',
    width: DEVICE_WIDTH,
    minHeight: 277,
    marginBottom: 20
  },
  slide: {
    position: 'relative',
    width: DEVICE_WIDTH,
    justifyContent: 'center',
    alignItems: 'center'
  },
  slideImg: {
    width: 140,
    height: 140,
    borderRadius: 140,
    backgroundColor: 'rgba(15, 23, 42, .1)'
  },
  category: {
    backgroundColor: 'rgba(15, 23, 42, .1)',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '25%',
    padding: 6,
    borderRadius: 10,
    marginVertical: 16,
    marginHorizontal: 16
  },
  price: {
    backgroundColor: 'rgba(15, 23, 42, .1)',
    position: 'absolute',
    top: 0,
    right: 0,
    width: 30,
    padding: 6,
    borderRadius: 10,
    marginVertical: 16,
    marginHorizontal: 16
  },
  title: {
    backgroundColor: 'rgba(15, 23, 42, .1)',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '40%',
    padding: 6,
    borderRadius: 10,
    marginVertical: 16,
    marginHorizontal: 16
  },
  like: {
    backgroundColor: 'rgba(15, 23, 42, .1)',
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: 30,
    padding: 6,
    borderRadius: 10,
    marginVertical: 16,
    marginHorizontal: 16
  },
  slidesControl: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: -6
  },
  dot: {
    backgroundColor: 'rgba(15, 23, 42, 1)',
    borderRadius: 0,
    marginHorizontal: 0,
    width: DEVICE_WIDTH / 3,
    flex: 1,
    height: 6,
    borderRightColor: '#fff',
    borderRightWidth: 0
  },
  inactive: {
    backgroundColor: 'rgba(15, 23, 42, .07)'
  },
  active: {
    backgroundColor: 'rgba(15, 23, 42, .15)'
  }
})

export default FeaturedProductsSkeleton
