import { StyleSheet } from 'react-native'

const globalStyles = StyleSheet.create({
  // container
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: '#fff'
  },
  toastContainer: {
    paddingHorizontal: 12,
    paddingVertical: 12,
    borderRadius: 5,
    marginVertical: 5,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden'
  },

  // color
  // colors: {
  //   toastifyColorLight: '#fff',
  //   toastifyColorDark: '#121212',
  //   toastifyColorInfo: '#3498db',
  //   toastifyColorSuccess: '#07bc0c',
  //   toastifyColorWarning: '#f1c40f',
  //   toastifyColorError: '#e74c3c',
  //   toastifyColorTransparent: 'rgba(255, 255, 255, 0.7)'
  // },
  primaryTextColor: {
    color: '#df143c'
  },
  primaryBgColor: {
    backgroundColor: '#df143c'
  },
  secondaryTextColor: {
    color: '#f5efe0'
  },
  secondaryBgColor: {
    backgroundColor: '#f5efe0'
  },
  darkTextColor: {
    color: '#333'
  },
  darkBgColor: {
    backgroundColor: '#333'
  },
  lightTextColor: {
    color: '#e9ecef'
  },
  lightBgColor: {
    backgroundColor: '#e9ecef'
  },
  blackTextColor: {
    color: '#000'
  },
  blackBgColor: {
    backgroundColor: '#000'
  },
  whiteTextColor: {
    color: '#fff'
  },
  whiteBgColor: {
    backgroundColor: '#fff'
  },
  textCenter: {
    textAlign: 'center'
  },

  // typography
  text: {
    fontSize: 14,
    lineHeight: 19,
    fontFamily: 'notosanstc-regular'
  },
  fwThin: {
    fontFamily: 'notosanstc-thin'
  },
  fwLight: {
    fontFamily: 'notosanstc-light'
  },
  fwRegular: {
    fontFamily: 'notosanstc-regular'
  },
  fwMedium: {
    fontFamily: 'notosanstc-medium'
  },
  fwBold: {
    fontFamily: 'notosanstc-bold'
  },
  fwBlack: {
    fontFamily: 'notosanstc-black'
  },
  fs10: {
    fontSize: 10,
    lineHeight: 15
  },
  fs11: {
    fontSize: 11,
    lineHeight: 16
  },
  fs12: {
    fontSize: 12,
    lineHeight: 17
  },
  fs13: {
    fontSize: 13,
    lineHeight: 18
  },
  fs14: {
    fontSize: 14,
    lineHeight: 19
  },
  fs15: {
    fontSize: 15,
    lineHeight: 20
  },
  fs16: {
    fontSize: 16,
    lineHeight: 21
  },
  fs17: {
    fontSize: 17,
    lineHeight: 22
  },
  fs18: {
    fontSize: 18,
    lineHeight: 23
  },
  fs19: {
    fontSize: 19,
    lineHeight: 24
  },
  fs20: {
    fontSize: 20,
    lineHeight: 25
  },
  fs21: {
    fontSize: 21,
    lineHeight: 26
  },
  fs22: {
    fontSize: 22,
    lineHeight: 27
  },
  fs23: {
    fontSize: 23,
    lineHeight: 28
  },
  fs24: {
    fontSize: 24,
    lineHeight: 29
  },
  fs25: {
    fontSize: 25,
    lineHeight: 30
  },
  fs26: {
    fontSize: 26,
    lineHeight: 31
  },
  fs28: {
    fontSize: 28,
    lineHeight: 33
  },
  fs29: {
    fontSize: 29,
    lineHeight: 34
  },
  fs30: {
    fontSize: 30,
    lineHeight: 35
  },

  // utils
  textUppercase: {
    textTransform: 'uppercase'
  },
  textLowecase: {
    textTransform: 'lowercase'
  },
  textCapitalize: {
    textTransform: 'capitalize'
  },
  textItalic: {
    fontStyle: 'italic'
  },
  opacity25: {
    opacity: 0.25
  },
  opacity50: {
    opacity: 0.5
  },
  opacity75: {
    opacity: 0.75
  },
  linkUnderline: {
    borderBottomWidth: 1,
    borderBottomColor: '#97a4af',
    borderStyle: 'dotted',
    borderRadius: 1
  },
  separator: {
    marginVertical: 20,
    borderBottomWidth: 1,
    borderColor: '#E9ECEF'
  },
  separatorBottomNarrow: {
    marginBottom: 10
  },
  relativePosition: {
    position: 'relative'
  },
  absolutePosition: {
    position: 'absolute'
  },

  // overlap
  containerBand: {
    minHeight: 100,
    paddingBottom: 60
  },
  containerOverlap: {
    marginTop: -70,
    padding: 16
  },
  containerOverlapInner: {
    backgroundColor: '#fff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  containerOverlapNoGap: {
    padding: 0
  },
  textOverlap: {
    marginHorizontal: 17,
    marginTop: 26,
    marginBottom: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },

  // space
  mt0: { marginTop: 0 },
  mt1: { marginTop: 5 },
  mt2: { marginTop: 10 },
  mt3: { marginTop: 15 },
  mt4: { marginTop: 20 },
  mt5: { marginTop: 25 },
  mt6: { marginTop: 30 },
  mt7: { marginTop: 35 },
  mt8: { marginTop: 40 },
  mt9: { marginTop: 45 },
  mt10: { marginTop: 50 },
  mb0: { marginBottom: 0 },
  mb1: { marginBottom: 5 },
  mb2: { marginBottom: 10 },
  mb3: { marginBottom: 15 },
  mb4: { marginBottom: 20 },
  mb5: { marginBottom: 25 },
  mb6: { marginBottom: 30 },
  mb7: { marginBottom: 35 },
  mb8: { marginBottom: 40 },
  mb9: { marginBottom: 45 },
  mb10: { marginBottom: 50 },
  mr0: { marginRight: 0 },
  mr1: { marginRight: 5 },
  mr2: { marginRight: 10 },
  mr3: { marginRight: 15 },
  mr4: { marginRight: 20 },
  mr5: { marginRight: 25 },
  mr6: { marginRight: 30 },
  mr7: { marginRight: 35 },
  mr8: { marginRight: 40 },
  mr9: { marginRight: 45 },
  mr10: { marginRight: 50 },
  ml0: { marginLeft: 0 },
  ml1: { marginLeft: 5 },
  ml2: { marginLeft: 10 },
  ml3: { marginLeft: 15 },
  ml4: { marginLeft: 20 },
  ml5: { marginLeft: 25 },
  ml6: { marginLeft: 30 },
  ml7: { marginLeft: 35 },
  ml8: { marginLeft: 40 },
  ml9: { marginLeft: 45 },
  ml10: { marginLeft: 50 },
  pt0: { paddingTop: 0 },
  pt1: { paddingTop: 5 },
  pt2: { paddingTop: 10 },
  pt3: { paddingTop: 15 },
  pt4: { paddingTop: 20 },
  pt5: { paddingTop: 25 },
  pt6: { paddingTop: 30 },
  pt7: { paddingTop: 35 },
  pt8: { paddingTop: 40 },
  pt9: { paddingTop: 45 },
  pt10: { paddingTop: 50 },
  pb0: { paddingBottom: 0 },
  pb1: { paddingBottom: 5 },
  pb2: { paddingBottom: 10 },
  pb3: { paddingBottom: 15 },
  pb4: { paddingBottom: 20 },
  pb5: { paddingBottom: 25 },
  pb6: { paddingBottom: 30 },
  pb7: { paddingBottom: 35 },
  pb8: { paddingBottom: 40 },
  pb9: { paddingBottom: 45 },
  pb10: { paddingBottom: 50 },
  pr0: { paddingRight: 0 },
  pr1: { paddingRight: 5 },
  pr2: { paddingRight: 10 },
  pr3: { paddingRight: 15 },
  pr4: { paddingRight: 20 },
  pr5: { paddingRight: 25 },
  pr6: { paddingRight: 30 },
  pr7: { paddingRight: 35 },
  pr8: { paddingRight: 40 },
  pr9: { paddingRight: 45 },
  pr10: { paddingRight: 50 },
  pl0: { paddingLeft: 0 },
  pl1: { paddingLeft: 5 },
  pl2: { paddingLeft: 10 },
  pl3: { paddingLeft: 15 },
  pl4: { paddingLeft: 20 },
  pl5: { paddingLeft: 25 },
  pl6: { paddingLeft: 30 },
  pl7: { paddingLeft: 35 },
  pl8: { paddingLeft: 40 },
  pl9: { paddingLeft: 45 },
  pl10: { paddingLeft: 50 },

  // aligned items
  flexOne: {
    flex: 1
  },
  hDirection: {
    flexDirection: 'row'
  },
  hAlignItems: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  hBetweenItems: {
    justifyContent: 'space-between'
  },
  hStartItems: {
    justifyContent: 'flex-start'
  },
  hEndItems: {
    justifyContent: 'flex-end'
  },
  hCenterItems: {
    justifyContent: 'center'
  },

  vDirection: {
    flexDirection: 'column'
  },
  vAlignItems: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  vStartItems: {
    alignItems: 'flex-start'
  },
  vEndItems: {
    alignItems: 'flex-end'
  },
  vCenterItems: {
    alignItems: 'center'
  },

  // cards
  card: {
    position: 'relative',
    justifyContent: 'flex-end',
    borderRadius: 10,
    backgroundColor: '#fff',
    borderColor: '#e9ecef',
    borderWidth: 1,
    padding: 16
  },

  // form
  inputContainer: {
    // marginVertical: 0,
    // padding: 16
  },
  input: {
    borderWidth: 1,
    backgroundColor: '#fff',
    borderColor: '#e9ecef',
    paddingHorizontal: 16,
    height: 46,
    fontSize: 16,
    lineHeight: 21,
    borderRadius: 6,
    marginVertical: 4,
    fontFamily: 'notosanstc-medium'
  },
  inputLabelWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10
  },
  inputLabel: {
    color: '#333',
    fontFamily: 'notosanstc-medium',
    fontSize: 14,
    lineHeight: 19,
    marginBottom: 0,
    marginTop: 16,
    marginHorizontal: 1
  },
  inputLabelNote: {
    color: 'rgba(0,0,0, .7)',
    fontFamily: 'notosanstc-light',
    fontSize: 11,
    lineHeight: 16,
    marginBottom: 0,
    marginTop: 16,
    marginHorizontal: 3
  },
  inputError: {
    borderColor: '#ff4444'
  },
  textError: {
    color: '#ff4444'
  },
  textarea: {
    textAlignVertical: 'top',
    height: 120,
    paddingVertical: 12
  },
  note: {
    fontFamily: 'notosanstc-light',
    fontSize: 11,
    lineHeight: 16,
    color: 'rgba(0,0,0, .7)'
  },

  // drawer
  drawer: {
    borderBottomWidth: 1,
    borderColor: '#E9ECEF'
  },
  lastDrawer: {
    borderBottomWidth: 0
  },
  drawerHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16
  },
  drawerBody: { paddingHorizontal: 16 },
  drawerBodyInner: {
    paddingVertical: 16
  },
  drawerBodyItem: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 6
  },
  drawerBodyItemTitle: {
    fontFamily: 'notosanstc-bold',
    fontSize: 14,
    lineHeight: 19,
    marginRight: 10
  },
  drawerBodyItemDesc: {
    fontFamily: 'notosanstc-light',
    fontSize: 14,
    lineHeight: 19
  },
  drawerBodyItemLink: {
    fontFamily: 'notosanstc-bold',
    fontSize: 14,
    lineHeight: 19,
    color: '#df143c'
  }
})

export default globalStyles
