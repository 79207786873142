import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  Image,
  Dimensions,
  ActivityIndicator,
  RefreshControl
} from 'react-native'
import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation, useIsFocused } from '@react-navigation/native'
import { AntDesign } from '@expo/vector-icons'
import { useToast } from 'react-native-toast-notifications'

// styles
import globalStyles from '../styles/global'

// utils
import { currencyFormatter } from '../utils/index'

// config
import { shownReviews, productImagePlaceholder } from '../config'

// data
import ProductSlider from '../components/ProductSlider'
import ReviewList from '../components/ٍReviewList'

// components
import FlatButton from '../components/Button'
import Rating from '../components/Rating'
import Counter from '../components/Counter'
import Like from '../components/Like'

// screens
import AddReviewScreen from './AddReviewScreen'
import EditReviewScreen from './EditReviewScreen'

// actions
import { addToCart } from '../redux/actions/cartActions'
import {
  likeProduct,
  listProductDetails
} from '../redux/actions/productActions'

// actions
import ProductSingleSkeleton from '../components/skeletons/ProductSingleSkeleton'

// constants
const DEVICE_WIDTH = Dimensions.get('window').width

const ProductSingleScreen = ({ route }) => {
  const [reviewModalOpen, setReviewModalOpen] = useState(false)
  const [editReviewModalOpen, setEditReviewModalOpen] = useState(false)
  const [closeModal, setCloseModal] = useState(false)

  // console.log('closeModal:', closeModal)

  const [count, setCount] = useState(1)
  const [isShown, setIsShown] = useState(false)
  const [readMore, setReadMore] = useState('Read More')
  const [isReviewed, setIsReviewed] = useState(false)

  const { productId } = route.params

  // CONSOLE.LOG
  // console.log('productId:', productId)

  const [refreshing, setRefreshing] = React.useState(false)
  const onRefresh = React.useCallback(() => {
    setRefreshing(true)

    // CONSOLE.LOG
    console.log('refreshing...')
    dispatch(listProductDetails(productId))
    _isReviewedByMe(userInfo?._id) ? setIsReviewed(true) : setIsReviewed(false)
    if (!loading) setRefreshing(false)
  }, [])

  const navigation = useNavigation()
  const dispatch = useDispatch()
  const toast = useToast()
  const isFocused = useIsFocused()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product } = productDetails

  const _isReviewedByMe = useCallback(
    (userId) => product?.reviews.find((review) => review.user === userId),
    [product?.reviews]
  )

  const handleWriteReview = () => {
    setReviewModalOpen(!reviewModalOpen)
  }

  const handleReadMore = () => {
    isShown ? setReadMore('Read More') : setReadMore('Show Less')
    setIsShown(!isShown)
  }

  const addToCartHandler = () => {
    dispatch(addToCart(productId, Number(count), toast))
    // setModalOpen(!modalOpen)
    setCount(1)
    navigation.navigate('Home', {})
  }

  useEffect(() => {
    dispatch(listProductDetails(productId))
  }, [dispatch, productId, isFocused, closeModal])

  useEffect(() => {
    _isReviewedByMe(userInfo?._id) ? setIsReviewed(true) : setIsReviewed(false)
  }, [_isReviewedByMe, userInfo?._id, isFocused, closeModal])

  // CONSOLE.LOG
  // console.log('product:', product)

  if (loading) return <ProductSingleSkeleton />

  if (!loading && error)
    return (
      <View style={[styles.container]}>
        <View style={[globalStyles.vCenterItems, globalStyles.mt10]}>
          <Text
            style={[globalStyles.text, globalStyles.fs20, globalStyles.fwLight]}
          >
            Item does not exist!
          </Text>
        </View>
      </View>
    )

  return (
    <View style={[styles.container]}>
      {!loading && product && (
        <ScrollView
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
        >
          <View style={{ marginVertical: 16, marginHorizontal: 16 }}>
            <View style={{ marginBottom: 10 }}>
              <Text
                style={{
                  fontFamily: 'notosanstc-light',
                  fontSize: 14,
                  lineHeight: 19,
                  marginBottom: 8
                }}
              >
                {product.category}
              </Text>
              <Text
                style={{
                  fontFamily: 'notosanstc-bold',
                  fontSize: 24,
                  lineHeight: 29,
                  marginBottom: 10
                }}
              >
                {product.name}
              </Text>
              <View
                style={[
                  {
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }
                ]}
              >
                <Rating rating={parseFloat(product.rating)} />
                <Text
                  style={[
                    globalStyles.fwLight,
                    globalStyles.fs14,
                    { marginLeft: 6 }
                  ]}
                >
                  {product.numReviews}{' '}
                  {product.numReviews === 1 ? 'review' : 'reviews'}
                </Text>
              </View>
            </View>
            {product.images?.length === 1 || product.images?.length === 0 ? (
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: 15,
                  paddingTop: 15
                }}
              >
                <Image
                  source={{
                    uri:
                      product.images.length > 0
                        ? product.images[0].imageurl
                        : productImagePlaceholder
                  }}
                  style={{ width: 280, height: 280 }}
                />
                <Like
                  size={20}
                  item={product}
                  type="details"
                  // mode="modal"
                  // setModalOpen={setModalOpen}
                />
              </View>
            ) : (
              <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                <ProductSlider images={product.images} />
                <Like
                  size={20}
                  item={product}
                  type="details"
                  // mode="modal"
                  // setModalOpen={setModalOpen}
                />
              </View>
            )}

            <View style={{ marginBottom: 0 }}>
              <Text
                style={{
                  fontFamily: 'notosanstc-light',
                  fontSize: 14,
                  lineHeight: 19
                }}
              >
                {product.description}
              </Text>
            </View>
            <View style={globalStyles.separator}></View>
            <View>
              <View style={{ flexDirection: 'row', marginBottom: 16 }}>
                <Text
                  style={{
                    fontFamily: 'notosanstc-light',
                    fontSize: 14,
                    lineHeight: 19,
                    marginRight: 8
                  }}
                >
                  Price:
                </Text>
                <Text
                  style={{
                    fontFamily: 'notosanstc-bold',
                    fontSize: 14,
                    lineHeight: 19
                  }}
                >
                  {currencyFormatter(parseFloat(product.price))}
                </Text>
              </View>
              <View style={{ flexDirection: 'row', marginBottom: 12 }}>
                <Text
                  style={{
                    fontFamily: 'notosanstc-light',
                    fontSize: 14,
                    lineHeight: 19,
                    marginRight: 8
                  }}
                >
                  Status:
                </Text>
                <Text
                  style={{
                    fontFamily: 'notosanstc-bold',
                    fontSize: 14,
                    lineHeight: 19
                  }}
                >
                  {product.countInStock !== 0 ? 'In Stock' : 'Out of Stock'}
                </Text>
              </View>
              {/* <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    marginBottom: 12
                  }}
                >
                  <Text
                    style={{
                      fontFamily: 'notosanstc-light',
                      fontSize: 14,
                      lineHeight: 19,
                      marginRight: 12
                    }}
                  >
                    Count In Stock: {product.countInStock}
                  </Text>
                </View> */}
              {product.countInStock > 0 && (
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    marginBottom: 12
                  }}
                >
                  <Text
                    style={{
                      fontFamily: 'notosanstc-light',
                      fontSize: 14,
                      lineHeight: 19,
                      marginRight: 12
                    }}
                  >
                    Quantity:
                  </Text>
                  <Counter
                    count={count}
                    setCount={setCount}
                    countInStock={product.countInStock}
                  />
                </View>
              )}
            </View>
            <View style={{ marginTop: 20, marginBottom: 20 }}>
              <FlatButton
                text="Add to cart"
                transform="uppercase"
                type="primary"
                onPress={addToCartHandler}
                isDisabled={product.countInStock < 1 && true}
              />
            </View>
            <View
              style={[
                globalStyles.separator,
                globalStyles.separatorBottomNarrow
              ]}
            ></View>
            <View style={[globalStyles.mt2]}>
              <ReviewList
                productId={product._id}
                reviews={product.reviews}
                isShown={isShown}
                editReviewModalOpen={editReviewModalOpen}
                setEditReviewModalOpen={setEditReviewModalOpen}
                closeModal={closeModal}
                setCloseModal={setCloseModal}
              />
            </View>
            <View
              style={[
                globalStyles.hAlignItems,
                globalStyles.hBetweenItems,
                globalStyles.mt2,
                globalStyles.mb8
              ]}
            >
              {product.reviews?.length > shownReviews && (
                <View
                  style={[
                    globalStyles.flexOne,
                    !isReviewed && userInfo && globalStyles.mr1
                  ]}
                >
                  <FlatButton
                    text={readMore}
                    transform="uppercase"
                    type="secondary"
                    onPress={handleReadMore}
                    leftIcon={isShown && true}
                    rightIcon={!isShown && true}
                    icon={
                      isShown ? 'chevron-back-sharp' : 'chevron-forward-sharp'
                    }
                  />
                </View>
              )}
              {!isReviewed && userInfo && (
                <View
                  style={[
                    globalStyles.flexOne,
                    product.reviews?.length > shownReviews && globalStyles.ml1
                  ]}
                >
                  <FlatButton
                    text="Write a Review"
                    transform="uppercase"
                    type="primary"
                    onPress={handleWriteReview}
                  />
                </View>
              )}
            </View>
          </View>
        </ScrollView>
      )}
      {!loading && product && product.images && product.images.length > 0 && (
        <>
          <AddReviewScreen
            productId={product._id}
            reviewModalOpen={reviewModalOpen}
            setReviewModalOpen={setReviewModalOpen}
            setIsReviewed={setIsReviewed}
            productImage={product.images[0].imageurl}
            productName={product.name}
            productRating={product.rating}
            productNumReviews={product.numReviews}
            closeModal={closeModal}
            setCloseModal={setCloseModal}
          />
          <EditReviewScreen
            productId={product._id}
            editReviewModalOpen={editReviewModalOpen}
            setEditReviewModalOpen={setEditReviewModalOpen}
            productImage={product.images[0].imageurl}
            productName={product.name}
            productRating={product.rating}
            productNumReviews={product.numReviews}
            closeModal={closeModal}
            setCloseModal={setCloseModal}
          />
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  toggleText: {
    fontFamily: 'notosanstc-light',
    marginVertical: 0,
    marginHorizontal: 0,
    textTransform: 'uppercase'
  },
  disclaimer: {
    fontFamily: 'notosanstc-light',
    color: '#000',
    textAlign: 'center',
    paddingTop: 25,
    paddingBottom: 9
  },
  separator: {
    marginVertical: 20,
    borderBottomWidth: 1,
    borderColor: '#E9ECEF'
  },
  like: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 2,
    flexDirection: 'row',
    alignItems: 'center'
  }
})

export default ProductSingleScreen
