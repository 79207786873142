import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Keyboard,
  Modal,
  TouchableOpacity,
  TouchableWithoutFeedback,
  TextInput,
  Image
} from 'react-native'
import React, { useCallback, useState, useEffect } from 'react'
import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { useToast } from 'react-native-toast-notifications'
import { Formik } from 'formik'
import * as yup from 'yup'
import { AntDesign } from '@expo/vector-icons'

// components
import FlatButton from '../components/Button'
import Rating from '../components/Rating'

// styles
import globalStyles from '../styles/global'

// actions
import { editReview, updateReview } from '../redux/actions/productActions'

const reviewSchema = yup.object({
  title: yup.string().required('Title is required'),
  rating: yup
    .string()
    .required('Rating is required')
    .matches(/^[1-5]$/, 'The value should be 1, 2, 3, 4 or 5'),
  comment: yup.string().required('Comment is required')
})

const EditReviewScreen = ({
  productId,
  editReviewModalOpen,
  setEditReviewModalOpen,
  productImage,
  productName,
  productRating,
  productNumReviews,
  closeModal,
  setCloseModal
}) => {
  const [initialValues, setInitialValues] = useState({
    title: '',
    rating: '',
    comment: ''
  })

  const dispatch = useDispatch()
  const navigation = useNavigation()
  const toast = useToast()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const reviewEdit = useSelector((state) => state.reviewEdit)
  const {
    loading: loadingEdit,
    error: errorEdit,
    review,
    success: successEdit
  } = reviewEdit

  const reviewUpdate = useSelector((state) => state.reviewUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    product,
    success: successUpdate
  } = reviewUpdate

  const _editReview = useCallback(async () => {
    setInitialValues((prevInitiaValues) => ({
      ...prevInitiaValues,
      title: review?.title || '',
      rating: review?.rating?.toString() || '',
      comment: review?.comment || ''
    }))
  }, [review])

  const handleRating = (text) => {
    if (!text || text.match(/^[1-5]$/)) setRating(text)
  }

  const closeEditReviewModal = () => {
    setEditReviewModalOpen(!editReviewModalOpen)
  }

  const onSubmit = (values, actions) => {
    actions.resetForm()
    dispatch(updateReview(productId, values, toast))

    closeEditReviewModal()

    setCloseModal(!closeModal)
    // setCloseModal(Math.random())
  }

  useEffect(() => {
    _editReview()
  }, [_editReview])

  useEffect(() => {
    if (productId) dispatch(editReview(productId, toast))
  }, [dispatch, productId])

  // console.log('review:', review)
  // console.log('initialValues:', initialValues)

  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      <Modal visible={editReviewModalOpen} animationType="slide">
        <ScrollView style={styles.modalContent}>
          <View style={[globalStyles.hAlignItems, globalStyles.hBetweenItems]}>
            <View style={{ marginVertical: 16, marginHorizontal: 16 }}>
              <Text style={[globalStyles.fwBold, globalStyles.fs22]}>
                Edit Review
              </Text>
            </View>
            <TouchableOpacity
              style={styles.modalClose}
              onPress={closeEditReviewModal}
            >
              <AntDesign name="close" size={28} color="#333" />
            </TouchableOpacity>
          </View>
          <View
            style={[
              globalStyles.vCenterItems,
              globalStyles.mt2,
              globalStyles.mb2
            ]}
          >
            <Image
              source={{ uri: productImage }}
              style={{ width: 140, height: 140, borderRadius: 140 }}
            />
            <View>
              <Text
                style={[
                  globalStyles.text,
                  globalStyles.fs18,
                  globalStyles.fwLight,
                  { paddingHorizontal: 60, textAlign: 'center' }
                ]}
              >
                {productName}
              </Text>
            </View>
            <View
              style={[
                globalStyles.mt2,
                globalStyles.hAlignItems,
                globalStyles.hCenterItems
              ]}
            >
              <Rating rating={productRating} />
              <Text
                style={[
                  globalStyles.text,
                  globalStyles.fwLight,
                  globalStyles.ml1
                ]}
              >
                {productNumReviews}{' '}
                {productNumReviews === 1 ? 'review' : 'reviews'}
              </Text>
            </View>
          </View>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={reviewSchema}
            onSubmit={onSubmit}
          >
            {(formik) => (
              <View
                style={(globalStyles.inputContainer, { padding: 16 })}
                behavior="padding"
              >
                <View
                  style={[globalStyles.inputLabelWrapper, { marginTop: -8 }]}
                >
                  <Text style={[globalStyles.inputLabel]}>Title</Text>
                  <Text style={globalStyles.inputLabelNote}>(required)</Text>
                </View>
                <TextInput
                  placeholder=""
                  value={formik.values.title}
                  onChangeText={formik.handleChange('title')}
                  onBlur={formik.handleBlur('title')}
                  style={[
                    globalStyles.input,
                    formik.touched.title &&
                      formik.errors.title &&
                      globalStyles.inputError
                  ]}
                  required
                />
                {formik.touched.title && formik.errors.title && (
                  <Text style={[globalStyles.note, globalStyles.textError]}>
                    {formik.errors.title}
                  </Text>
                )}
                <View style={[globalStyles.inputLabelWrapper]}>
                  <Text style={[globalStyles.inputLabel]}>Rating</Text>
                  <Text style={globalStyles.inputLabelNote}>(required)</Text>
                </View>
                <TextInput
                  placeholder="Rating (1-5) .e.g. 1, 2, 3, 4 or 5"
                  value={formik.values.rating}
                  onChangeText={formik.handleChange('rating')}
                  onBlur={formik.handleBlur('rating')}
                  style={[
                    globalStyles.input,
                    formik.touched.rating &&
                      formik.errors.rating &&
                      globalStyles.inputError
                  ]}
                  keyboardType="numeric"
                  required
                />
                {formik.touched.rating && formik.errors.rating ? (
                  <Text style={[globalStyles.note, globalStyles.textError]}>
                    {formik.errors.rating}
                  </Text>
                ) : (
                  <Text style={[globalStyles.note]}>
                    1: Poor, 2: Fair, 3: Good, 4: Very Good, 5: Excellent.
                  </Text>
                )}
                <View style={[globalStyles.inputLabelWrapper]}>
                  <Text style={[globalStyles.inputLabel]}>Comment</Text>
                  <Text style={globalStyles.inputLabelNote}>(required)</Text>
                </View>
                <TextInput
                  multiline
                  numberOfLines={4}
                  placeholder=""
                  value={formik.values.comment}
                  onChangeText={formik.handleChange('comment')}
                  onBlur={formik.handleBlur('comment')}
                  style={[
                    globalStyles.input,
                    globalStyles.textarea,
                    formik.touched.comment &&
                      formik.errors.comment &&
                      globalStyles.inputError
                  ]}
                  required
                />
                {formik.touched.comment && formik.errors.comment && (
                  <Text style={[globalStyles.note, globalStyles.textError]}>
                    {formik.errors.comment}
                  </Text>
                )}
                <View style={{ marginTop: 20, marginBottom: 40 }}>
                  <FlatButton
                    text="Update review"
                    transform="uppercase"
                    type="primary"
                    onPress={formik.handleSubmit}
                    isDisabled={!formik.isValid}
                  />
                </View>
              </View>
            )}
          </Formik>
        </ScrollView>
      </Modal>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  modalContent: {
    flex: 1
  },
  modalOpen: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginVertical: 10,
    marginHorizontal: 4
  },
  modalClose: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginVertical: 16,
    marginHorizontal: 16
  }
})

export default EditReviewScreen
