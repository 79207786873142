import { StyleSheet, Text, View, TouchableOpacity } from 'react-native'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Ionicons } from '@expo/vector-icons'
import { MaterialCommunityIcons } from '@expo/vector-icons'

// styles
import globalStyles from '../styles/global'
import ProductFilterSkeleton from './skeletons/ProductFilterSkeleton'
import FlatButton from './Button'

const WishlistFilter = ({ theme = 'light', filter, setFilter }) => {
  const [filterToggle, setFilterToggle] = useState(false)
  const [filterOptions, setFilterOptions] = useState([
    { selected: false, label: 'Newest first', value: 'newest-first' },
    { selected: false, label: 'Top sellers', value: 'top-sellers' },
    { selected: false, label: 'Price (high - low)', value: 'price-high-low' },
    { selected: false, label: 'Price (low - high)', value: 'price-low-high' },
    { selected: false, label: 'A to Z', value: 'a-z' },
    { selected: false, label: 'Z to A', value: 'z-a' }
  ])

  // const productList = useSelector((state) => state.productList)
  // const { meta } = productList

  const productWishlist = useSelector((state) => state.productWishlist)
  const { meta } = productWishlist

  // console.log('meta:', meta)

  const filterToggleHandler = () => {
    setFilterToggle(!filterToggle)
  }

  const clearFilterHandler = () => {
    setFilter({})
    const resetFilterOptions = filterOptions.map((obj) => ({
      ...obj,
      selected: false
    }))
    setFilterOptions(resetFilterOptions)
    setFilterToggle(false)
  }

  const selectOptionHandler = (index) => {
    const updatedOptions = filterOptions.map((obj, i) => {
      if (i === index) {
        // return { ...obj, selected: !obj.selected }
        setFilter({ ...obj, selected: true })
        return { ...obj, selected: true }
      } else {
        return { ...obj, selected: false }
      }
    })

    setFilterOptions(updatedOptions)
    setFilterToggle(false)
  }

  useEffect(() => {
    setFilterOptions((current) =>
      current.map((obj) => {
        if (filter.value === obj.value) {
          return { ...obj, ...filter }
        }
        return obj
      })
    )
  }, [filter])

  return (
    <View>
      <View
        style={[
          globalStyles.hAlignItems,
          globalStyles.vCenterItems,
          globalStyles.hBetweenItems,
          { marginHorizontal: 16 }
        ]}
      >
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginVertical: 4
          }}
          onPress={filterToggleHandler}
        >
          <Ionicons
            name={filterToggle ? 'filter' : 'filter-outline'}
            size={24}
            color={filterToggle ? '#000' : '#000'}
          />
          <Text
            style={[
              globalStyles.text,
              filterToggle ? globalStyles.fwBold : globalStyles.fwLight,
              globalStyles.ml2
            ]}
          >
            Filter
          </Text>
        </TouchableOpacity>
        {meta && meta.total_products > 0 && (
          <Text style={[globalStyles.text, globalStyles.fwLight]}>
            Showing {1} to {meta.to_page} of {meta.total_products}{' '}
            {meta.total_products === 1 ? 'product' : 'products'}
          </Text>
        )}
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-start',
          marginLeft: 16,
          marginTop: 6
        }}
      >
        {filter && typeof filter.value !== 'undefined' && (
          <FlatButton
            text={`${filter.label} `}
            transform="uppercase"
            type={theme === 'light' ? 'light' : 'dark'}
            weight="light"
            rightIcon={true}
            icon="close-outline"
            colorIcon={theme === 'light' ? 'dark' : 'light'}
            size="tag"
            onPress={clearFilterHandler}
          />
        )}
      </View>
      {filterToggle && (
        <View
          style={{
            marginHorizontal: 16,
            marginVertical: 16
          }}
        >
          <View style={[styles.grid]}>
            {filterOptions.map((option, index) => (
              <TouchableOpacity
                style={[styles.item]}
                onPress={() => selectOptionHandler(index)}
                key={index}
              >
                <View style={[styles.itemIcon]}>
                  <MaterialCommunityIcons
                    name={
                      option.selected ? 'radiobox-marked' : 'radiobox-blank'
                    }
                    size={24}
                    color={option.selected ? '#df143c' : '#000'}
                  />
                </View>
                <Text style={[styles.itemText]}>{option.label}</Text>
              </TouchableOpacity>
            ))}
          </View>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  grid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start' // if you want to fill rows left to right
  },
  item: {
    width: '50%', // is 50% of container width
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginVertical: 4
  },
  itemText: {
    fontFamily: 'notosanstc-light',
    fontSize: 14,
    lineHeight: 19
  },
  itemIcon: {
    marginLeft: -3,
    marginRight: 7
  }
})

export default WishlistFilter
