import {
  StyleSheet,
  Text,
  View,
  Image,
  useWindowDimensions,
  TouchableOpacity,
  Pressable,
  Platform
} from 'react-native'
import React, { useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { AntDesign } from '@expo/vector-icons'
import { Feather } from '@expo/vector-icons'
import { Ionicons } from '@expo/vector-icons'
import { SimpleLineIcons } from '@expo/vector-icons'
import { DrawerActions } from '@react-navigation/native'
import { FontAwesome } from '@expo/vector-icons'

// components
import HomeScreen from '../screens/HomeScreen'
import SettingsScreen from '../screens/SettingsScreen'
import ProfileScreen from '../screens/ProfileScreen'
import OrderListScreen from '../screens/OrderListScreen'
import CustomDrawerContent from './CustomDrawerContent'
import WishlistScreen from '../screens/WishlistScreen'
import MyReviewsScreen from '../screens/MyReviewsScreen'

// local storage
import { getLocalStotage } from '../storage'

// constants
const MARGIN_LEFT_RIGHT = Platform.OS === 'web' ? 16 : 0

const Drawer = createDrawerNavigator()

const DrawerNavigator = () => {
  const navigation = useNavigation()
  const { width } = useWindowDimensions()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  const getItemSubtotal = (cartItems) =>
    cartItems.reduce((acc, item) => acc + item.quantity, 0)

  const getItemTotalPrice = (cartItems) =>
    cartItems.reduce((acc, item) => acc + item.quantity * item.price, 0)

  const openSearchHandler = () => {
    navigation.navigate('Search', { startSearch: true })
  }

  const openCartHandler = () => {
    navigation.navigate('Cart', {})
  }

  const refreshHomeHandler = () => {
    navigation.navigate('Home', {})
  }

  return (
    <Drawer.Navigator
      drawerContent={(props) => <CustomDrawerContent props={props} />}
      initialRouteName="Home"
      screenOptions={() => ({
        headerStyle: {
          backgroundColor: '#df143c',
          borderBottomWidth: 0,
          borderBottomColor: '#df143c',
          elevation: 0
          // height: 90
        },
        headerTitleStyle: {
          fontFamily: 'notosanstc-medium',
          color: '#fff'
        },
        headerLeft: () =>
          width >= 768 ? (
            <></>
          ) : (
            <View style={[{ marginLeft: 16 }]}>
              <Pressable
                style={{ marginRight: 12 }}
                onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
              >
                <SimpleLineIcons name="menu" size={22} color="white" />
              </Pressable>
            </View>
          ),
        headerRight: () => (
          <View style={{ flexDirection: 'row' }}>
            <TouchableOpacity
              style={{ marginRight: 12 }}
              onPress={openSearchHandler}
            >
              <AntDesign name="search1" size={24} color="white" />
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.cartIcon]}
              onPress={openCartHandler}
            >
              <AntDesign name="shoppingcart" size={24} color="white" />
              {cartItems.length > 0 && (
                <Text style={[styles.cartIconText]}>
                  {getItemSubtotal(cartItems)}
                </Text>
              )}
            </TouchableOpacity>
          </View>
        ),
        headerTintColor: '#fff',
        drawerStyle: {
          backgroundColor: '#FFFFFF'
        },
        drawerType: width >= 768 ? 'permanent' : 'front',
        drawerLabelStyle: {
          fontFamily: 'notosanstc-medium',
          fontSize: 14,
          lineHeight: 19,
          // paddingLeft: 8,
          paddingTop: 4,
          paddingBottom: 4,
          marginLeft: -20
        },
        drawerActiveBackgroundColor: '#f5efe0',
        drawerActiveTintColor: 'rgba(0,0,0,1)',
        drawerInactiveBackgroundColor: '#fff',
        drawerInactiveTintColor: 'rgba(0,0,0,.4)',
        drawerItemStyle: {
          borderRadius: 0,
          width: '100%',
          marginLeft: 0,
          marginTop: 0,
          marginBottom: 0
        }
      })}
    >
      <Drawer.Screen
        options={{
          drawerLabel: 'Home',
          title: 'Home',
          headerTitleAlign: 'center',
          headerTitle: () => (
            <Image
              source={require('../assets/logos/shot-logo-light-sm-2x.png')}
              style={styles.shortLogoHeader}
            />
          ),
          drawerIcon: ({ color }) => (
            <View style={{ marginLeft: 8 }}>
              <AntDesign name="home" size={20} color={color} />
            </View>
          )
        }}
        name="Home"
        component={HomeScreen}
      />
      {userInfo && (
        <Drawer.Screen
          options={{
            drawerLabel: 'Profile',
            title: 'Profile',
            drawerIcon: ({ color }) => (
              <View style={{ marginLeft: 8 }}>
                <AntDesign name="user" size={20} color={color} />
              </View>
            )
          }}
          name="Profile"
          component={ProfileScreen}
        />
      )}
      {userInfo && (
        <Drawer.Screen
          options={{
            drawerLabel: 'Orders',
            title: 'My Orders',
            drawerIcon: ({ color }) => (
              <View style={{ marginLeft: 8 }}>
                <Ionicons name="ios-receipt-outline" size={20} color={color} />
              </View>
            )
          }}
          name="OrderList"
          component={OrderListScreen}
          initialParams={{ load: true }}
        />
      )}
      {userInfo && (
        <Drawer.Screen
          options={{
            drawerLabel: 'Wishlist',
            title: 'Wishlist',
            drawerIcon: ({ color }) => (
              <View style={{ marginLeft: 8 }}>
                <AntDesign name="heart" size={20} color={color} />
              </View>
            )
          }}
          name="Wishlist"
          component={WishlistScreen}
        />
      )}
      {userInfo && (
        <Drawer.Screen
          options={{
            drawerLabel: 'Reviews',
            title: 'My Reviews',
            drawerIcon: ({ color }) => (
              <View style={{ marginLeft: 8 }}>
                <FontAwesome name="star-half-o" size={21} color={color} />
              </View>
            )
          }}
          name="MyReviews"
          component={MyReviewsScreen}
        />
      )}
      {false && (
        <Drawer.Screen
          options={{
            drawerLabel: 'Settings',
            title: 'Settings',
            drawerIcon: ({ color }) => (
              <View style={{ marginLeft: 8 }}>
                <AntDesign name="setting" size={20} color={color} />
              </View>
            )
          }}
          name="Settings"
          component={SettingsScreen}
        />
      )}
    </Drawer.Navigator>
  )
}

const styles = StyleSheet.create({
  logoHeader: {
    width: 140,
    height: 34
  },
  shortLogoHeader: {
    width: 23,
    height: 40
  },
  cartIcon: {
    marginRight: 16,
    flexDirection: 'row'
  },
  cartIconText: {
    color: '#fff',
    marginLeft: 6,
    fontFamily: 'notosanstc-bold',
    fontSize: 14,
    lineHeight: 21,
    position: 'relative',
    top: 2
  }
})

export default DrawerNavigator
