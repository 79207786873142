import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import PropTypes from 'prop-types'
import { MaterialIcons } from '@expo/vector-icons'

const Rating = ({ rating = 0, color, size }) => {
  return (
    <View style={styles.rating}>
      <MaterialIcons
        name={
          rating >= 1 ? 'star' : rating >= 0.5 ? 'star-half' : 'star-outline'
        }
        size={size}
        color={color}
      />
      <MaterialIcons
        name={
          rating >= 2 ? 'star' : rating >= 1.5 ? 'star-half' : 'star-outline'
        }
        size={size}
        color={color}
      />
      <MaterialIcons
        name={
          rating >= 3 ? 'star' : rating >= 2.5 ? 'star-half' : 'star-outline'
        }
        size={size}
        color={color}
      />
      <MaterialIcons
        name={
          rating >= 4 ? 'star' : rating >= 3.5 ? 'star-half' : 'star-outline'
        }
        size={size}
        color={color}
      />
      <MaterialIcons
        name={
          rating >= 5 ? 'star' : rating >= 4.5 ? 'star-half' : 'star-outline'
        }
        size={size}
        color={color}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  rating: {
    flexDirection: 'row'
  }
})

Rating.defaultProps = {
  color: '#ffc107',
  size: 18
}

Rating.propTypes = {
  rating: PropTypes.number.isRequired,
  color: PropTypes.string,
  size: PropTypes.number
}

export default Rating
