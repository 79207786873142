import { StyleSheet, Text, View, Image, Dimensions } from 'react-native'
import React from 'react'
import { SwiperFlatList } from 'react-native-swiper-flatlist'

// constants
const DEVICE_WIDTH = Dimensions.get('window').width

const ProductSlider = ({ images }) => {
  return (
    <View style={styles.slidesWrapper}>
      <SwiperFlatList
        index={0}
        autoplayDelay={1}
        showPagination
        paginationStyle={styles.slidesControl}
        paginationStyleItem={styles.dot}
        paginationStyleItemInactive={[styles.inactive]}
        paginationStyleItemActive={[styles.active]}
        data={images}
        renderItem={({ item }) => (
          <View style={styles.slide}>
            <Image
              source={{
                uri: item.imageurl
              }}
              style={styles.slideImg}
            />
          </View>
        )}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  slidesWrapper: {
    position: 'relative',
    backgroundColor: '#fff',
    width: DEVICE_WIDTH,
    marginBottom: 65,
    paddingBottom: 15,
    marginTop: 15
  },
  slide: {
    position: 'relative',
    width: DEVICE_WIDTH,
    justifyContent: 'center',
    alignItems: 'center'
  },
  slideImg: {
    width: 280,
    height: 280,
    marginTop: 20
  },
  slidesControl: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: -40
  },
  dot: {
    backgroundColor: 'rgba(0, 0, 0, 1)',
    borderRadius: 20,
    marginHorizontal: 7
  },
  inactive: {
    backgroundColor: 'rgba(0, 0, 0, .2)',
    width: 10,
    height: 10
  },
  active: {
    backgroundColor: 'rgba(0, 0, 0, .8)',
    width: 20,
    height: 20
  }
})

export default ProductSlider
