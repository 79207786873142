import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { AntDesign } from '@expo/vector-icons'

// styles
import globalStyles from '../../styles/global'

const Steps = ({ step }) => {
  return (
    <View style={[styles.nav]}>
      <View
        style={[
          styles.navItem,
          styles.navItemFirst,
          step === 1 && styles.navItemActive
        ]}
      >
        {step === 1 ? (
          <Text
            style={[
              styles.navItemText,
              globalStyles.whiteTextColor,
              globalStyles.text
            ]}
          >
            1.
          </Text>
        ) : step > 1 ? (
          <AntDesign name="checkcircle" size={18} color={'#df143c'} />
        ) : (
          <Text
            style={[styles.navItemText, globalStyles.text, globalStyles.text]}
          >
            1.
          </Text>
        )}
        <Text
          style={[
            styles.stepGap,
            globalStyles.text,
            step === 1 && styles.navItemActiveText
          ]}
        >
          Sign In
        </Text>
      </View>
      <View style={[styles.navItem, step === 2 && styles.navItemActive]}>
        {step === 2 ? (
          <Text
            style={[
              styles.navItemText,
              globalStyles.whiteTextColor,
              globalStyles.text
            ]}
          >
            2.
          </Text>
        ) : step > 2 ? (
          <AntDesign name="checkcircle" size={18} color={'#df143c'} />
        ) : (
          <Text style={[styles.navItemText, globalStyles.text]}>2.</Text>
        )}
        <Text
          style={[
            styles.stepGap,
            globalStyles.text,
            step === 2 && styles.navItemActiveText
          ]}
        >
          Shipping
        </Text>
      </View>
      <View style={[styles.navItem, step === 3 && styles.navItemActive]}>
        {step === 3 ? (
          <Text
            style={[
              styles.navItemText,
              globalStyles.whiteTextColor,
              globalStyles.text
            ]}
          >
            3.
          </Text>
        ) : step > 3 ? (
          <AntDesign name="checkcircle" size={18} color={'#df143c'} />
        ) : (
          <Text style={[styles.navItemText, globalStyles.text]}>3.</Text>
        )}
        <Text
          style={[
            styles.stepGap,
            globalStyles.text,
            step === 3 && styles.navItemActiveText
          ]}
        >
          Payment
        </Text>
      </View>
      <View style={[styles.navItem, step === 4 && styles.navItemActive]}>
        {step === 4 ? (
          <Text
            style={[
              styles.navItemText,
              globalStyles.whiteTextColor,
              globalStyles.text
            ]}
          >
            4.
          </Text>
        ) : step > 4 ? (
          <AntDesign name="checkcircle" size={18} color={'#df143c'} />
        ) : (
          <Text style={[styles.navItemText, globalStyles.text]}>4.</Text>
        )}
        <Text
          style={[
            styles.stepGap,
            globalStyles.text,
            step === 4 && styles.navItemActiveText
          ]}
        >
          Order
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  nav: { marginTop: -16, marginLeft: -16, marginRight: -16, marginBottom: 16 },
  navItem: {
    paddingHorizontal: 16,
    paddingVertical: 14,
    backgroundColor: '#f8f9fa',
    // backgroundColor: '#f5efe0',
    // backgroundColor: '#fff',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottomWidth: 0,
    borderColor: '#fff',
    marginTop: -1
  },
  navItemText: {
    fontFamily: 'notosanstc-regular',
    fontSize: 14,
    lineHeight: 19,
    color: '#000'
  },
  navItemFirst: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
  },
  navItemActive: {
    backgroundColor: '#df143c'
  },
  navItemActiveText: {
    fontFamily: 'notosanstc-medium',
    fontSize: 14,
    lineHeight: 19,
    color: '#fff'
  },
  stepGap: {
    marginLeft: 7
  },
  navItemSeparator: {
    borderBottomWidth: 1,
    borderColor: '#f8f9fa'
  }
})

export default Steps
