import { StyleSheet, Text, View, ScrollView, Image } from 'react-native'
import React from 'react'
import { useNavigation } from '@react-navigation/native'

// components
import FlatButton from '../components/Button'

// styles
import globalStyles from '../styles/global'

const SettingsScreen = () => {
  const navigation = useNavigation()

  return (
    <ScrollView style={styles.container}>
      <View
        style={[globalStyles.secondaryBgColor, globalStyles.containerBand]}
      ></View>
      <View style={[globalStyles.containerOverlap]}>
        <View style={[globalStyles.containerOverlapInner]}>
          <View style={styles.innerContainer} behavior="padding">
            <View style={{ marginVertical: 10 }}></View>
            <View style={{ marginVertical: 20 }}>
              <Text
                style={[
                  globalStyles.text,
                  globalStyles.fwLight,
                  globalStyles.fs18
                ]}
              >
                Placeholder
              </Text>
            </View>
          </View>
        </View>
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  innerContainer: {
    marginVertical: 0,
    padding: 16,
    alignItems: 'center'
  }
})

export default SettingsScreen
