import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { AntDesign } from '@expo/vector-icons'

const AlertIcon = ({ type }) => {
  let iconName = ''
  let marginRight = 10

  switch (type) {
    case 'success':
      iconName = 'checkcircle'
      marginRight = 3
      break
    case 'danger':
      iconName = 'closecircleo'
      break
    case 'warning':
      iconName = 'warning'
      break
    case 'info':
      iconName = 'exclamationcircleo'
      break
    default:
      break
  }

  if (!iconName) return <View></View>

  return (
    <View style={{ marginRight }}>
      <AntDesign name={iconName} size={20} color="white" />
    </View>
  )
}

const styles = StyleSheet.create({})

export default AlertIcon
