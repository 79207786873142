import axios from 'axios'
// import { toast } from 'react-toastify'

import {
  USER_ADD_FAIL,
  USER_ADD_REQUEST,
  USER_ADD_SUCCESS,
  USER_CONFIRM_REGISTRATION_FAIL,
  USER_CONFIRM_REGISTRATION_REQUEST,
  USER_CONFIRM_REGISTRATION_SUCCESS,
  USER_CONFIRM_REGISTRATION_VALID_FAIL,
  USER_CONFIRM_REGISTRATION_VALID_REQUEST,
  USER_CONFIRM_REGISTRATION_VALID_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCESS,
  USER_EDIT_FAIL,
  USER_EDIT_REQUEST,
  USER_EDIT_RESET,
  USER_EDIT_SUCCESS,
  USER_FORGOT_PASSWORD_FAIL,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REMOVE_FAIL,
  USER_REMOVE_REQUEST,
  USER_REMOVE_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_VALID_FAIL,
  USER_RESET_PASSWORD_VALID_REQUEST,
  USER_RESET_PASSWORD_VALID_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_PASSWORD_FAIL,
  USER_UPDATE_PASSWORD_REQUEST,
  USER_UPDATE_PASSWORD_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_RESET,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_REQUEST,
  USER_UPDATE_RESET,
  USER_UPDATE_SUCCESS
} from '../constants/userConstants'

// config
import { apiBaseUrl } from '../../config'

// actions
import { resetCart } from './cartActions'

// local storage
import { setLocalStorage, removeLocalStorage } from '../../storage'

export const login = (identifier, password, toast) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST
    })

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const { data } = await axios.post(
      `${apiBaseUrl}/api/users/login`,
      {
        identifier,
        password
      },
      config
    )

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data.data
    })

    // toast.dismiss()
    // toast.success(data.message)
    toast.hideAll()
    toast.show(data.message, { type: 'success' })

    // localStorage.setItem('userInfo', JSON.stringify(data.data))
    setLocalStorage('userInfo', data.data)
  } catch (error) {
    const errorMsg =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: errorMsg
    })

    // toast.dismiss()
    // toast.error(errorMsg, { autoClose: 20000 })
    toast.hideAll()
    toast.show(errorMsg, { type: 'danger', duration: 5000 })
  }
}

export const logout = (toast) => async (dispatch) => {
  dispatch({ type: USER_LOGOUT })
  dispatch({ type: USER_DETAILS_RESET })

  // localStorage.removeItem('userInfo')
  removeLocalStorage('userInfo')

  // rest the cart
  dispatch(resetCart())

  // toast.dismiss()
  // toast.success("You're successfully logged out")
  toast.hideAll()
  toast.show("You're successfully logged out", {
    type: 'success'
  })
}

export const register =
  (username, email, password, confirmPassword, reToken, navigation, toast) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_REGISTER_REQUEST
      })

      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      const { data } = await axios.post(
        `${apiBaseUrl}/api/users/register`,
        {
          username,
          email,
          password,
          confirmPassword,
          reToken
        },
        config
      )

      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data.data
      })

      // dispatch({
      //   type: USER_LOGIN_SUCCESS,
      //   payload: data.data
      // })

      // was already commented out (no storage needed)
      // localStorage.setItem('userInfo', JSON.stringify(data.data))

      // toast.dismiss()
      // toast.info(data.message, { autoClose: false })
      // navigate('/')
      toast.hideAll()
      toast.show(data.message, { type: 'success' })
      navigation.navigate('Home', {})
    } catch (error) {
      const errorMsg =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message

      dispatch({
        type: USER_REGISTER_FAIL,
        payload: errorMsg
      })

      // toast.dismiss()
      // toast.error(errorMsg, { autoClose: 20000 })
      toast.hideAll()
      toast.show(errorMsg, { type: 'danger', duration: 5000 })
    }
  }

export const unsafeRegister =
  (username, email, password, confirmPassword, navigation, toast) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_REGISTER_REQUEST
      })

      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      const { data } = await axios.post(
        `${apiBaseUrl}/api/users/unsafe-register`,
        {
          username,
          email,
          password,
          confirmPassword
        },
        config
      )

      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data.data
      })

      // dispatch({
      //   type: USER_LOGIN_SUCCESS,
      //   payload: data.data
      // })

      // was already commented out (no storage needed)
      // localStorage.setItem('userInfo', JSON.stringify(data.data))

      // toast.dismiss()
      // toast.info(data.message, { autoClose: false })
      // navigate('/')
      toast.hideAll()
      toast.show(data.message, { type: 'info', duration: 20000 })
      navigation.navigate('Home', {})
    } catch (error) {
      const errorMsg =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message

      dispatch({
        type: USER_REGISTER_FAIL,
        payload: errorMsg
      })

      // toast.dismiss()
      // toast.error(errorMsg, { autoClose: 20000 })
      toast.hideAll()
      toast.show(errorMsg, {
        type: 'danger',
        duration: 5000
      })
    }
  }

export const getUserDetails = (toast) => async (dispatch, getState) => {
  dispatch({ type: USER_UPDATE_PROFILE_RESET })

  try {
    dispatch({
      type: USER_DETAILS_REQUEST
    })

    const {
      userLogin: { userInfo }
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.get(`${apiBaseUrl}/api/users/profile`, config)

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data.data
    })

    // was already commented out (no toast needed)
    // toast.dismiss()
    // toast.success(data.message)
  } catch (error) {
    const errorMsg =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message

    dispatch({
      type: USER_DETAILS_FAIL,
      payload: errorMsg
    })

    // was already commented out (no toast needed)
    // toast.dismiss()
    // toast.error(errorMsg)
  }
}

export const updateUserProfile =
  (userFormData, toast) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_UPDATE_PROFILE_REQUEST
      })

      const {
        userLogin: { userInfo }
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.token}`
        }
      }

      const { data } = await axios.put(
        `${apiBaseUrl}/api/users/profile`,
        userFormData,
        config
      )

      dispatch({
        type: USER_UPDATE_PROFILE_SUCCESS,
        payload: data.data
      })

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data.data
      })

      // toast.dismiss()
      // toast.success(data.message)
      toast.hideAll()
      toast.show(data.message, { type: 'success' })

      // localStorage.setItem('userInfo', JSON.stringify(data.data))
      setLocalStorage('userInfo', data.data)
    } catch (error) {
      const errorMsg =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message

      dispatch({
        type: USER_UPDATE_PROFILE_FAIL,
        payload: errorMsg
      })

      // toast.dismiss()
      // toast.error(errorMsg, { autoClose: 20000 })
      toast.hideAll()
      toast.show(errorMsg, { type: 'danger', duration: 5000 })
    }
  }

export const updateUserPassword =
  (passwordData, navigation, toast) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_UPDATE_PASSWORD_REQUEST
      })

      const {
        userLogin: { userInfo }
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`
        }
      }

      const { data } = await axios.put(
        `${apiBaseUrl}/api/users/password`,
        passwordData,
        config
      )

      dispatch({
        type: USER_UPDATE_PASSWORD_SUCCESS,
        payload: data
      })

      // toast.dismiss()
      // toast.success(data.message)
      // navigate('/account')
      toast.hideAll()
      toast.show(data.message, { type: 'success' })
      navigation.navigate('Profile', {})
    } catch (error) {
      const errorMsg =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message

      dispatch({
        type: USER_UPDATE_PASSWORD_FAIL,
        payload: errorMsg
      })

      // toast.dismiss()
      // toast.error(errorMsg, { autoClose: 20000 })
      toast.hideAll()
      toast.show(errorMsg, { type: 'danger', duration: 5000 })
    }
  }

export const forgotUserPassword =
  (identifier, navigation, toast) => async (dispatch) => {
    try {
      dispatch({
        type: USER_FORGOT_PASSWORD_REQUEST
      })

      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      const { data } = await axios.post(
        `${apiBaseUrl}/api/users/forgot-password`,
        { identifier },
        config
      )

      dispatch({
        type: USER_FORGOT_PASSWORD_SUCCESS,
        payload: data
      })

      // toast.dismiss()
      // toast.info(data.message, { autoClose: false })
      // navigate('/')
      toast.hideAll()
      toast.show(data.message, { type: 'info', duration: 20000 })
      navigation.navigate('Home', {})
    } catch (error) {
      const errorMsg =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      dispatch({
        type: USER_FORGOT_PASSWORD_FAIL,
        payload: errorMsg
      })

      // toast.dismiss()
      // toast.error(errorMsg, { autoClose: 20000 })
      toast.hideAll()
      toast.show(errorMsg, { type: 'danger', duration: 5000 })
    }
  }

export const resetPassword =
  (token, newPassword, confirmNewPassword, navigation, toast) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_RESET_PASSWORD_REQUEST
      })

      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      const { data } = await axios.post(
        `${apiBaseUrl}/api/users/reset-password`,
        {
          token,
          newPassword,
          confirmNewPassword
        },
        config
      )

      dispatch({
        type: USER_RESET_PASSWORD_SUCCESS,
        payload: data.data
      })

      // toast.dismiss()
      // toast.success(data.message)
      // navigate('/login')
      toast.hideAll()
      toast.show(data.message, { type: 'success' })
      navigation.navigate('Login', {})
    } catch (error) {
      const errorMsg =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message

      dispatch({
        type: USER_RESET_PASSWORD_FAIL,
        payload: errorMsg
      })

      // toast.dismiss()
      // toast.error(errorMsg, { autoClose: 20000 })
      toast.hideAll()
      toast.show(errorMsg, { type: 'danger', duration: 5000 })
    }
  }

export const confirmRegistration =
  (token, navigation, toast) => async (dispatch) => {
    try {
      dispatch({
        type: USER_CONFIRM_REGISTRATION_REQUEST
      })

      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      const { data } = await axios.get(
        `${apiBaseUrl}/api/users/confirm-registration/${token}`,
        config
      )

      dispatch({
        type: USER_CONFIRM_REGISTRATION_SUCCESS,
        payload: data.data
      })

      // toast.dismiss()
      // toast.success(data.message)
      // navigate('/login')
      toast.hideAll()
      toast.show(data.message, { type: 'success' })
      navigation.navigate('Login', {})
    } catch (error) {
      const errorMsg =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      dispatch({
        type: USER_CONFIRM_REGISTRATION_FAIL,
        payload: errorMsg
      })

      // toast.dismiss()
      // toast.error(errorMsg, { autoClose: 20000 })
      toast.hideAll()
      toast.show(errorMsg, { type: 'danger', duration: 5000 })
    }
  }

export const resetPasswordValid = (token, toast) => async (dispatch) => {
  try {
    dispatch({
      type: USER_RESET_PASSWORD_VALID_REQUEST
    })

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const { data } = await axios.get(
      `${apiBaseUrl}/api/users/reset-password-valid/${token}`,
      config
    )

    dispatch({
      type: USER_RESET_PASSWORD_VALID_SUCCESS,
      payload: data.data
    })

    // was already commented out (no toast needed)
    // toast.dismiss()
    // toast.success(data.message)
  } catch (error) {
    const errorMsg =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: USER_RESET_PASSWORD_VALID_FAIL,
      payload: errorMsg
    })

    // was already commented out (no toast needed)
    // toast.dismiss()
    // toast.error(errorMsg, { autoClose: 20000 })
  }
}

export const confirmRegistationValid = (token, toast) => async (dispatch) => {
  try {
    dispatch({
      type: USER_CONFIRM_REGISTRATION_VALID_REQUEST
    })

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const { data } = await axios.get(
      `${apiBaseUrl}/api/users/confirm-registration-valid/${token}`,
      config
    )

    dispatch({
      type: USER_CONFIRM_REGISTRATION_VALID_SUCCESS,
      payload: data.data
    })

    // was already commented out (no toast needed)
    // toast.dismiss()
    // toast.success(data.message)
  } catch (error) {
    const errorMsg =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: USER_CONFIRM_REGISTRATION_VALID_FAIL,
      payload: errorMsg
    })

    // was already commented out (no toast needed)
    // toast.dismiss()
    // toast.error(errorMsg, { autoClose: 20000 })
  }
}

export const listUsers =
  (perPage, page, term, toast) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_LIST_REQUEST
      })

      const {
        userLogin: { userInfo }
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`
        }
      }

      const { data } = await axios.get(
        `${apiBaseUrl}/api/users/${perPage}/${page}/${term}`,
        config
      )

      dispatch({
        type: USER_LIST_SUCCESS,
        payload: data
      })

      // was already commented out (no toast needed)
      // toast.dismiss()
      // toast.success(data.message)
    } catch (error) {
      const errorMsg =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message

      dispatch({
        type: USER_LIST_FAIL,
        payload: errorMsg
      })

      // was already commented out (no toast needed)
      // toast.dismiss()
      // toast.error(errorMsg)
    }
  }

export const removeUser = (id, toast) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_REMOVE_REQUEST
    })

    const {
      userLogin: { userInfo }
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.delete(`${apiBaseUrl}/api/users/${id}`, config)

    dispatch({
      type: USER_REMOVE_SUCCESS
    })

    // toast.dismiss()
    // toast.success(data.message)
    toast.hideAll()
    toast.show(data.message, { type: 'success' })
  } catch (error) {
    const errorMsg =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message

    dispatch({
      type: USER_REMOVE_FAIL,
      payload: errorMsg
    })

    // toast.dismiss()
    // toast.error(errorMsg)
    toast.hideAll()
    toast.show(errorMsg, { type: 'danger', duration: 5000 })
  }
}

export const addUser = (user, toast) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_ADD_REQUEST
    })

    const {
      userLogin: { userInfo }
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.post(
      `${apiBaseUrl}/api/users/add`,
      user,
      config
    )

    dispatch({
      type: USER_ADD_SUCCESS,
      payload: data.data
    })

    // toast.dismiss()
    // toast.info(data.message, { autoClose: false })
    toast.hideAll()
    toast.show(data.message, { type: 'info' })
  } catch (error) {
    const errorMsg =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message

    dispatch({
      type: USER_ADD_FAIL,
      payload: errorMsg
    })

    // toast.dismiss()
    // toast.error(errorMsg, { autoClose: 20000 })
    toast.hideAll()
    toast.show(errorMsg, { type: 'danger', duration: 5000 })
  }
}

export const editUser = (id, toast) => async (dispatch, getState) => {
  dispatch({ type: USER_EDIT_RESET })

  try {
    dispatch({
      type: USER_EDIT_REQUEST
    })

    const {
      userLogin: { userInfo }
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.get(`${apiBaseUrl}/api/users/${id}`, config)

    dispatch({
      type: USER_EDIT_SUCCESS,
      payload: data.data
    })

    // was already commented out (no toast needed)
    // toast.dismiss()
    // toast.success(data.message)
  } catch (error) {
    const errorMsg =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message

    dispatch({
      type: USER_EDIT_FAIL,
      payload: errorMsg
    })

    // was already commented out (no toast needed)
    // toast.dismiss()
    // toast.error(errorMsg)
  }
}

export const updateUser =
  (id, userFormData, toast) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_UPDATE_RESET
      })

      dispatch({
        type: USER_UPDATE_REQUEST
      })

      const {
        userLogin: { userInfo }
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.token}`
        }
      }

      const { data } = await axios.put(
        `${apiBaseUrl}/api/users/${id}`,
        userFormData,
        config
      )

      dispatch({
        type: USER_UPDATE_SUCCESS,
        payload: data.data
      })

      // toast.dismiss()
      // toast.success(data.message)
      toast.hideAll()
      toast.show(data.message, { type: 'success' })
    } catch (error) {
      const errorMsg =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message

      dispatch({
        type: USER_UPDATE_FAIL,
        payload: errorMsg
      })

      // toast.dismiss()
      // toast.error(errorMsg, { autoClose: 20000 })
      toast.hideAll()
      toast.show(errorMsg, { type: 'danger', duration: 5000 })
    }
  }
