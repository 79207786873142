import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity
} from 'react-native'
import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { useToast } from 'react-native-toast-notifications'
import { Ionicons } from '@expo/vector-icons'
import { AntDesign } from '@expo/vector-icons'

import moment from 'moment'

// styles
import globalStyles from '../styles/global'

// utils
import { currencyFormatter } from '../utils'

// config
import { orderListPerPage } from '../config'

// components
import NotFound from '../components/NotFound'

// skeletons
import OrderListSkeleton from './skeletons/OrderListSkeleton'
import { listMyOrders } from '../redux/actions/orderActions'

const OrderList = ({ load, isFocused }) => {
  const [orders, setOrders] = useState([])
  const [firstLoad, setFirstLoad] = useState(load)

  const navigation = useNavigation()
  const dispatch = useDispatch()
  const toast = useToast()

  // TODO: make this dynamic later (it's hardcoded so far)
  const page = 1

  const orderListMyOrders = useSelector((state) => state.orderListMyOrders)
  const { orders: myOrders, loading, error } = orderListMyOrders

  const viewOrderDetailsHandler = (orderId) => {
    navigation.navigate('OrderDetails', { orderId })
  }

  const toggleDrawerHandler = (index) => {
    const updatedOrders = orders.map((obj, i) => {
      if (i === index) {
        return { ...obj, openDrawer: !obj.openDrawer }
      } else {
        return { ...obj, openDrawer: false }
      }
    })

    setOrders(updatedOrders)
  }

  const _formatOrders = useCallback(async () => {
    if (myOrders?.data) {
      const formattedOrders = myOrders.data.map((order, index) => {
        return { openDrawer: index === 0 ? true : false, data: order }
      })
      setOrders(formattedOrders)
    }
  }, [myOrders])

  useEffect(() => {
    _formatOrders()
  }, [_formatOrders])

  useEffect(() => {
    // if (firstLoad) {
    //   dispatch(listMyOrders(orderListPerPage, page, toast))
    //   setFirstLoad(false)
    // }
    dispatch(listMyOrders(orderListPerPage, page, toast))
  }, [dispatch, page, isFocused])

  if (loading) return <OrderListSkeleton />

  if (!loading && error)
    return (
      <NotFound
        message="No orders found"
        actionLabel="Start Shopping"
        action={() => {
          navigation.navigate('Home', {})
        }}
      />
    )

  return (
    <View style={[globalStyles.card, { padding: 0 }]}>
      {!loading &&
        orders &&
        orders.map((order, index) => (
          <View
            style={[
              globalStyles.drawer,
              orders.length === index + 1 && globalStyles.lastDrawer
            ]}
            key={index}
          >
            {orders.length === 1 ? (
              <View style={[globalStyles.drawerHeader]}>
                <Text
                  style={[
                    globalStyles.text,
                    globalStyles.fwLight,
                    globalStyles.fs18
                  ]}
                >
                  Order #{order.data._id.substring(0, 9).toUpperCase()}
                </Text>
              </View>
            ) : (
              <TouchableOpacity
                style={[globalStyles.drawerHeader]}
                onPress={() => toggleDrawerHandler(index)}
              >
                <Text
                  style={[
                    globalStyles.text,
                    globalStyles.fwLight,
                    globalStyles.fs18
                  ]}
                >
                  Order #{order.data._id.substring(0, 9).toUpperCase()}
                </Text>
                <Ionicons
                  name={`md-chevron-${order.openDrawer ? 'up' : 'down'}-sharp`}
                  size={22}
                  color="#333"
                />
              </TouchableOpacity>
            )}

            {(order.openDrawer || orders.length === 1) && (
              <View style={[globalStyles.drawerBody]}>
                <View style={[globalStyles.drawerBodyInner]}>
                  <View style={[globalStyles.drawerBodyItem]}>
                    <Text style={[globalStyles.drawerBodyItemTitle]}>
                      Date:
                    </Text>
                    <Text style={[globalStyles.drawerBodyItemDesc]}>
                      {moment(order.data.createdAt).format('L')}
                    </Text>
                  </View>
                  <View style={[globalStyles.drawerBodyItem]}>
                    <Text style={[globalStyles.drawerBodyItemTitle]}>
                      Total:
                    </Text>
                    <Text style={[globalStyles.drawerBodyItemDesc]}>
                      {currencyFormatter(order.data.totalPrice)}
                    </Text>
                  </View>
                  <View style={[globalStyles.drawerBodyItem]}>
                    <Text style={[globalStyles.drawerBodyItemTitle]}>
                      Paid:
                    </Text>
                    <Text
                      style={[
                        globalStyles.drawerBodyItemDesc,
                        globalStyles.mr2
                      ]}
                    >
                      {order.data.paidAt
                        ? `Yes on ${moment(order.data.paidAt).calendar()}`
                        : 'Not Yet'}
                    </Text>
                    <AntDesign
                      name={
                        order.data.paidAt ? 'checkcircle' : 'exclamationcircle'
                      }
                      size={18}
                      color={
                        order.data.paidAt
                          ? 'rgba(7, 188, 12, .75)'
                          : 'rgba(223, 20, 60, .75)'
                      }
                    />
                  </View>
                  <View style={[globalStyles.drawerBodyItem]}>
                    <Text style={[globalStyles.drawerBodyItemTitle]}>
                      Delivered:
                    </Text>
                    <Text
                      style={[
                        globalStyles.drawerBodyItemDesc,
                        globalStyles.mr2
                      ]}
                    >
                      {order.data.deliveredAt
                        ? `Yes on ${moment(order.data.deliveredAt).calendar()}`
                        : 'Not Yet'}
                    </Text>
                    <AntDesign
                      name={
                        order.data.deliveredAt
                          ? 'checkcircle'
                          : 'exclamationcircle'
                      }
                      size={18}
                      color={
                        order.data.deliveredAt
                          ? 'rgba(7, 188, 12, .75)'
                          : 'rgba(223, 20, 60, .75)'
                      }
                    />
                  </View>
                  <TouchableOpacity
                    style={[globalStyles.vStartItems, globalStyles.mt2]}
                    onPress={() => viewOrderDetailsHandler(order.data._id)}
                  >
                    <Text
                      style={[
                        globalStyles.drawerBodyItemLink,
                        globalStyles.fs13,
                        globalStyles.mb1
                      ]}
                    >
                      View Details
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            )}
          </View>
        ))}
    </View>
  )
}

const styles = StyleSheet.create({})

export default OrderList
