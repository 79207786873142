import { View, Text, StyleSheet } from 'react-native'
import React, { useState, useEffect } from 'react'
import { useIsFocused } from '@react-navigation/native'

// components
import FeaturedProducts from '../components/FeaturedProducts'
import ProductList from '../components/ProductList'
import ProductFilter from '../components/ProductFilter'

const HomeScreen = () => {
  const isFocused = useIsFocused()
  const [filter, setFilter] = useState({})

  return (
    <View style={styles.container}>
      <FeaturedProducts isFocused={isFocused} />

      <ProductFilter filter={filter} setFilter={setFilter} />

      <ProductList
        term=""
        sort={filter.value ? filter.value : '*'}
        isFocused={isFocused}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  }
})

export default HomeScreen
