import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TextInput,
  TouchableOpacity
} from 'react-native'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { useToast } from 'react-native-toast-notifications'
import { Ionicons } from '@expo/vector-icons'

// styles
import globalStyles from '../styles/global'

// components
import FlatButton from '../components/Button'

// actions
import { updateUserPassword } from '../redux/actions/userActions'

const ChangePasswordScreen = () => {
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [isCurrentPasswordHidden, setIsCurrentPasswordHidden] = useState(true)
  const [isNewPasswordHidden, setIsNewPasswordHidden] = useState(true)
  const [isConfirmNewPasswordHidden, setIsConfirmNewPasswordHidden] =
    useState(true)

  const dispatch = useDispatch()
  const navigation = useNavigation()
  const toast = useToast()

  const userUpdatePassword = useSelector((state) => state.userUpdatePassword)
  const { loading, error, userInfo, success } = userUpdatePassword

  const changePasswordHandler = () => {
    dispatch(
      updateUserPassword(
        { currentPassword, newPassword, confirmNewPassword },
        navigation,
        toast
      )
    )
  }

  return (
    <ScrollView style={styles.container}>
      <View style={[globalStyles.secondaryBgColor, globalStyles.containerBand]}>
        {false && (
          <View style={[globalStyles.textOverlap]}>
            <View>
              <Text
                style={[
                  globalStyles.text,
                  globalStyles.fwMedium,
                  globalStyles.fs20,
                  globalStyles.blackTextColor,
                  globalStyles.mb1
                ]}
              >
                Forgot Password
              </Text>
            </View>
          </View>
        )}
      </View>
      <View style={[globalStyles.containerOverlap]}>
        <View style={[globalStyles.containerOverlapInner]}>
          <View style={styles.inputContainer} behavior="padding">
            <View style={[styles.inputLabelWrapper, { marginTop: -8 }]}>
              <Text style={[styles.inputLabel]}>Current Password</Text>
              <Text style={styles.inputLabelNote}>(required)</Text>
            </View>
            <View style={styles.passwordField}>
              <TextInput
                placeholder=""
                value={currentPassword}
                onChangeText={(text) => setCurrentPassword(text)}
                style={styles.input}
                secureTextEntry={isCurrentPasswordHidden}
                autoCapitalize="none"
              />
              <TouchableOpacity
                style={styles.eyeIcon}
                onPress={() => {
                  setIsCurrentPasswordHidden(!isCurrentPasswordHidden)
                }}
              >
                <Ionicons
                  name={`${
                    isCurrentPasswordHidden
                      ? 'ios-eye-outline'
                      : 'ios-eye-off-outline'
                  }`}
                  size={24}
                  color="rgba(0,0,0,.25)"
                />
              </TouchableOpacity>
            </View>
            <View style={[styles.inputLabelWrapper]}>
              <Text style={[styles.inputLabel]}>New Password</Text>
              <Text style={styles.inputLabelNote}>(required)</Text>
            </View>
            <View style={styles.passwordField}>
              <TextInput
                placeholder=""
                value={newPassword}
                onChangeText={(text) => setNewPassword(text)}
                style={styles.input}
                secureTextEntry={isNewPasswordHidden}
                autoCapitalize="none"
              />
              <Text style={styles.note}>
                Password must be at least 8 characters. And must contain
                letters, numbers, symbols (e.g. @yusuf123, Yusuf2022$).
              </Text>
              <TouchableOpacity
                style={styles.eyeIcon}
                onPress={() => {
                  setIsNewPasswordHidden(!isNewPasswordHidden)
                }}
              >
                <Ionicons
                  name={`${
                    isNewPasswordHidden
                      ? 'ios-eye-outline'
                      : 'ios-eye-off-outline'
                  }`}
                  size={24}
                  color="rgba(0,0,0,.25)"
                />
              </TouchableOpacity>
            </View>

            <View style={[styles.inputLabelWrapper]}>
              <Text style={[styles.inputLabel]}>Confirm New Password</Text>
              <Text style={styles.inputLabelNote}>(required)</Text>
            </View>
            <View style={styles.passwordField}>
              <TextInput
                placeholder=""
                value={confirmNewPassword}
                onChangeText={(text) => setConfirmNewPassword(text)}
                style={styles.input}
                secureTextEntry={isConfirmNewPasswordHidden}
                autoCapitalize="none"
              />
              <TouchableOpacity
                style={styles.eyeIcon}
                onPress={() => {
                  setIsConfirmNewPasswordHidden(!isConfirmNewPasswordHidden)
                }}
              >
                <Ionicons
                  name={`${
                    isConfirmNewPasswordHidden
                      ? 'ios-eye-outline'
                      : 'ios-eye-off-outline'
                  }`}
                  size={24}
                  color="rgba(0,0,0,.25)"
                />
              </TouchableOpacity>
            </View>

            <View style={styles.buttonContainer}>
              <FlatButton
                text="Update Password"
                transform="uppercase"
                type="primary"
                onPress={changePasswordHandler}
              />
            </View>
          </View>
        </View>
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  inputContainer: {
    marginVertical: 0,
    padding: 16
  },
  input: {
    borderWidth: 1,
    backgroundColor: '#fff',
    borderColor: '#e9ecef',
    paddingHorizontal: 16,
    height: 46,
    fontSize: 16,
    lineHeight: 21,
    borderRadius: 6,
    marginVertical: 4,
    fontFamily: 'notosanstc-medium'
  },
  inputLabelWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10
  },
  inputLabel: {
    color: '#333',
    fontFamily: 'notosanstc-medium',
    fontSize: 14,
    lineHeight: 19,
    marginBottom: 0,
    marginTop: 16,
    marginHorizontal: 1
  },
  inputLabelNote: {
    color: 'rgba(0,0,0, .7)',
    fontFamily: 'notosanstc-light',
    fontSize: 11,
    lineHeight: 16,
    marginBottom: 0,
    marginTop: 16,
    marginHorizontal: 3
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginVertical: 16
  },
  copyContainer: {
    flexDirection: 'row'
  },
  copyText: {
    color: '#333',
    fontFamily: 'notosanstc-light',
    marginHorizontal: 1
  },
  copyLink: {
    color: '#df143c',
    marginLeft: 5,
    borderBottomColor: '#df143c',
    fontFamily: 'notosanstc-bold'
  },
  note: {
    fontFamily: 'notosanstc-light',
    fontSize: 11,
    lineHeight: 16,
    color: 'rgba(0,0,0, .7)'
  },
  passwordField: {
    position: 'relative',
    marginHorizontal: 0
  },
  eyeIcon: {
    position: 'absolute',
    right: 12,
    top: 15.5
  }
})

export default ChangePasswordScreen
