import { StyleSheet, Text, View } from 'react-native'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import MasonryList from '@react-native-seoul/masonry-list'
import { useDispatch, useSelector } from 'react-redux'

// styles
import globalStyles from '../styles/global'

// config
import { myWishlistPerPage } from '../config'

// components
import CardItem from './CardItem'
import FlatButton from './Button'

// skeletons
import CardItemSkeleton from './skeletons/CardItemSkeleton'

// actions
import { wishlistProducts } from '../redux/actions/productActions'

const WishList = ({ sort, isFocused }) => {
  const [unlikedProduct, setUnlikedProduct] = useState('')
  const [latestItems, setLatestItems] = useState([])
  const [firstLoad, setFirstLoad] = useState(true)
  const [initLoad, setInitLoad] = useState(true)
  const [page, setPage] = useState(1)

  const wishlistRef = useRef()

  const dispatch = useDispatch()

  const productWishlist = useSelector((state) => state.productWishlist)
  const { products, meta, loading, error } = productWishlist

  const [refreshing, setRefreshing] = useState(false)
  const onRefresh = useCallback(() => {
    setRefreshing(true)
    setFirstLoad(true)
    setLatestItems([])
    setPage(1)

    dispatch(wishlistProducts(myWishlistPerPage, 1, sort))
    if (!loading) setRefreshing(false)
  }, [dispatch, sort, isFocused])

  const fetchMoreItems = () => {
    setFirstLoad(false)
    setPage((prevPage) => prevPage + 1)
    // console.log('page:', page)

    dispatch(wishlistProducts(myWishlistPerPage, page + 1, sort))
  }

  // console.log('latestItems:', latestItems)
  // console.log('sort:', sort)
  // console.log('products:', products)

  const _latestItems = useCallback(async () => {
    if (typeof products !== 'undefined')
      setLatestItems([...latestItems, ...products])
    setFirstLoad(false)
  }, [products, firstLoad])

  useEffect(() => {
    // console.log('**** products ****', products)
    _latestItems()

    // return () => {
    //   setLatestItems([])
    //   setFirstLoad(true)
    // }
  }, [_latestItems])

  useEffect(() => {
    if (initLoad || isFocused) {
      dispatch(wishlistProducts(myWishlistPerPage, 1, sort))
      setLatestItems([])
      // setPage(1)
    }

    return () => {
      setLatestItems([])
      setPage(1)
      setFirstLoad(true)
    }
  }, [dispatch, isFocused, unlikedProduct, initLoad, sort])

  if (loading) return <CardItemSkeleton />

  const LoadingMore = () => {
    // console.log('Page:', page)
    return (
      <>
        {!loading &&
        latestItems.length > 0 &&
        myWishlistPerPage < meta.total_products &&
        myWishlistPerPage !== 0 ? (
          <>
            {page >= meta.total_pages ? (
              <View
                style={[
                  globalStyles.vCenterItems,
                  { marginTop: 20, marginBottom: 10, opacity: 0.4 }
                ]}
              >
                <FlatButton
                  text="No More Products"
                  transform="uppercase"
                  weight="light"
                  isDisabled={true}
                />
              </View>
            ) : (
              <View style={{ marginTop: 20, marginBottom: 10 }}>
                <FlatButton
                  text="Load More Products"
                  transform="uppercase"
                  type=""
                  weight="light"
                  onPress={fetchMoreItems}
                />
              </View>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    )
  }

  return (
    <>
      {!loading && products?.length > 0 && (
        <View style={styles.gridView}>
          <MasonryList
            data={latestItems}
            keyExtractor={(item) => item._id + Math.random()}
            numColumns={2}
            showsVerticalScrollIndicator={false}
            renderItem={({ item }) => (
              <CardItem item={item} setUnlikedProduct={setUnlikedProduct} />
            )}
            refreshing={refreshing}
            onRefresh={onRefresh}
            // onEndReached={fetchMoreItems}
            // onEndReachedThreshold={0.1}
            ListFooterComponent={<LoadingMore />}
            innerRef={wishlistRef}
            onContentSizeChange={() => {
              if (page > 1) wishlistRef.current.scrollToEnd({ animated: true })
            }}
          />
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  gridView: {
    marginTop: 0,
    flex: 1,
    padding: 12
  }
})

export default WishList
