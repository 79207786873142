import AsyncStorage from '@react-native-async-storage/async-storage'

const setLocalStorage = async (key, value) => {
  try {
    await AsyncStorage.setItem(key, JSON.stringify(value))
  } catch (error) {
    console.log(error)
  }
}

const getLocalStorage = async (key) => {
  let result = null

  try {
    result = JSON.parse(await AsyncStorage.getItem(key))
  } catch (error) {
    console.log(error)
  }

  return result
}

const removeLocalStorage = async (key) => {
  try {
    await AsyncStorage.removeItem(key)
  } catch (error) {
    console.log(error)
  }
}

const clearLocalStorage = async (key) => {
  try {
    await AsyncStorage.clear(key)
  } catch (error) {
    console.log(error)
  }
}

export {
  setLocalStorage,
  getLocalStorage,
  removeLocalStorage,
  clearLocalStorage
}
