import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

const ProfileSkeleton = () => {
  return (
    <View>
      <View style={styles.edit}>
        <View style={[styles.editText]}></View>
      </View>
      <View style={styles.innerContainer} behavior="padding">
        <View style={{ alignItems: 'center' }}>
          <View style={{ marginVertical: 0, position: 'relative' }}>
            <View style={[styles.image]}></View>
          </View>
          <View style={{ marginTop: 20 }}>
            <View style={[styles.fullname]}></View>
          </View>
        </View>
        <View style={{ marginVertical: 40 }}>
          <View style={[styles.line]}></View>
          <View style={[styles.line]}></View>
          <View style={[styles.line]}></View>
          <View style={[styles.line]}></View>
          <View style={[styles.line]}></View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  innerContainer: {
    marginVertical: 0,
    padding: 16
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginVertical: 16
  },
  edit: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginHorizontal: 16,
    marginVertical: 12
  },
  editText: {
    backgroundColor: 'rgba(15, 23, 42, .1)',
    width: 80,
    padding: 6,
    borderRadius: 10,
    marginTop: 6
  },
  image: {
    backgroundColor: 'rgba(15, 23, 42, .1)',
    width: 100,
    height: 100,
    borderRadius: 100
  },
  fullname: {
    backgroundColor: 'rgba(15, 23, 42, .1)',
    width: 180,
    padding: 8,
    borderRadius: 10
  },
  line: {
    backgroundColor: 'rgba(15, 23, 42, .05)',
    width: '100%',
    padding: 6,
    borderRadius: 10,
    marginVertical: 7
  }
})

export default ProfileSkeleton
