import {
  ActivityIndicator,
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  TouchableOpacity
} from 'react-native'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useNavigation, useRoute } from '@react-navigation/native'
import MasonryList from '@react-native-seoul/masonry-list'
import { useDispatch, useSelector } from 'react-redux'

// styles
import globalStyles from '../styles/global'

// config
import { productsPerPage } from '../config'

// components
import CardItem from './CardItem'
import NotFound from '../components/NotFound'

// skeletons
import CardItemSkeleton from './skeletons/CardItemSkeleton'

// actions
import { listProducts } from '../redux/actions/productActions'
import FlatButton from './Button'

const ProductList = ({ term, sort, isFocused }) => {
  const [latestItems, setLatestItems] = useState([])
  const [firstLoad, setFirstLoad] = useState(true)
  const [firstFetch, setFirstFetch] = useState(true)
  const [initLoad, setInitLoad] = useState(true)
  const [page, setPage] = useState(1)

  const productListRef = useRef()

  const navigation = useNavigation()
  const dispatch = useDispatch()
  const route = useRoute()

  const productList = useSelector((state) => state.productList)
  const { loading, error, products, meta } = productList

  const [refreshing, setRefreshing] = useState(false)
  const onRefresh = useCallback(() => {
    setRefreshing(true)
    // setFirstFetch(true)
    setFirstLoad(true)
    setLatestItems([])
    setPage(1)

    dispatch(listProducts(productsPerPage, 1, sort, term, { page, sort }))
    if (!loading) setRefreshing(false)
  }, [dispatch, sort, term, isFocused])

  const fetchMoreItems = () => {
    // setFirstFetch(false)
    setFirstLoad(false)
    setPage((prevPage) => prevPage + 1)
    dispatch(
      listProducts(productsPerPage, page + 1, sort, term, {
        page,
        sort
      })
    )
  }

  const _latestItems = useCallback(async () => {
    if (products) setLatestItems([...latestItems, ...products])
    setFirstLoad(false)
  }, [products, firstLoad, term])

  useEffect(() => {
    _latestItems()

    return () => {
      setLatestItems([])
      setFirstLoad(true)
    }
  }, [_latestItems])

  useEffect(() => {
    if (initLoad || isFocused) {
      dispatch(listProducts(productsPerPage, 1, sort, term, { page, sort }))
      setLatestItems([])
      setPage(1)
    }

    return () => {
      setLatestItems([])
      setFirstLoad(true)
    }
  }, [dispatch, initLoad, isFocused, term, sort])

  // CONSOLE.LOG
  // console.log('latestItems:', latestItems)

  // if (loading && firstLoad) return <CardItemSkeleton />
  if (loading) return <CardItemSkeleton />

  if (!loading && products.length === 0)
    return (
      <>
        {term === '' ? (
          <View style={[globalStyles.vCenterItems, globalStyles.mt10]}>
            <Text
              style={[
                globalStyles.text,
                globalStyles.fs18,
                globalStyles.fwLight
              ]}
            >
              No items found
            </Text>
          </View>
        ) : (
          <View
            style={[
              globalStyles.containerOverlap,
              globalStyles.flexOne,
              globalStyles.mt0
            ]}
          >
            <NotFound
              message="No items found"
              actionLabel="back home"
              action={() => {
                navigation.navigate('Home', {})
              }}
            />
          </View>
        )}
      </>
    )

  // console.log('Page:', page)

  const LoadingMore = () => {
    // console.log('Page:', page)

    return (
      <>
        {!loading &&
        latestItems.length > 0 &&
        productsPerPage < meta.total_products &&
        productsPerPage !== 0 ? (
          <>
            {page >= meta.total_pages ? (
              <View
                style={[
                  globalStyles.vCenterItems,
                  { marginTop: 20, marginBottom: 10, opacity: 0.4 }
                ]}
              >
                <FlatButton
                  text="No More Products"
                  transform="uppercase"
                  weight="light"
                  isDisabled={true}
                />
              </View>
            ) : (
              <View style={{ marginTop: 20, marginBottom: 10 }}>
                <FlatButton
                  text="Load More Products"
                  transform="uppercase"
                  type=""
                  weight="light"
                  onPress={fetchMoreItems}
                />
              </View>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    )
  }

  return (
    <>
      {!loading && products.length > 0 && (
        <View style={styles.gridView}>
          <MasonryList
            data={latestItems}
            keyExtractor={(item) => item._id + Math.random()}
            numColumns={2}
            showsVerticalScrollIndicator={false}
            renderItem={({ item }) => <CardItem item={item} />}
            refreshing={refreshing}
            onRefresh={onRefresh}
            // onEndReached={fetchMoreItems}
            // onEndReachedThreshold={0.1}
            ListFooterComponent={<LoadingMore />}
            innerRef={productListRef}
            onContentSizeChange={() => {
              if (page > 1)
                productListRef.current?.scrollToEnd({ animated: true })
            }}
          />
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  gridView: {
    marginTop: 0,
    flex: 1,
    padding: 12
  }
})

export default ProductList
