// export const apiBaseUrl = 'http://192.168.1.106:3030' // local
// export const apiBaseUrl = 'https://lynashop-mobile.herokuapp.com' // staging
// export const apiBaseUrl = 'https://lynashop.herokuapp.com' // Starting November 28th, 2022, free Heroku Dynos, free Heroku Postgres, and free Heroku Data for Redis® will no longer be available.
// export const apiBaseUrl = 'https://lynashop-api.onrender.com' // Render
export const apiBaseUrl = 'https://lynashop.api.youcefdeveloper.com' // live
export const productImagePlaceholder =
  'https://cdn.youcefdeveloper.com/lynashop/demo/default-009-640x640-trans.png'
export const defaultMaleProfile =
  'https://cdn.youcefdeveloper.com/lynashop/demo/default-male-profile-200x200.jpg'
export const defaultFemaleProfile =
  'https://cdn.youcefdeveloper.com/lynashop/demo/default-female-profile-200x200.jpg'
export const productsPerPage = 8
export const orderListPerPage = 0
export const myWishlistPerPage = 8
export const shownReviews = 3
export const myReviewsPerPage = 0
