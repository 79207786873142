import {
  StyleSheet,
  Text,
  View,
  TextInput,
  TouchableOpacity
} from 'react-native'
import React, { useState, useEffect, useCallback } from 'react'
import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { useToast } from 'react-native-toast-notifications'
import { Formik } from 'formik'
import * as yup from 'yup'
import { MaterialCommunityIcons } from '@expo/vector-icons'

// styles
import globalStyles from '../../styles/global'
import FlatButton from '../Button'
import Steps from './Steps'

// utils
import { capitalizeFirstLetter } from '../../utils'

// actions
import { saveShippingAddress } from '../../redux/actions/cartActions'

// constants
const COUNTRIES = ['United States', 'Canada']

const shippingAddressSchema = yup.object({
  address: yup.string().required('Address is required').min(10),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required').min(2),
  zipCode: yup.string().required('Zip Code is required')
})

const Shipping = () => {
  const [country, setCountry] = useState(COUNTRIES[0])
  const [initialValues, setInitialValues] = useState({
    address: '',
    apt: '',
    city: '',
    state: '',
    zipCode: '',
    country: country
  })

  const navigation = useNavigation()
  const dispatch = useDispatch()
  const toast = useToast()

  const cart = useSelector((state) => state.cart)
  const { shippingAddress } = cart

  const _editShippingAddress = useCallback(async () => {
    setInitialValues((prevInitiaValues) => ({
      ...prevInitiaValues,
      address: shippingAddress.address || '',
      apt: shippingAddress.apt || '',
      city: shippingAddress.city || '',
      state: shippingAddress.state || '',
      zipCode: shippingAddress.zipCode || '',
      country: shippingAddress.country || COUNTRIES[0]
    }))
  }, [shippingAddress])

  const onSubmit = (values, actions) => {
    actions.resetForm()
    dispatch(saveShippingAddress(values, navigation, toast))
  }

  const countryHandler = (country) => {
    setCountry(country)
    setInitialValues((prevInitiaValues) => ({ ...prevInitiaValues, country }))
  }

  useEffect(() => {
    _editShippingAddress()
  }, [_editShippingAddress])

  return (
    <View style={[globalStyles.card]}>
      <Steps step={2} />
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={shippingAddressSchema}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <View style={globalStyles.inputContainer} behavior="padding">
            <View style={[globalStyles.inputLabelWrapper, { marginTop: -8 }]}>
              <Text style={[globalStyles.inputLabel]}>Country</Text>
              <Text style={globalStyles.inputLabelNote}>(required)</Text>
            </View>
            <View style={[globalStyles.hAlignItems]}>
              {COUNTRIES.map((country) => (
                <TouchableOpacity
                  style={[
                    globalStyles.hAlignItems,
                    globalStyles.vCenterItems,
                    globalStyles.mt3,
                    globalStyles.mb2,
                    globalStyles.mr4,
                    { marginLeft: -2 }
                  ]}
                  onPress={() => countryHandler(country)}
                  key={country}
                >
                  {initialValues.country === country ? (
                    <MaterialCommunityIcons
                      name="radiobox-marked"
                      size={24}
                      color="#df143c"
                    />
                  ) : (
                    <MaterialCommunityIcons
                      name="radiobox-blank"
                      size={24}
                      color="#000"
                    />
                  )}
                  <Text
                    style={[
                      globalStyles.text,
                      globalStyles.fwLight,
                      globalStyles.ml1
                    ]}
                  >
                    {country}
                  </Text>
                </TouchableOpacity>
              ))}
            </View>
            <View style={[globalStyles.inputLabelWrapper, { marginTop: -8 }]}>
              <Text style={[globalStyles.inputLabel]}>Address</Text>
              <Text style={globalStyles.inputLabelNote}>(required)</Text>
            </View>
            <TextInput
              placeholder="Street address or P.O. Box"
              value={formik.values.address}
              onChangeText={formik.handleChange('address')}
              onBlur={formik.handleBlur('address')}
              style={[
                globalStyles.input,
                formik.touched.address &&
                  formik.errors.address &&
                  globalStyles.inputError
              ]}
              required
            />
            {formik.touched.address && formik.errors.address && (
              <Text
                style={[
                  globalStyles.note,
                  globalStyles.textError,
                  globalStyles.mb2
                ]}
              >
                {capitalizeFirstLetter(formik.errors.address)}
              </Text>
            )}
            <TextInput
              placeholder="Apt, Suite, Unit, Building, Floor, etc."
              value={formik.values.apt}
              onChangeText={formik.handleChange('apt')}
              onBlur={formik.handleBlur('apt')}
              style={globalStyles.input}
              required
            />
            <View style={[globalStyles.inputLabelWrapper]}>
              <Text style={[globalStyles.inputLabel]}>City</Text>
              <Text style={globalStyles.inputLabelNote}>(required)</Text>
            </View>
            <TextInput
              placeholder=""
              value={formik.values.city}
              onChangeText={formik.handleChange('city')}
              onBlur={formik.handleBlur('city')}
              style={[
                globalStyles.input,
                formik.touched.city &&
                  formik.errors.city &&
                  globalStyles.inputError
              ]}
              required
            />
            {formik.touched.city && formik.errors.city && (
              <Text style={[globalStyles.note, globalStyles.textError]}>
                {capitalizeFirstLetter(formik.errors.city)}
              </Text>
            )}
            <View style={[globalStyles.inputLabelWrapper]}>
              <Text style={[globalStyles.inputLabel]}>State</Text>
              <Text style={globalStyles.inputLabelNote}>(required)</Text>
            </View>
            <TextInput
              placeholder=""
              value={formik.values.state}
              onChangeText={formik.handleChange('state')}
              onBlur={formik.handleBlur('state')}
              style={[
                globalStyles.input,
                formik.touched.state &&
                  formik.errors.state &&
                  globalStyles.inputError
              ]}
              required
            />
            {formik.touched.state && formik.errors.state && (
              <Text style={[globalStyles.note, globalStyles.textError]}>
                {capitalizeFirstLetter(formik.errors.state)}
              </Text>
            )}
            <View style={[globalStyles.inputLabelWrapper]}>
              <Text style={[globalStyles.inputLabel]}>Zip Code</Text>
              <Text style={globalStyles.inputLabelNote}>(required)</Text>
            </View>
            <TextInput
              placeholder=""
              value={formik.values.zipCode}
              onChangeText={formik.handleChange('zipCode')}
              onBlur={formik.handleBlur('zipCode')}
              style={[
                globalStyles.input,
                formik.touched.zipCode &&
                  formik.errors.zipCode &&
                  globalStyles.inputError
              ]}
              required
            />
            {formik.touched.zipCode && formik.errors.zipCode && (
              <Text style={[globalStyles.note, globalStyles.textError]}>
                {capitalizeFirstLetter(formik.errors.zipCode)}
              </Text>
            )}
            <View style={{ marginTop: 20, marginBottom: 10 }}>
              <FlatButton
                text="Continue"
                transform="uppercase"
                type="primary"
                onPress={formik.handleSubmit}
                isDisabled={!formik.isValid}
              />
            </View>
          </View>
        )}
      </Formik>
    </View>
  )
}

const styles = StyleSheet.create({})

export default Shipping
