import {
  StyleSheet,
  Text,
  View,
  Image,
  Pressable,
  TouchableOpacity,
  SafeAreaView
} from 'react-native'
import React, { useState } from 'react'
import {
  DrawerContentScrollView,
  DrawerItemList,
  DrawerItem
} from '@react-navigation/drawer'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { useToast } from 'react-native-toast-notifications'
import { AntDesign } from '@expo/vector-icons'

// actions
import { logout } from '../redux/actions/userActions'

const CustomDrawerContent = ({ props }) => {
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const toast = useToast()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const openLoginHandler = () => {
    navigation.navigate('Login', {})
  }

  const logoutHandler = () => {
    dispatch(logout(toast))
    props.navigation.closeDrawer()
  }

  const logoHandler = () => {
    navigation.navigate('Home', {})
    props.navigation.closeDrawer()
  }

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: '#fff',
        borderRightColor: '#df143c',
        borderRightWidth: 0
      }}
    >
      <DrawerContentScrollView {...props} contentContainerStyle={{}}>
        <TouchableOpacity style={styles.logoWraper} onPress={logoHandler}>
          <Image
            source={require('../assets/logos/logo-dark-lg-2x.png')}
            style={styles.logo}
          />
        </TouchableOpacity>
        {userInfo && (
          <View style={styles.profileImgWraper}>
            <Image
              source={{ uri: userInfo.picture }}
              style={styles.profileImg}
            />
            <Text style={styles.profileText}>
              Welcome,{' '}
              {userInfo.firstname
                ? userInfo.firstname
                : userInfo.lastname
                ? userInfo.lastname
                : userInfo.username}
              !
            </Text>
          </View>
        )}
        <DrawerItemList {...props} />
      </DrawerContentScrollView>
      {!userInfo && (
        <View>
          <DrawerItem
            label="Sign In"
            onPress={() => {
              openLoginHandler()
            }}
            labelStyle={{
              fontFamily: 'notosanstc-medium',
              color: '#000',
              fontSize: 14,
              lineHeight: 19,
              marginLeft: -20,
              paddingVertical: 4
            }}
            icon={({}) => (
              <View style={{ paddingLeft: 10 }}>
                <AntDesign name="login" size={20} color="#000" />
              </View>
            )}
            style={{
              borderRadius: 0,
              marginVertical: 0,
              marginHorizontal: 0,
              width: '100%'
            }}
            activeBackgroundColor="red"
          />
        </View>
      )}
      {userInfo && (
        <View>
          <DrawerItem
            label="Sign Out"
            onPress={() => {
              logoutHandler()
            }}
            labelStyle={{
              fontFamily: 'notosanstc-medium',
              color: '#df143c',
              fontSize: 14,
              lineHeight: 19,
              marginLeft: -20,
              paddingVertical: 4
            }}
            icon={({}) => (
              <View style={{ paddingLeft: 10 }}>
                <AntDesign name="logout" size={20} color="#df143c" />
              </View>
            )}
            style={{
              borderRadius: 0,
              marginVertical: 0,
              marginHorizontal: 0,
              width: '100%'
            }}
            activeBackgroundColor="red"
          />
        </View>
      )}
    </SafeAreaView>
  )
}

export default CustomDrawerContent

const styles = StyleSheet.create({
  logoWraper: {
    marginHorizontal: 16,
    marginTop: 16,
    marginBottom: 16
  },
  logo: {
    width: 160,
    height: 39
  },
  profileImgWraper: {
    alignItems: 'center',
    marginVertical: 20
  },
  profileImg: {
    width: 100,
    height: 100,
    borderRadius: 50,
    shadowOffset: { width: 1, height: 1 },
    shadowColor: '#000',
    shadowOpacity: 0.3,
    shadowRadius: 2
  },
  profileText: {
    paddingTop: 8,
    fontFamily: 'notosanstc-light',
    fontSize: 18
  }
})
