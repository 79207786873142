// local storage
import { setLocalStorage } from '../storage'

export const getItemSubtotal = (cartItems) =>
  cartItems.reduce((acc, item) => acc + item.quantity, 0)

export const getItemTotalPrice = (cartItems) =>
  cartItems.reduce((acc, item) => acc + item.quantity * item.price, 0)

export const getDeliveryFee = (amount) => (amount >= 100 ? 0 : 5.99)

export const getEstimatedTax = (amount = 0, zip = null) => {
  return zip ? Number((0.0825 * amount).toFixed(2)) : 0
}

export const removeFromCartHandler = (id, dispatch, removeFromCart, toast) => {
  dispatch(removeFromCart(id, toast))
}

export const removeAllItemsHandler = (
  dispatch,
  removeAllItemsFromCart,
  navigation,
  toast
) => {
  dispatch(removeAllItemsFromCart(toast))
  navigation.navigate('Cart', {})
}

export const checkoutHandler = (navigate) => {
  navigate('/signin?redirect=shipping')
}

export const cartCalculations = (cart, cartItems, shippingAddress) => {
  const zipCode = shippingAddress ? shippingAddress.zipCode : null

  // calculations
  cart.itemSubtotal = getItemSubtotal(cartItems)
  cart.itemTotalPrice = getItemTotalPrice(cartItems)
  cart.shippingPrice = getDeliveryFee(getItemTotalPrice(cartItems))
  cart.taxPrice = getEstimatedTax(getItemTotalPrice(cartItems), zipCode)
  cart.orderTotalPrice =
    getItemTotalPrice(cartItems) +
    getDeliveryFee(getItemTotalPrice(cartItems)) +
    getEstimatedTax(getItemTotalPrice(cartItems), zipCode)

  // storage

  // localStorage.setItem('cartItemSubtotal', JSON.stringify(cart.itemSubtotal))
  // localStorage.setItem(
  //   'cartItemTotalPrice',
  //   JSON.stringify(cart.itemTotalPrice)
  // )
  // localStorage.setItem('cartShippingPrice', JSON.stringify(cart.shippingPrice))
  // localStorage.setItem('cartTaxPrice', JSON.stringify(cart.taxPrice))
  // localStorage.setItem(
  //   'cartOrderTotalPrice',
  //   JSON.stringify(cart.orderTotalPrice)
  // )

  setLocalStorage('cartItemSubtotal', cart.itemSubtotal)
  setLocalStorage('cartItemTotalPrice', cart.itemTotalPrice)
  setLocalStorage('cartShippingPrice', cart.shippingPrice)
  setLocalStorage('cartTaxPrice', cart.taxPrice)
  setLocalStorage('cartOrderTotalPrice', cart.orderTotalPrice)
}
