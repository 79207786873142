import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity
} from 'react-native'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToast } from 'react-native-toast-notifications'

// styles
import globalStyles from '../styles/global'

// utils
import { currencyFormatter } from '../utils/index'
import { cartCalculations } from '../utils/cart'

// components
import CartOrderSummary from '../components/cart/CartOrderSummary'
import Shipping from '../components/checkout/Shipping'

const ShippingScreen = () => {
  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  // calculations
  cartCalculations(cart, cartItems)

  return (
    <ScrollView style={styles.container}>
      <View style={[globalStyles.secondaryBgColor, globalStyles.containerBand]}>
        {true && (
          <View style={[globalStyles.textOverlap]}>
            <View>
              <Text
                style={[
                  globalStyles.text,
                  globalStyles.fwMedium,
                  globalStyles.fs20,
                  globalStyles.blackTextColor,
                  globalStyles.mb1
                ]}
              >
                Shipping
              </Text>
            </View>
          </View>
        )}
      </View>
      <View style={[globalStyles.containerOverlap]}>
        <Shipping />
      </View>
      <View style={{ paddingHorizontal: 16, paddingBottom: 40 }}>
        <CartOrderSummary
          itemSubtotal={cart.itemSubtotal}
          itemTotalPrice={currencyFormatter(cart.itemTotalPrice)}
          deliveryFee={
            cart.shippingPrice === 0
              ? 'Free'
              : currencyFormatter(cart.shippingPrice)
          }
          taxLabel={'Estimated tax'}
          tax={currencyFormatter(cart.taxPrice)}
          orderTotalLabel={'Order Total'}
          orderTotalPrice={currencyFormatter(cart.orderTotalPrice)}
          cartItems={cartItems}
          actionLabel={'Place Order'}
          isDisabled={true}
        />
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  }
})

export default ShippingScreen
