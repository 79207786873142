import { StyleSheet, Text, View, ScrollView } from 'react-native'
import React, { useEffect } from 'react'
import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { useToast } from 'react-native-toast-notifications'

// styles
import globalStyles from '../styles/global'

// utils
import { currencyFormatter } from '../utils/index'
import { cartCalculations } from '../utils/cart'

// components
import CartOrderSummary from '../components/cart/CartOrderSummary'
import Order from '../components/checkout/Order'

// actions
import { createOrder, payOrder } from '../redux/actions/orderActions'
import { emptyCart } from '../redux/actions/cartActions'

const OrderScreen = () => {
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const toast = useToast()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const cart = useSelector((state) => state.cart)
  const { cartItems, shippingAddress } = cart

  const orderCreate = useSelector((state) => state.orderCreate)
  const { order, success, error } = orderCreate

  // calculations
  cartCalculations(cart, cartItems, shippingAddress)

  const placeOrderHandler = () => {
    if (userInfo && cart) {
      dispatch(
        createOrder(
          {
            user: userInfo._id,
            orderItems: cart.cartItems,
            shippingAddress: cart.shippingAddress,
            paymentMethod: cart.paymentMethod,
            itemsTotal: cart.itemSubtotal,
            itemsPrice: cart.itemTotalPrice,
            taxPrice: cart.taxPrice,
            shippingPrice: cart.shippingPrice,
            totalPrice: cart.orderTotalPrice
          },
          toast
        )
      )

      navigation.navigate('OrderComplete', {})
    }
  }

  useEffect(() => {
    if (success) {
      // empty the cart
      dispatch(emptyCart())

      // pay order
      dispatch(
        payOrder(
          order._id,
          {
            id: order._id.substring(0, 17).toUpperCase(),
            status: 'COMPLETED',
            update_time: Date.now,
            payer: { email_address: 'demo@lynashop.net' }
          },
          toast
        )
      )
    }
    // eslint-disable-next-line
  }, [success])

  return (
    <ScrollView style={styles.container}>
      <View style={[globalStyles.secondaryBgColor, globalStyles.containerBand]}>
        {true && (
          <View style={[globalStyles.textOverlap]}>
            <View>
              <Text
                style={[
                  globalStyles.text,
                  globalStyles.fwMedium,
                  globalStyles.fs20,
                  globalStyles.blackTextColor,
                  globalStyles.mb1
                ]}
              >
                Place Order
              </Text>
            </View>
          </View>
        )}
      </View>
      <View style={[globalStyles.containerOverlap]}>
        <Order action={placeOrderHandler} />
      </View>
      <View style={{ paddingHorizontal: 16, paddingBottom: 40 }}>
        <CartOrderSummary
          itemSubtotal={cart.itemSubtotal}
          itemTotalPrice={currencyFormatter(cart.itemTotalPrice)}
          deliveryFee={
            cart.shippingPrice === 0
              ? 'Free'
              : currencyFormatter(cart.shippingPrice)
          }
          taxLabel={'Estimated tax'}
          tax={currencyFormatter(cart.taxPrice)}
          orderTotalLabel={'Order Total'}
          orderTotalPrice={currencyFormatter(cart.orderTotalPrice)}
          cartItems={cartItems}
          actionLabel={'Place Order'}
          action={placeOrderHandler}
        />
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  }
})

export default OrderScreen
