import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TextInput,
  TouchableOpacity
} from 'react-native'
import React, { useState, useEffect } from 'react'
import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { useToast } from 'react-native-toast-notifications'
import { Ionicons } from '@expo/vector-icons'

// styles
import globalStyles from '../styles/global'

// components
import FlatButton from '../components/Button'

// actions
import { login } from '../redux/actions/userActions'

const LoginScreen = () => {
  const [identifier, setIdentifier] = useState('')
  const [password, setPassword] = useState('')
  const [isPasswordHidden, setIsPasswordHidden] = useState(true)

  const dispatch = useDispatch()
  const navigation = useNavigation()
  const toast = useToast()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const loginHandler = () => {
    dispatch(login(identifier, password, toast))
  }

  useEffect(() => {
    if (userInfo) navigation.navigate('Home', {})
  }, [navigation, userInfo])

  return (
    <>
      {!userInfo && (
        <ScrollView style={styles.container}>
          <View
            style={[globalStyles.secondaryBgColor, globalStyles.containerBand]}
          >
            {false && (
              <View style={[globalStyles.textOverlap]}>
                <View>
                  <Text
                    style={[
                      globalStyles.text,
                      globalStyles.fwMedium,
                      globalStyles.fs20,
                      globalStyles.blackTextColor,
                      globalStyles.mb1
                    ]}
                  >
                    Login
                  </Text>
                </View>
              </View>
            )}
          </View>
          <View style={[globalStyles.containerOverlap]}>
            <View style={[globalStyles.containerOverlapInner]}>
              <View style={styles.inputContainer} behavior="padding">
                <View style={[styles.inputLabelWrapper, { marginTop: -8 }]}>
                  <Text style={[styles.inputLabel]}>Email / Username</Text>
                </View>
                <TextInput
                  placeholder=""
                  value={identifier}
                  onChangeText={(text) => setIdentifier(text)}
                  style={styles.input}
                  required
                  autoCapitalize="none"
                />
                <View style={styles.inputLabelWrapper}>
                  <Text style={styles.inputLabel}>Password</Text>
                </View>
                <View style={styles.passwordField}>
                  <TextInput
                    placeholder=""
                    value={password}
                    onChangeText={(text) => setPassword(text)}
                    style={styles.input}
                    secureTextEntry={isPasswordHidden}
                    autoCapitalize="none"
                  />
                  <TouchableOpacity
                    style={styles.eyeIcon}
                    onPress={() => {
                      setIsPasswordHidden(!isPasswordHidden)
                    }}
                  >
                    <Ionicons
                      name={`${
                        isPasswordHidden
                          ? 'ios-eye-outline'
                          : 'ios-eye-off-outline'
                      }`}
                      size={24}
                      color="rgba(0,0,0,.25)"
                    />
                  </TouchableOpacity>
                </View>
                <View style={styles.buttonContainer}>
                  <FlatButton
                    text="Login"
                    transform="uppercase"
                    type="primary"
                    onPress={loginHandler}
                  />
                  <FlatButton
                    text="Forgot Password?"
                    transform="capitalize"
                    type=""
                    onPress={() => navigation.navigate('ForgotPassword')}
                  />
                </View>
                <View style={[globalStyles.separator]}></View>
                <View style={styles.copyContainer}>
                  <Text style={styles.copyText}>Need an account?</Text>
                  <TouchableOpacity
                    onPress={() => navigation.navigate('Register')}
                  >
                    <Text style={styles.copyLink}>Register</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  inputContainer: {
    marginVertical: 0,
    padding: 16
  },
  input: {
    borderWidth: 1,
    backgroundColor: '#fff',
    borderColor: '#e9ecef',
    paddingHorizontal: 16,
    height: 46,
    fontSize: 16,
    lineHeight: 21,
    borderRadius: 6,
    marginVertical: 4,
    fontFamily: 'notosanstc-medium'
  },
  inputLabelWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10
  },
  inputLabel: {
    color: '#333',
    fontFamily: 'notosanstc-medium',
    fontSize: 14,
    lineHeight: 19,
    marginBottom: 0,
    marginTop: 16,
    marginHorizontal: 1
  },
  inputLabelNote: {
    color: 'rgba(0,0,0, .7)',
    fontFamily: 'notosanstc-light',
    fontSize: 11,
    lineHeight: 16,
    marginBottom: 0,
    marginTop: 16,
    marginHorizontal: 3
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginVertical: 16
  },
  copyContainer: {
    flexDirection: 'row'
    // marginTop: 4
  },
  copyText: {
    color: '#333',
    fontFamily: 'notosanstc-light',
    marginHorizontal: 1,
    fontSize: 14,
    lineHeight: 19
  },
  copyLink: {
    color: '#df143c',
    marginLeft: 5,
    borderBottomColor: '#df143c',
    fontFamily: 'notosanstc-bold',
    fontSize: 14,
    lineHeight: 19
  },
  passwordField: {
    position: 'relative',
    marginHorizontal: 0
  },
  eyeIcon: {
    position: 'absolute',
    right: 12,
    top: 15.5
  }
})

export default LoginScreen
