import { StyleSheet, Text, View, ScrollView, Image } from 'react-native'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Fontisto } from '@expo/vector-icons'
import { useNavigation, useIsFocused } from '@react-navigation/native'

// styles
import globalStyles from '../styles/global'

// components
import ProductList from '../components/ProductList'
import NotFound from '../components/NotFound'
import ProductFilter from '../components/ProductFilter'
import SearchList from '../components/SearchList'

const SearchScreen = ({ route }) => {
  const [filter, setFilter] = useState({})

  const navigation = useNavigation()
  const isFocused = useIsFocused()
  const { keyword } = route.params

  const productList = useSelector((state) => state.productList)
  const { meta } = productList

  useEffect(() => {}, [keyword])

  return (
    <View style={styles.container}>
      {keyword && keyword !== '' && (
        <View
          style={[globalStyles.secondaryBgColor, globalStyles.containerBand]}
        >
          <View>
            {false && (
              <View
                style={[globalStyles.textOverlap, globalStyles.hStartItems]}
              >
                <Text
                  style={[
                    globalStyles.text,
                    globalStyles.fwMedium,
                    globalStyles.fs18,
                    globalStyles.blackTextColor
                  ]}
                >
                  Search for
                </Text>
                <Text
                  style={[
                    globalStyles.text,
                    globalStyles.fwMedium,
                    globalStyles.fs18,
                    globalStyles.primaryTextColor
                  ]}
                >
                  “{keyword}”
                </Text>
              </View>
            )}
            {meta && meta.total_products !== 0 && (
              <View style={[globalStyles.mt3, globalStyles.mb2]}>
                <ProductFilter
                  theme="dark"
                  filter={filter}
                  setFilter={setFilter}
                />
              </View>
            )}
          </View>
        </View>
      )}

      {/* {meta && meta.total_products === 0 && (
        <View style={[globalStyles.containerOverlap, globalStyles.flexOne]}>
          <NotFound
            message="No items found"
            actionLabel="back home"
            action={() => {
              navigation.navigate('Home', {})
            }}
          />
        </View>
      )} */}

      {keyword && keyword !== '' && (
        <View
          style={[
            globalStyles.containerOverlap,
            globalStyles.containerOverlapNoGap,
            globalStyles.flexOne
          ]}
        >
          <ProductList
            term={keyword}
            sort={filter?.value ? filter.value : '*'}
            isFocused={isFocused}
          />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  innerContainer: {
    marginVertical: 0,
    padding: 16,
    alignItems: 'center'
  }
})

export default SearchScreen
