import { StyleSheet, Text, View } from 'react-native'
import React, { useState, useEffect } from 'react'
import MasonryList from '@react-native-seoul/masonry-list'
import { useDispatch, useSelector } from 'react-redux'

// config
import { myWishlistPerPage, productsPerPage } from '../config'

// components
import CardItem from './CardItem'

// skeletons
import CardItemSkeleton from './skeletons/CardItemSkeleton'

// actions
import { listProducts, wishlistProducts } from '../redux/actions/productActions'

const SearchList = ({
  term,
  modalOpen,
  setModalOpen,
  selectedItem,
  setSelectedItem
}) => {
  const [firstLoad, setFirstLoad] = useState(true)

  const dispatch = useDispatch()

  const productList = useSelector((state) => state.productList)
  const { loading, error, products } = productList

  const page = 1
  const sort = '*'

  useEffect(() => {
    if (firstLoad || page) {
      dispatch(listProducts(productsPerPage, page, sort, term, { page, sort }))
      setFirstLoad(false)
    }
  }, [dispatch, page, firstLoad])

  if (loading) return <CardItemSkeleton />

  return (
    <>
      <View style={styles.gridView}>
        <MasonryList
          data={[products]}
          keyExtractor={(item) => item._id}
          numColumns={2}
          showsVerticalScrollIndicator={false}
          renderItem={({ item }) => (
            <CardItem
              item={item}
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
            />
          )}
          // refreshing={isLoadingNext}
          // onRefresh={() => refetch({ first: ITEM_CNT })}
          onEndReachedThreshold={0.1}
          // onEndReached={() => loadNext(ITEM_CNT)}
        />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  gridView: {
    marginTop: 0,
    flex: 1,
    padding: 12
  }
})

export default SearchList
