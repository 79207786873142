import { StyleSheet, Text, View, TouchableOpacity } from 'react-native'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation, useIsFocused } from '@react-navigation/native'
import { useToast } from 'react-native-toast-notifications'
import { AntDesign } from '@expo/vector-icons'
import moment from 'moment'

// styles
import globalStyles from '../../styles/global'

// utils
import { currencyFormatter } from '../../utils'

// components
import CartOrderSummary from '../cart/CartOrderSummary'

// constants
import {
  ORDER_DELIVER_RESET,
  ORDER_PAY_RESET
} from '../../redux/constants/orderConstants'

// actions
import { getOrderDetails, payOrder } from '../../redux/actions/orderActions'
import OrderDetailsSkeleton from '../skeletons/OrderDetailsSkeleton'

const OrderDetails = ({ orderId }) => {
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const toast = useToast()
  const isFocused = useIsFocused()

  const orderDetails = useSelector((state) => state.orderDetails)
  const { order, loading, error } = orderDetails

  const orderPay = useSelector((state) => state.orderPay)
  const { loading: loadingPay, success: successPay } = orderPay

  const makeOrderPayment = () => {
    dispatch(
      payOrder(
        order._id,
        {
          id: order._id.substring(0, 17).toUpperCase(),
          status: 'COMPLETED',
          update_time: Date.now,
          payer: { email_address: 'demo@lynashop.net' }
        },
        toast,
        true
      )
    )
  }

  useEffect(() => {
    dispatch({ type: ORDER_PAY_RESET })
    dispatch({ type: ORDER_DELIVER_RESET })
    dispatch(getOrderDetails(orderId, toast))
  }, [dispatch, orderId, successPay])

  if (loading) return <OrderDetailsSkeleton />

  return (
    <>
      {!loading && order && (
        <>
          <View style={[globalStyles.card]}>
            <View>
              <Text
                style={[
                  globalStyles.text,
                  globalStyles.fwBold,
                  globalStyles.fs16,
                  globalStyles.mb3
                ]}
              >
                Shipping Address
              </Text>
              <Text style={[globalStyles.text, globalStyles.fwLight]}>
                {order.shippingAddress.address}
                {order.shippingAddress.apt && `, #${order.shippingAddress.apt}`}
              </Text>
              <Text style={[globalStyles.text, globalStyles.fwLight]}>
                {order.shippingAddress.city}, {order.shippingAddress.state}{' '}
                {order.shippingAddress.zipCode}
              </Text>
              <Text style={[globalStyles.text, globalStyles.fwLight]}>
                {order.shippingAddress.country}
              </Text>
              <View
                style={[
                  globalStyles.hAlignItems,
                  globalStyles.vCenterItems,
                  globalStyles.mt3
                ]}
              >
                {order.isDelivered ? (
                  <>
                    <AntDesign
                      name="checkcircle"
                      size={24}
                      color="rgba(7, 188, 12, .75)"
                    />
                    <Text
                      style={[
                        globalStyles.text,
                        globalStyles.fwLight,
                        globalStyles.fs16,
                        globalStyles.ml2
                      ]}
                    >
                      Delivered on {moment(order.deliveredAt).calendar()}
                    </Text>
                  </>
                ) : (
                  <>
                    <AntDesign
                      name="exclamationcircle"
                      size={24}
                      color="rgba(223, 20, 60, .75)"
                    />
                    <Text
                      style={[
                        globalStyles.text,
                        globalStyles.fwLight,
                        globalStyles.fs16,
                        globalStyles.ml2
                      ]}
                    >
                      Not Delivered
                    </Text>
                  </>
                )}
              </View>

              <View style={[globalStyles.separator]} />

              <Text
                style={[
                  globalStyles.text,
                  globalStyles.fwBold,
                  globalStyles.fs16,
                  globalStyles.mb3
                ]}
              >
                Payment Method
              </Text>
              <Text style={[globalStyles.text, globalStyles.fwLight]}>
                PayPal
              </Text>
              <View
                style={[
                  globalStyles.hAlignItems,
                  globalStyles.vCenterItems,
                  globalStyles.mt3
                ]}
              >
                {order.isPaid ? (
                  <>
                    <AntDesign
                      name="checkcircle"
                      size={24}
                      color="rgba(7, 188, 12, .75)"
                    />
                    <Text
                      style={[
                        globalStyles.text,
                        globalStyles.fwLight,
                        globalStyles.fs16,
                        globalStyles.ml2
                      ]}
                    >
                      Paid on {moment(order.paidAt).calendar()}
                    </Text>
                  </>
                ) : (
                  <>
                    <AntDesign
                      name="exclamationcircle"
                      size={24}
                      color="rgba(223, 20, 60, .75)"
                    />
                    <Text
                      style={[
                        globalStyles.text,
                        globalStyles.fwLight,
                        globalStyles.fs16,
                        globalStyles.ml2
                      ]}
                    >
                      Not Paid
                    </Text>
                  </>
                )}
              </View>
            </View>
          </View>
          <View style={{ marginTop: 16, paddingBottom: 40 }}>
            <CartOrderSummary
              itemSubtotal={order.itemsTotal}
              itemTotalPrice={currencyFormatter(order.itemsPrice)}
              deliveryFee={
                order.shippingPrice === 0
                  ? 'Free'
                  : currencyFormatter(order.shippingPrice)
              }
              taxLabel={'Tax'}
              tax={currencyFormatter(order.taxPrice)}
              orderTotalLabel={'Total'}
              orderTotalPrice={currencyFormatter(order.totalPrice)}
              cartItems={order.orderItems}
              isAction={order.isPaid ? false : true}
              action={makeOrderPayment}
              actionLabel={'Pay Now'}
              isDeliveryNote={false}
            />
          </View>
        </>
      )}
    </>
  )
}

const styles = StyleSheet.create({})

export default OrderDetails
